var translation = {
    LANGUAGE: "fr",
    SABLONO: "Sablono",
    _DECIMAL: "{{n}}.{{m}}",
    _SECONDS: "secondes",
    _MINUTES: "minutes",
    _HOUR: "heure",
    _HOURS: "heures",
    _WORK_HOUR: "heure de travail",
    _WORK_HOURS: "heures de travail",
    _DAY: "journée",
    _DAYS: "jours",
    _WORKDAY: "jour ouvré",
    _WORKDAYS: "jours ouvrés",
    _YEARS: "ans",
    _wd: "jours ouvrés",
    _wh: "heures de travail",
    _h: "heures",
    _d: "jours",
    _wd_ABBREVIATION: "jo",
    _wh_ABBREVIATION: "ht",
    _h_ABBREVIATION: "h",
    _d_ABBREVIATION: "j",
    _LAST_MODIFIED: "Dernière modification",
    _SCHEDULE: "Planning",
    _ON_SCHEDULE: "A temps",
    _AT_RISK: "En danger",
    _BEHIND_SCHEDULE: "En retard de planning",
    _BEHIND_BASELINE_SCHEDULE: "En retard de calendrier de base",
    _AHEAD_SCHEDULE: "En avance de planning",
    _UNSCHEDULED: "Non planifié",
    _NO_PROCESS: "Sans Flux de Travail",
    _PLANNED: "Planifié",
    _PLANNED_TO_BE_DONE: "Prévu d'être fait",
    _ACTUAL: "Actuel",
    _NEW_NOTES: "Nouvelles remarques",
    _NOTES: "Remarques",
    _NOTE_TEXT: "Texte de notes",
    _ISSUES_CLOSED: "Problèmes résolus",
    _ISSUE_TEMPLATE_CODE: "Modèle de notes",
    _CLAIM: "Problème de qualité",
    _CLAIMS: "Problèmes de qualité",
    _OBSTRUCTION: "Points bloquants",
    _OBSTRUCTIONS: "Points bloquants",
    _OPEN_CLAIM: "problème de qualité en suspens",
    _OPEN_ISSUE: "problème en suspens",
    _OPEN_OBSTRUCTION: "Points bloquants non résolu",
    _OPEN_CLAIMS: "Problèmes de qualité en suspens",
    _OPEN_ISSUES: "Problèmes en suspens",
    _OPEN_OBSTRUCTIONS: "Points bloquants non résolu",

    _ISSUE_STATUS: "État",
    _ISSUE_STATUS_OPEN: "Ouvert",
    _ISSUE_STATUS_CLOSED: "Résolu",
    _ISSUE_STATUS_WFC: "Attente de Confirmation",
    _ISSUE_STATUS_REJECTED: "Réouvert",
    _ISSUE_STATUS_CONFIRMED: "Résolu",
    _ISSUE_STATUS_REMOVED: "Supprimé",
    _ISSUE_STATUS_NOT_REMOVED: "Non supprimé",

    INPUT_INCLUDE_REMOVED_ISSUES_LABEL: "Statut de suppression",

    _INFO_S: "information",
    _NO_NOTES: "Aucun",
    _ISSUE_TYPE_INFO: "Information",
    _CLOSED_CLAIMS: "problèmes de qualité résolus",
    _CLOSE_ISSUE: "problème résolu",
    _CLOSED_ISSUES: "problèmes résolus",
    _CLOSED_OBSTRUCTIONS: "obstructions résolus",
    _OPEN_CLAIMS_N:
        "{{items}} problème{{ (items === 1) ? '' : 's' }} de qualité en suspens",
    _OPEN_ISSUES_N:
        "{{issues}} problème{{ (issues === 1) ? '' : 's' }} en suspens",
    _OPEN_OBSTRUCTIONS_N:
        "{{items}} obstruction{{ (items === 1) ? '' : 's' }} non résolu{{ (items === 1) ? '' : 's' }}",
    _INFO_N: "{{items}} notificiation{{ (items === 1) ? '' : 's' }}",
    _ACTIVITIES_FINISHED_N:
        "{{done}} de {{quantity}} sont terminée{{ (items === 1) ? '' : 's' }}",
    _ACTIVITIES_FINISHED: "activités terminées",
    _ACTIVITIES: "activités",
    _ACTIVITIES_N:
        "{{activities | number}} activité{{ (items === 1) ? '' : 's' }}",
    _CUSTOM_ACTIVITIES: "Activités sur mesure",
    _CONTAINS: "Contient:",
    _CREATED: "Créé:",
    _DRAFT: "En cours",
    _DATE: "Date",
    _TODAY: "Aujourd'hui",
    _END_OF_LAST_MONTH: "Fin du mois dernier",
    _CUSTOM_DATE: "Personnalisé",
    _UNTIL: "Jusqu'à",
    _ANYTIME: "Quand vous voulez",
    _DAYS_BETWEEN_OVER_30_DAYS: "Plus de 30 jours",
    _DAYS_BETWEEN_TO_30_DAYS: "30 derniers jours",
    _DAYS_BETWEEN_TO_7_DAYS: "7 derniers jours",
    _DAYS_BETWEEN_AFTER_30_DAYS: "30 prochains jours",
    _DAYS_BETWEEN_AFTER_7_DAYS: "7 prochains jours",
    _TEN_DAYS_AGO: "Au cours des 10 derniers jours",
    _LAST_MONTH: "Le mois dernier",
    _LAST_WEEK: "La semaine dernière",
    _NEXT_WEEK: "Semaine prochaine",
    _THIS_WEEK: "Cette semaine",
    _WEEK_OF_YEAR: "Début de la semaine",
    _TO_DATE_NOT_SET: "aucun",
    _FROM_DATE_NOT_SET: "aucun",
    _UNSET: "non planifé",
    _TOPOLOGICAL_INDEX: "Position d'activité dans l'ordre",
    _CUSTOM: "Défini par l'utilisateur...",
    _STATUS: "État du livrable",
    _UPDATING_STATUS: "Actualiser l'état ",
    _STATUS_UPDATED: "État actualisé",
    _FROM: "De",
    _TO: "Au",
    _ALL: "Tous",
    _NO_DESCRIPTION: "Aucune description",
    _INFO: "Notes d'information",
    _ALL_TYPES: "Tous",
    _IMAGES: "Images",
    _EVERYBODY: "Tout le monde",
    _ROLE_DEFAULT: "",
    _ROLE_ADMIN: "Utilisateur admin",
    _ROLE_MANAGER: "Gestionnaire de projet",
    _ROLE_INSPECTOR: "Inspecteur de chantier",
    _ROLE_CONTROLLER: "Contrôleur de projet",
    _ROLE_REVIEWER: "Examinateur (accès en lecture)",
    _ROLE_REPLACEMENT: " Remplaçant",

    _ROLE_COMMERCIAL_MANAGE: "Directeur commercial",
    _ROLE_COMMERCIAL_READ: "Réviseur commercial",

    _ROLE_INITIALS_ADMIN: "AP",
    _ROLE_INITIALS_MANAGER: "CP",
    _ROLE_INITIALS_INSPECTOR: "IS",
    _ROLE_INITIALS_CONTROLLER: "CP",
    _ROLE_INITIALS_REVIEWER: "EO",
    _COMPONENT: "Composante",
    _DELIVERY: "Livraison",
    _DOCUMENT: "Document",
    _ROOM: "Chambre",
    _TRADE: "Métier",
    _GROUP: "Groupe",
    _OTHERS: "Autre",
    _ACTIVITY: "Activité",
    _DELIVERABLE: "Livrable",
    _PROGRESS_0: "pas commencé",
    _PROGRESS_100: "fini",
    _PROGRESS_50: "travail commencé",
    _PROGRESS_CHANGES: "Mises à jour d'activité",
    _PROGRESS_CANNOT_START: "Signaler une remarque",
    _NOT_STARTED: "Pas commencé",
    _STARTED: "Debut des travaux ",
    _DONE: "Fini",
    _WAITING_FOR_CONFIRMATION: "Confirmation en attente",
    _CONFIRMED: "Confirmé",
    _REJECTED: "Rejeté",
    _APPROVED: "Approuvé",
    _COMPLETED: "Finalisé : Confirmé + Terminé après l'équipe responsable",
    _REVIEW_CHECKLIST: "La revue",
    _SELECT_ALL: "Sélectionner tout",
    _DESELECT_ALL: "Désélectionner tout",
    _SHOW_MORE: "Plus",
    _PREDECESSOR: "Prédécesseur",
    _SUCCESSOR: "Successeur",
    _PREDECESSOR_N:
        "Dépend de {{elements}} prédécesseur{{(items ===0 || items > 1) ? 's' : ''}}",
    _SUCCESSOR_N:
        "Affect {{elements}} successeur{{(items ===0 || items > 1) ? 's' : ''}}",
    _COPYRIGHT: "© {{year}} Sablono GmbH",
    _NEXT: "Suivant",
    _REFRESH: "Rafraîchir",
    _UPDATE: "Mise à jour",
    _IMPORT: "Importation",
    _DOWNLOAD: "Télécharger",
    _DOWNLOAD_FILE: "Télécharger le fichier",
    _DOWNLOAD_MACRO: "Télécharger la macro",
    _CANCEL: "Annuler",
    _CONTINUE: "Continuer",
    _GO_TO: "Aller à ",
    _CLOSE: "Fermer",
    _FINISH: "Finition",
    _YES: "Oui",
    _NO: "Non",
    _SAVED: "Sauvegarder",
    _TEAM_COLOR: "Couleur d'équipe",
    _COLOR: "Couleur",
    _STREET: "Adresse municipale",
    _POST_CODE: "Code postal",
    _CITY: "Ville",
    _COUNTRY: "Pays",
    _CONTACT_INFO: "Désigné à:",
    _COMPANY: "Coordonnées de l'entreprise:",
    _EDIT: "Modifier",
    LATE_BY_DAYS: "{{ jours }} retard",

    _NOTE_RESPONSIBLE_TEAM: "Équipe responsable",
    _NOTE_RESPONSIBLE_TEAM_SHORT: "Responsable",

    _NOTE_CONFIRMATION_TEAM: "Équipe de révision",
    _NOTE_CONFIRMATION_TEAM_SHORT: "Révision",

    _PROJECT_TEAM: "Équipe d'accès complet",
    PROJECT_TEAM_INVITED_MEMBERS: "Invitations en attente",
    _NO_DEFAULT_CONFIRMATION_TEAM:
        "Aucune équipe de révision présélectionnée par défaut",
    _REPORTERS_TEAM:
        "Équipe à laquelle appartient l'utilisateur qui signale les notes",
    _UNRESTRICTED_TEAM: "Non attribué",
    _NO_CONFIRMATION_TEAM_AVAILABLE: "Non demandé",

    _AREA_MANAGER: "Gestionnaire responsable",
    _CREATION_DATE: "Déclarer à",
    _PROJECT_START_DATE: "Date de début",
    _PROJECT_DUE_DATE: "Date d'achèvement",
    _FILTER_TOOLTIP: "Filtre",
    _SEND_MAIL: "Envoyer le message",
    _AUTOCOMPLETE_SEARCH_BY_EMAIL_OR_USERNAME:
        'Veuillez saisir l\'adresse électronique et appuyer sur la touche "Entrée"',
    _AUTOCOMPLETE_SEARCH_USER_NOT_FOUND:
        "Veuillez utiliser l'adresse e-mail exact de la personne que vous souhaitez ajouter à votre projet. Indice: Il doit d'abord créer et activer son compte.",
    SECTION_TEAMS_SUPPORT_CENTER_TITLE: "Gestion d'équipe",
    SECTION_TEAMS_ADD_NEW_TITLE: "Ajouter une nouvelle équipe",
    SECTION_TEAMS_ADD_NEW_TEXT:
        "Une équipe peut représenter par exemple une entreprise ou un métier. Les activités créées au Sablono peuvent être assignées à des équipes spécifiques pour gérer les responsabilités et la visibilité.",
    SECTION_TEAMS_EDIT_TITLE: "Modifier l'équipe",
    SECTION_TEAMS_EDIT_TEXT:
        "Une équipe peut représenter par exemple une entreprise ou un métier. Les activités créées au Sablono peuvent être assignées à des équipes spécifiques pour gérer les responsabilités et la visibilité.",
    SECTION_TEAMS_EDIT_CONTACT_TITLE: "Données de contact",
    SECTION_TEAMS_EDIT_CONTACT_TEXT:
        "Veuillez entrer les identifieants de la personne responsable de cette équipe.",
    SECTION_TEAMS_EDIT_LOCATION_TEXT:
        "Veuillez indiquer le nom et l'adresse de l'entreprise responsable de cette équipe.",
    SECTION_TEAMS_EDIT_DETAILS_TITLE: "Informations sur l'équipe",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TITLE:
        "Renoncer aux droits administratifs?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TITLE:
        "Renoncer aux droits chef de projet?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TEXT:
        "Êtes-vous sûr de vouloir changer de rôle ? Ceci ne peut pas être annulé",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TEXT:
        "Etes-vous sûr de vouloir changer de rôle ? Cela ne peut pas être annulé, car vous perdrez tous les privilèges pour administrer les membres de l'équipe du projet",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TITLE: "Êtes-vous sûr?",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TEXT:
        "Voulez-vous vraiment vous retirer de ce projet? Par la suite, vous ne pourrez plus y accéder.",
    SECTION_TEAMS_PROJECT_TEAM_INFO_TOOLTIP:
        "L'équipe Accès intégral est la seule équipe qui peut éditer toutes les activités du projet même si l'activité a été assignée à une autre équipe. C'est pourquoi il convient par exemple à la gestion de projets.",
    ACTION_DELETE_TEAM: "Supprimer une équipe",
    ACTION_DELETE_USER_FROM_TEAM: "Supprimer un utilisateur",
    ACTION_CONFIRM_DELETE_USER_FROM_TEAM: "Confirmer la suppression",
    ACTION_UPLOAD_TEAM_LOGO: "Télécharger le logo",
    INFO_NO_MATCHES_FOUND: "Aucun résultat trouvé",
    INFO_CONTACT_CUSTOMER_SUPPORT:
        "Avez-vous besoin d'aide ou de plus d'informations?",
    ACTION_WATCH_VIDEO: "Regardez notre vidéo",
    _STRUCTURE: "Structure",
    _PROJECT_STRUCTURE: "Structure du projet",
    _NAME: "Nom",
    _CODE: "Code",
    _DESC: "Description",

    ENTER_FULLSCREEN: "Tableau de bord du site de départ",
    EXIT_FULLSCREEN: "Fermer le tableau de bord du site",
    LAST_UPDATED: "Dernière mise à jour des données",
    FULLSCREEN_REFRESH: "Recharger les données",

    PLANNED_DATES_TOOLTIP:
        "Ces dates ont été planifiées et fixées par vous ou l'un de vos partenaires.",
    CALCULATED_DATES_TOOLTIP:
        "Ces dates ont été automatiquement calculées par Sablono selon vos Flux de Travail.",
    FORECASTED_DELIVERY_TOOLTIP:
        'Sablono a prévu la fin de ce livrable selon vos progrès actuels. "En danger" est indiqué si une activité de ce livrable est en retard et ne fait pas partie du Flux de Travail.',
    NAME: "Nom",
    CODE: "Code",
    _PROGRESS: "Progrès",
    _INSPECTION: "Mis à jour",
    START_DATE: "Date de début",
    USER_DEFINED_START: "Début définit par l'utilisateur",
    USER_DEFINED_END: "Fin définit par l'utilisateur",
    EARLIEST_START: "Début au plus tôt (Base)",
    EARLIEST_END: "Fin au plus tôt (Base)",
    DUE_BETWEEN: "Echéance entre",
    END_DATE: "Date de fin",
    LATEST_END: "Fin au plus tard (Base)",
    LATEST_START: "Début au plus tard (Base)",
    DUE_DATE: "Date de réalisation",
    DURATION: "Durée du projet",
    DURATION_UNIT: "Unité de durée",
    LAST_PLANNER_START: "Début (Court terme)",
    LAST_PLANNER_END: "Fin (Court terme)",
    REVISION_NUMBER_TOOLTIP: "Version modèle",
    TOTAL_FLOAT: "Marge total (Base)",
    TOTAL_FLOAT_OF_X:
        "{{float >= 0 ? float + ' day' + (float === 1 ? 's' : '') : '-'}}",
    TOTAL_FLOAT_TOOLTIP:
        "Ce tampon sera disponible pour terminer le Livrable entre le Début au plus tôt et la Fin au plus tard.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_DELIVERABLE:
        "Ce tampon sera disponible pour terminer le Livrable entre le Début au plus tôt et la Fin au plus tard.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_ACTIVITY:
        "Ce tampon sera disponible pour terminer l'activité entre le Début au plus tôt et la Fin au plus tard. .",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_DELIVERABLE:
        "Ce livrable fait partie d'un chemin critique, pour cela il n'y a pas de tampon disponible.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_ACTIVITY:
        "Cette activité fait partie d'un chemin critique, pour cela il n'y a pas de tampon disponible.",
    TOTAL_FLOAT_TOOLTIP_IS_NEGATIVE:
        "Basé sur la durée de vos activités et de leur dépendances, vous ne pourrez pas faire correspondre vos dates d'achèvement définies par l'utilisateur.",
    TOTAL_FLOAT_TOOLTIP_IS_NULL:
        "La marge totale n'a pas été calculée car la date de début ou de fin n'a pas été définie pour ce chemin.",
    PRIORITY: "Priorité",
    TIME_OF_DAY: "Temps",
    PERCENTAGE_FINISHED: "fini",
    _FINISHED: "Fini",
    _REMOVE: "supprimer",
    _PROCESS: "Flux de Travail",
    _PROCESS_TEMPLATE: "Modèle de Flux de Travail",
    _STAGE: "Activité",
    _UNSTRUCTURED: "Non structuré",
    _NO_TEMPLATE_ASSIGNED: "Aucun Flux de Travail affecté",
    _NOT_SET: "Aucun",
    _SELECT_STATUS: "...et son état.",
    _ANY: "Tous",
    _LAST_INSPECTION: "Dernière mise à jour",
    WORK_SCHEDULED: "Travaux planifiés",
    _LEGEND: "Légende",
    _STATE: "Statut",
    _ACTIVITY_NUMBER: "Nombre d'activités",
    _DELIVERABLE_NUMBER: "Número de entregas",
    INSPECTED_BY: "Dernier rapport de {{timestamp}} par {{inspector}}.",
    LAST_MODIFIED_BY: "Dernière édition le {{timestamp}} par {{user}}",
    REMOVED_BY: "Supprimé le {{timestamp}} par {{user}}",
    EDITED: "Édité",
    REMOVED: "Supprimé",

    _FILTER_NAME_TEXT_PLACEHOLDER: "Filtrer par nom du livrable...",
    _FILTER_CODE_TEXT_PLACEHOLDER: "Filtrer par code du livrable...",
    _FILTER_TYPE_TEXT_PLACEHOLDER: "Filtrer par type du livrable...",
    _FILTER_DESC_TEXT_PLACEHOLDER: "Filtrer par description du livrable...",
    _FILTER_STAGE_TEXT_PLACEHOLDER:
        "Rechercher et sélectionner une Activité ou un Groupe...",
    _FILTER_LABEL_STAGE: "État du livrable",
    _FILTER_LABEL_PROCESS: "Modèle de Flux de Travail",
    _FILTER_WORKFLOW_TEXT_PLACEHOLDER:
        "Rechercher et sélectionner un modèle de Flux de Travail...",
    _FILTER_STRUCTURE_TEXT_PLACEHOLDER:
        "Rechercher et sélectionner une partie de la structure du projet...",
    _FILTER_SEARCH_DELIVERABLE_PLACEHOLDER:
        "Recherchez les produits livrables par nom, code ou description...",
    _FILTER_SEARCH_TEAM_MEMBERS_PLACEHOLDER:
        "Recherche des membres de l'équipe...",
    _FILTER_SEARCH_NOTES_PLACEHOLDER: "Recherche de notes par texte...",
    _SEARCH_BY_STAGE_PLACEHOLDER: "Rechercher une activité",
    _DELIVERABLE_SEARCH: "Effectuez une recherche",
    _ACTION_SHOW_ADVANCED_FILTERS: "Options avancées",
    _ACTION_SHOW_REDUCED_FILTERS: "Options élémentaires",
    LINK_DOWNLOAD_MACRO: "/downloads/modAstaSablonoImporter.bas",
    LINK_VERSION_PAGE:
        "https://support.sablono.com/en/articles/4783534-sablono-web-platform-updates",
    LINK_SUPPORT_CENTER: "https://support.sablono.com",
    LINK_IDEAS_PORTAL: "https://sablono.ideas.aha.io/?sort=recent",
    LINK_IMPRESSUM: "https://www.sablono.com/legal/imprint",
    LINK_VISUALIZATION_VIDEO: "https://www.youtube.com/watch?v=mkLL8Gt1aRc",
    ACTION_LOGIN: "Connectez-vous",
    ACTION_REGISTER_USER: "Inscrivez-vous",
    ACTION_FINALISE_REGISTER_USER: "Finaliser",
    ACTION_CHANGE_USER: "Changez d'utilisateur",
    ACTION_CHANGE_PASSWORD: "Changez le mot de passe",
    ACTION_ARIA_LABEL_CHANGE_LANGUAGE: "Changez la langue",
    ACTION_SETUP_PASSWORD: "Définir un nouveau mot de passe",
    LOGIN__PASSWORD_RESET_REQUIRED_MESSAGE:
        "Vous devez définir un nouveau mot de passe pour votre utilisateur. Pour ce faire, veuillez utiliser le bouton ci-dessous.",
    ACTION_CREATE_EMPTY_TOOLTIP: "Créez votre propre projet",
    ACTION_PROJECT_FROM_IMPORT_TOOLTIP: "Importez un  projet",
    ACTION_CALL_THE_SUPPORT: "Ouvrir un chat d'assistance",
    ACTION_SHOW_ERROR_DETAILS: "Afficher les détails",
    ACTION_OPEN_IN_TEMPLATE_EDITOR: "Ouvrir dans l'éditeur de modèle",
    ACTION_ASSIGN_ACTIVITIES: "Affecter les activités",
    ACTION_SHOW_WHOLE_PROCESS: "Afficher tout le Flux de Travail",
    ACTION_SHOW_ALL_ACTIVITIES: "Afficher toutes les activités",
    ACTION_NAV_TO_IMPRINT: "Mentions légales",
    ACTION_NAV_TO_AGB: "CGV",
    ACTION_NAV_TO_FAQ: "Support en ligne",
    ACTION_NAV_TO_VERSION: "Mises à jour et communiqués",
    ACTION_NAV_TO_IDEAS: "Portail des idées",
    ACTION_NAV_TO_CONTACT: "Contact",
    ACTION_NAV_TO_APP: "Inspectez Web-App",
    ACTION_NAV_TO_LEARNING_CENTER: "Allez au Centre de Formation",
    ACTION_NAV_TO_LOGIN: "Allez pour se connecter",
    ACTION_RESEND_VERIFICATION:
        "Envoyer un nouveau code de vérification par e-mail",
    ACTION_NAV_TO_SITE_DIARY: "Journal de bord quotidien",

    ACTION_NAV_TO_LEANBOARD: "Planification Lean",
    ACTION_NAV_TO_DASHBOARD: "Tableau de bord des performances",
    ACTION_NAV_TO_PROJECT_SELECTION: "Afficher mes Projets",
    ACTION_NAV_TO_ALL_PROJECTS: "Mes Projets",
    ACTION_NAV_TO_PROJECT_DETAILS: "Paramètres du Projet",
    ACTION_NAV_TO_WBS: "Structure du Projet",
    ACTION_NAV_TO_TEAMS: "Équipes",
    ACTION_NAV_TO_PROJECT_DELIVERABLES: "Produits lLivrables",
    ACTION_NAV_TO_TEMPLATE_ELEMENT: "Modèles de Flux de Travail",
    ACTION_NAV_TO_TEMPLATE_CHECKLIST:
        "Listes de contrôle d'assurance qualité (AQ)",
    ACTION_NAV_TO_TEMPLATE_NOTES: "Modèles de notification",
    ACTION_NAV_TO_TEMPLATE_NOTES_IMPORT: "Importer: Modèles de notification",
    ACTION_NAV_TO_IMPORT_SELECTION: "Importez livrables ",
    ACTION_NAV_TO_INSPECTIONS: "Historique des action",
    ACTION_NAV_TO_ANALYSIS: "Livrables",
    ACTION_NAV_TO_PROCESS_VIEWER: "Activités",
    ACTION_NAV_TO_TRACKER: "Tableau des Activités",
    ACTION_NAV_TO_NOTES: "Notes & Problèmes",
    ACTION_NAV_TO_HELP_FEEDBACK: "Aide et retour d'information",
    ACTION_NAV_TO_USER_PROFILE: "Mon Profil",
    ACTION_NAV_TO_VISUALIZATION: "Progrès Visuel",
    ACTION_NAV_TO_ASSIGN_WORKFLOW: "Affecter modèle de Flux de Travail",
    ACTION_NAV_TO_TEAM_EDIT_TOOLTIP: "Ajouter des membres de l'équipe",
    ACTION_NAV_TO_IMPORT_EXCEL: "Livrables - Microsoft© Excel",
    ACTION_NAV_TO_IMPORT_MSPROJECT: "Microsoft© Project",
    ACTION_NAV_TO_IMPORT_ASTA: "Asta Power Project",
    ACTION_NAV_TO_IMPORT_PRIMAVERA: "Oracle Primavera",
    ACTION_NAV_TO_IMPORT_XML: "XML classer",
    ACTION_NAV_TO_IMPORT_EMPTY: "Nouveau Projet",
    ACTION_NAV_TO_IMPORT_ANOTHER_SOURCE: "Importer plus de livrables",
    ACTION_NAV_TO_TOOLS: "Outils d'import/export",
    ACTION_NAV_TO_EXPORT: "Progrès du projet exporter",
    ACTION_NAV_TO_EXPORT_PRIMAVERA: "Progrès à exporter P6",
    ACTION_NAV_TO_REQUEST_ACCESS: "Demande d'accès",

    ACTION_IMPORT_INTEGRATE:
        "Integrate Sablono into your familiar tools | Sablono Help Center",
    ACTION_IMPORT_INTEGRATE_URL:
        "https://support.sablono.com/en/articles/2967622-step-4-integrate-sablono-into-your-familiar-tools",

    ACTION_ASK_IMPORT_SOURCE: "Vous n'avez pas trouvé votre outil?",
    ACTION_ASK_IMPORT_SOURCE_SUBTITLE: "Contactez-nous maintenant",
    ACTION_ISSUE_EDITOR: "Créer une nouvelle notification",

    SECTION_PROJECT_SELECTION_TITLE: "Mes Projets",

    SECTION_DASHBOARD_TITLE: "Tableau de bord des performances",
    SECTION_PROJECT_DETAIL_TITLE: "Paramètres du projet",
    SECTION_PROJECT_DELIVERABLES_TITLE: "Livrables",
    SECTION_TEMPLATE_ELEMENTS_TITLE: "Modèles de Flux de Travail",
    SECTION_TEMPLATE_NOTES_IMPORT_TITLE: "Importer: Modèles de notification",
    SECTION_INSPECTIONS_TITLE: "Historique des action",
    SECTION_ANALYSIS_TITLE: "Avancement du projet",
    SECTION_NOTES_TITLE: "Notifications",
    SECTION_IMPORT_UPLOAD_TITLE: "L'importation sera terminée",
    SECTION_EXCEL_IMPORT_TITLE: "Microsoft© Excel Import",
    SECTION_XML_IMPORT_TITLE: "Importation XML",
    SECTION_NOTE_TEMPLATES_TITLE: "Modèles de Notes",
    SECTION_TEMPLATES_TITLE: "Modèles de Flux de Travail",
    SECTION_WBS_TITLE: "Structure du Projet",
    SECTION_IMPORT_SELECTION_TITLE: "Importer: Données du Projet",
    SECTION_EXPORT_TITLE: "Progrès à l'Exportation",

    SECTION_EXCEL_IMPORT_DATES_TITLE: "Importation de Microsoft© Excel",
    TUTORIAL_IMPORT_DATES:
        "https://support.sablono.com/en/articles/5879573-how-to-import-activity-dates-from-microsoft-excel",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TITLE:
        "Dates et durées importées avec succès",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TEXT:
        "{{numberOfUpdates}} activités ont reçu de nouvelles dates et/ou durées.",
    SECTION_EXCEL_IMPORT_DATES_TEXT:
        "Veuillez vous assurer que votre Excel a le format suivant avant l'importation: <br> Code du livrable - Nom de l'activité - Début - Fin - Format de la date - Durée - Unité - ID du projet <br> Veuillez noter que vous ne pourrez pas importer les dates de début et de fin pour la même activité en même temps.",

    SECTION_TOOLS_TITLE: "Outils d'Importation/Exportation",
    SECTION_TOOLS_IMPORT:
        "Actualiser votre projet Sablono avec la dernière version de votre planning",
    SECTION_TOOLS_EXPORT:
        "Exporter l'état d'avancement de votre projet pour l'utiliser dans votre outil de planification",
    SECTION_TOOLS_TILE_IMPORT:
        "<h3>Importation</h3> de <b>nouveaux</b> livrables, progrès ou dates des activités",
    SECTION_TOOLS_TILE_UPDATE:
        "<h3>Mise à jour Import</h3> à éditer <b>existant</b> livrables",
    SECTION_TOOLS_TILE_EXPORT:
        "<h3>Exporter</h3> l'avancement de votre projet pour l'utiliser dans d'autres programmes",
    SECTION_TOOLS_IMPORT_ACTIVITIES:
        "Données pour le traceur existant - Microsoft© Excel",
    SECTION_TOOLS_IMPORT_PACKAGE:
        "Nouveau tracker d'activité - Microsoft© Excel",

    SECTION_TOOLS_GO_DASHBOARD: "Aller au tableau de bord",
    SECTION_REQUEST_FOR_INFORMATION_TITLE:
        "Rapport sur l'état d'avancement des travaux",
    SECTION_REQUEST_FOR_INFORMATION_OPT_OUT_TITLE:
        "Rapport sur l'état d'avancement des travaux",
    SECTION_TOOLS_DIALOG_CREATED: "Importer à {{at}} par {{by}}",
    SECTION_TOOLS_DIALOG_UPDATED: "Dernière mise à jour à {{at}} par {{by}}",
    SECTION_TOOLS_DIALOG_MERGE_TITLE: "Actualiser votre projet Sablono",
    SECTION_TOOLS_DIALOG_MERGE_TEXT:
        "Veuillez sélectionner le fichier source que vous souhaitez utiliser pour actualiser votre projet.",
    SECTION_TOOLS_DIALOG_EXPORT_TITLE: "Exporter l'avancement de votre projet",
    SECTION_TOOLS_DIALOG_EXPORT_TEXT:
        "Veuillez sélectionner la partie de votre projet pour laquelle vous souhaitez exporter la progression.",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TILE:
        "@:INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TEXT:
        "Exporter l'état d'avancement du projet complet dans un fichier CSV.",
    SECTION_EXPORT_SELECT_FILE_SUBTITLE:
        "L'état d'avancement de votre projet sera écrit dans le fichier que vous téléchargez. <br/> Vous pouvez ensuite le télécharger et l'importer dans l'outil que vous utilisez.",
    SECTION_EXPORT_PROCESSING_TITLE: "Nous actualisons votre dossier...",
    SECTION_EXPORT_PROJECT_PROGRESS_TITLE:
        "Nous exportons l'avancement de votre projet...",
    SECTION_EXPORT_SUCCESS_TITLE: "Votre fichier est prêt à être télécharger.",
    SECTION_EXPORT_SUCCESS_TITLE_PRIMAVERA:
        "Votre fichier mis à jour est prêt à être télécharger.",
    SECTION_EXPORT_SUCCESS_SUBTITLE:
        "L' état de {{changed}} {{(changed==1)?'task':'task'}} a été actualisé .",
    SECTION_EXPORT_CSV_SUCCESS_SUBTITLE:
        "{{platformTotal}} {{(platformTotal==1)?'line has':'lines have'}} été exporter.",
    SECTION_EXPORT_ASTA_RESOURCES:
        "Pour importer l'état d'avancement de votre projet dans Asta Power Project, veuillez utiliser la macro fournie ci-dessous.",
    SECTION_EXPORT_ASTA_RESOURCES_DETAIL:
        "* Si vous avez utilisé la macro avant, vous n'aurez pas besoin de la télécharger à nouveau.",
    SECTION_EXPORT_WRONG_FILE_TITLE:
        "Le fichier que vous avez sélectionné ne semble pas correspondre à votre projet Sablono...",
    SECTION_EXPORT_WRONG_FILE_SUBTITLE:
        "Les tâches de votre dossier ne sont pas liées à celles du Sablono. Pour continuer, veuillez sélectionner un autre fichier.",
    SECTION_EXPORT_UP_TO_DATE_FILE_TITLE: "Votre dossier est déjà mise à jour!",
    SECTION_EXPORT_UP_TO_DATE_FILE_SUBTITLE:
        "L'avancement de votre projet n'a pas changé depuis la dernière mise à jour de votre dossier.",
    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_TITLE:
        "Veuillez télécharger votre fichier Primavera",
    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_SUBTITLE:
        "Pour exporter l'avancement de votre projet, nous aurons besoin de votre projet Primavera au format de fichier Primavera-XML. <br />Après avoir téléchargé le fichier, la progression que vous avez documentée à l'aide de Sablono y sera automatiquement ajoutée.<br />Après quoi vous pourrez télécharger le fichier à nouveau et l'importer dans Primavera.",
    DIALOG_EXPORT_PROGRESS_TITLE: "Oups...",
    DIALOG_EXPORT_PROGRESS_CONTENT:
        "Malheureusement, nous ne pouvons pas détecter si vous travaillez avec un système Primavera utilisant 'virgule' ou 'point' comme séparateur. Afin de pouvoir importer le fichier que nous vous avons fourni, veuillez nous indiquer comment votre système est configuré.",
    DIALOG_EXPORT_PROGRESS_ACTION_COMMA: "Virgule",
    DIALOG_EXPORT_PROGRESS_ACTION_FULL_STOP: "Point",
    SECTION_MERGE_TITLE: "Actualiser le projet",
    SECTION_MERGE_TOOLBAR_TITLE: "Actualiser votre projet Sablono",
    SECTION_MERGE_STEP_1_STEPPER: "Sélectionnez un fichier",
    SECTION_MERGE_STEP_2_STEPPER: "Choisir les options d' actualisation",
    SECTION_MERGE_STEP_3_STEPPER: "Actualisation du projet",
    SECTION_MERGE_STEP_5_STEPPER: "Actualisation terminée",
    SECTION_MERGE_STEP_1_TITLE:
        "Pour actualiser votre projet Sablono, veuillez télécharger le fichier contenant la dernière version de votre calendrier.",
    SECTION_MERGE_STEP_1_SUBTITLE:
        "Veuillez sélectionner un fichier correspondant à <strong>{{title}}</strong> (<em>{{fileName}}</em>).",
    SECTION_MERGE_STEP_2_TITLE: "Supprimer les livrables de Sablono",
    SECTION_MERGE_STEP_2_SUBTITLE:
        "Souhaitez-vous supprimer les livrables de Sablono qui ont été importés avec ce fichier précédemment mais qui ne sont plus inclus ? Veuillez garder à l'esprit que cela supprimerait également toutes les activités et notes qui y sont liées.",
    SECTION_MERGE_STEP_2_KEEP: "Non, gardez-les",
    SECTION_MERGE_STEP_2_DELETE: "Oui, effacez-les",

    SECTION_MERGE_STEP_2A_TITLE:
        "Réactiver les livrables précédemment supprimés",
    SECTION_MERGE_STEP_2A_SUBTITLE:
        "Souhaitez-vous réactiver les livrables qui font partie de ce fichier mais qui ont été supprimés du projet en utilisant la plate-forme Sablono après l'importation précédente ?",
    SECTION_MERGE_STEP_2A_KEEP: "Non, gardez-les supprimés",
    SECTION_MERGE_STEP_2A_DELETE: "Oui, les réactiver",

    SECTION_MERGE_STEP_3_TITLE: "Nous actualisons votre projet Sablono...",
    SECTION_MERGE_STEP_4_TITLE: "Nous traitons vos commentaires...",

    SECTION_MERGE_ERROR_TITLE: "Ups, quelque chose a mal tourné...",
    SECTION_MERGE_ERROR_SUBTITLE:
        "Pour vous assurer que votre projet Sablono est actualisé avec succès, veuillez réessayer.<br/>Si vous rencontrez d'autres problèmes, n'hésitez pas à nous contacter en utilisant notre support de chat.",
    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_HEADER:
        "Actualisation du projet réussie",
    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_TEXT:
        "Votre projet Sablono, y compris vos livrables et la structure du projet, ont été actualisé selon la dernière version de votre calendrier.",
    SECTION_MERGE_SUCCESSFULL_XLS_PROJECT_UPLOAD_TEXT:
        "Votre projet Sablono, y compris vos livrables et la structure du projet, ont été actualisés avec la dernière version de votre feuille Excel.",
    SECTION_MERGE_INFO_EXCEL_MAPPING:
        "Veuillez utiliser la colonne <b>{{mappingKey}}</b> de votre fichier comme Code pour assurer une actualisation réussie.",
    SECTION_MERGE_INFO_EXCEL_MAPPING_HINT:
        "Le code est utilisé pour identifier les livrables identiques et les actualiser.",
    INPUT_USERNAME_TITLE: "Email",
    INPUT_PASSWORD_TITLE: "Mot de passe",
    INPUT_NEW_PASSWORD_TITLE: "Nouveau mot de passe",
    INPUT_PASSWORD_REPEAT_TITLE: "Répéter le mot de passe",
    INPUT_FULL_NAME_TITLE: "Nom",
    INPUT_FIRST_NAME_TITLE: "Prénom",
    INPUT_LAST_NAME_TITLE: "Nom de famille",
    INPUT_EMAIL_TITLE: "Courriel professionnel",
    INPUT_COMPANY_NAME_TITLE: "Nom de l'entreprise",
    INPUT_REGISTER_PHONE_TITLE: "Téléphone professionnel",
    INPUT_REGISTER_QUESTION_TITLE: "Comment avez-vous entendu parler de nous?",
    INPUT_REGISTER_QUESTION_OTHER_TITLE: "Comment en détail?",
    INPUT_REGISTER_QUESTION_OPTION_OTHER: "autre chose",
    INPUT_REGISTER_QUESTION_OPTION_WEBSITE: "Page d'accueil Sablono",
    INPUT_REGISTER_QUESTION_OPTION_TELESALE: "Campagne téléphonique",
    INPUT_REGISTER_QUESTION_OPTION_RECOMMENDATION: "Recommandation",
    INPUT_REGISTER_QUESTION_OPTION_GOOGLE: "Recherche sur Internet",
    INPUT_REGISTER_QUESTION_OPTION_ADVERTISMENT: "Publicité",
    INPUT_PROJECT_NAME_TITLE: "Nom du projet",
    INPUT_PROJECT_CODE_TITLE: "Code du projet",
    INPUT_PROJECT_DESCRIPTION_TITLE: "Description du projet",
    INPUT_PROJECT_START_DATE_TITLE: "Début du projet",
    INPUT_PROJECT_LANGUAGE_TITLE: "Langue du projet",
    INPUT_PROJECT_TIMEZONE_TITLE: "Fuseau horaire du projet",
    INPUT_PROJECT_START_TITLE: "Début du projet",
    INPUT_PROJECT_COMPLETION_TITLE: "Fin du projet",
    INPUT_PROJECT_TYPE: "Type de projet",
    INPUT_PROJECT_TYPE_LABEL: "Inscrivez votre réponse ici",
    INPUT_PROJECT_TYPE_OPTION_1: "Construction résidentielle",
    INPUT_PROJECT_TYPE_OPTION_2: "Construction commerciale",
    INPUT_PROJECT_TYPE_OPTION_3: "Fabrication et production",
    INPUT_PROJECT_TYPE_OPTION_4: "Énergie renouvelable",
    INPUT_PROJECT_TYPE_OPTION_5: "Patrimoine, conservation, rénovation",
    INPUT_PROJECT_TYPE_OPTION_6: "Secteur public (santé, éducation, défense)",
    INPUT_PROJECT_TYPE_OPTION_7:
        "Construction industrielle (distribution, logistique, centre de données)",
    INPUT_PROJECT_TYPE_OPTION_8: "Infrastructures, rail, routes",
    INPUT_PROJECT_TYPE_OPTION_9: "Logement privé",
    INPUT_PROJECT_TYPE_OPTION_10: "Autre",
    INPUT_PROJECT_IMAGE_SIZE_SUGGESTION: "Recommandé: 800x600px",
    INPUT_PROJECT_CURRENCY_TITLE: "Monnaie du projet",

    ACTION_CREATE_PROJECT: "Créer un projet",
    ACTION_LOGOUT: "Déconnexion",

    INPUT_REGISTER_QUESTIONNAIRE_LABEL: "Inscrivez votre réponse ici",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1:
        "Comment avez-vous connu Sablono ?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2:
        "Dans quel but souhaitez-vous utiliser Sablono ?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3:
        "Pourquoi utiliser Sablono ?",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_1:
        "Invitation personnelle ou recommandation",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_2: "Médias sociaux",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_3: "Article de blog",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_4:
        "Moteur de recherche",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_5: "Evénement",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_6: "Autre",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_1:
        "Suivi de l'avancement",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_2:
        "Qualité de la documentation",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_3: "Le reporting",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_4:
        "Gestion de l'évaluation commerciale",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_5: "Planification",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_6:
        "Réunions quotidiennes/hebdomadaires sur l'avancement des travaux",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_1:
        "Remplacer les trackers Excel existants",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_2:
        "Gestion simplifiée des échanges commerciaux",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_3:
        "Documentation de qualité dans un endroit central",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_4:
        "Rapports simplifiés depuis le site",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_5:
        "Réunions d'avancement quotidiennes",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_6:
        "Compréhension plus facile de l'état d'avancement du projet",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_7:
        "Séances de planification prévisionnelle",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_8:
        "Évaluations commerciales",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1:
        "Comment avez-vous connu Sablono Track ?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2:
        "Que suivrez-vous sur Sablono ?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3:
        "Pourquoi voulez-vous utiliser les rails Sablono ?",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_1:
        "Invitation personnelle ou recommandation",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_2: "Médias sociaux",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_3:
        "Article de blog",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_4:
        "Moteur de recherche",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_5: "Evénement",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_6: "Autre",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_1:
        "Pose de palplanches",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_2: "Aménagement",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_3: "Façades",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_4:
        "Paquet préfabriqué",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_5:
        "Travaux de structure",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_6: "Projet complet",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_7: "Coupe-feu",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_8: "Autre",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_1:
        "Remplacer les traceurs Excel existants",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_2:
        "Gestion simplifiée des échanges",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_3:
        "Rapports simplifiés depuis le site",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_4:
        "Réunions d'avancement quotidiennes",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_5:
        "Une meilleure compréhension de l'état d'avancement du projet'",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_6:
        "Gestion de l'évaluation commerciale",

    SECTION_SIDENAV_SETUP_TITLE: "Configuration",
    SECTION_SIDENAV_DASHBOARDS_AND_TRACKERS_TITLE:
        "Tableaux de bord & Visualisations",
    SECTION_SIDENAV_LISTS_TITLE: "Listes",
    SECTION_SIDENAV_SCHEDULES_AND_TIMELINES_TITLE: "Horaires & Délais",
    SECTION_SIDENAV_RESOURCES_TITLE: "Informations",
    SECTION_SIDENAV_NO_PROJECT: "Aucun projet sélectionné.",

    SECTION_CREATE_EMPTY: "Créer un nouveau projet",
    SECTION_CREATE_FROM_IMPORT: "Importer un projet à partir d'autres outils",
    SECTION_LOGIN_TITLE: "Connectez-vous",
    SECTION_REGISTRATION_TITLE: "Inscrivez-vous",
    SECTION_CREATE_PROJECT_TITLE: "Créer un projet",
    INFO_NO_INSPECTIONS: "pas encore de mises à jour",
    INFO_RECENT_INSPECTION: "dernière version",
    INFO_TEAM_MEMBER: "Membre de l'équipe",
    INFO_TEAM_MEMBERS: "Utilisateurs du flux de travail et de l'AQ",
    INFO_MORE_TEAM_MEMBER: "et 1 autre",
    INFO_MORE_TEAM_MEMBERS: "et {{numTeam}} autres",
    INFO_PROJECT_PROGRESS: "Terminé",
    INFO_PROJECT_EXPIRY:
        "Les projets de démonstration expirent après 14 jours d'inactivité.",
    INFO_COMMERCIAL_USERS: "Utilisateurs commerciaux",

    BACK: "Retour",
    CLOSE: "Fermer",
    YES: "Oui",
    NO: "Non",
    NOT_APPLICABLE: "N/A",
    PROJECT_LANGUAGE: "Langue du projet",
    PROJECT_PREVIEW: "Aperçu du projet",
    FILE_VIEW: "Visualisation des fichiers",
    PREVIEW_PROJECT: "Aperçu du projet",
    VIEW_FILE: "Voir le fichier",
    SELECT_FILE: "Sélectionner le fichier",
    REFRESH: "Rafraîchir",
    UPLOAD: "Télécharger",
    SETTINGS: "Réglages",
    VISUALIZATION_SHOW_SETTINGS_TOOLTIP: "Réglages",
    VISUALIZATION_SWITCH_SHOW_BORDER_INDICATOR: "Marquer les problèmes",
    _UPLOADED: "Téléchargé",
    _GENERATED: "Générée",

    ERROR_UNKNOWN_TITLE: "Erreur inconnue",
    ERROR_UNKNOWN_MESSAGE:
        "Une erreur inattendue s'est produite. Veuillez contacter la hotline d'assistance ou support@sablono.com",
    ERRORS_NO_DATA_TITLE: "Pas de données disponibles",
    ERRORS_NO_DATA_MESSAGE:
        "Aucune donnée n'est disponible pour la fonction souhaitée.",
    ERRORS_AUTHENTICATION_TITLE: "Erreur d'authentification",
    ERRORS_AUTHENTICATION_MESSAGE: "Votre session a expiré.",
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_TITLE: "Cette valeur existe déjà",
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_MESSAGE:
        "Cette valeur ne peut pas être utilisée car elle est déjà utilisée par le système.",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_TITLE:
        "Action annulée: Privilèges insuffisants",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_MESSAGE:
        "L'action ne peut pas être exécutée car il manque des privilèges système.",
    ERROR_HANA_VALUE_TO_LARGE_TITLE: "La valeur dépasse la limite",
    ERROR_HANA_VALUE_TO_LARGE_MESSAGE:
        "L'action ne peut pas être exécutée parce qu'une valeur dépasse la limite de la base de données.",
    MISSING_INPUT: "Action annulée: Les paramètres requis sont manquants.",
    ERROR_DATE_END_BEFORE_START:
        "La date de fin doit être antérieure à la date de début.",
    ERROR_DATE_END_BEFORE_START_MESSAGE:
        "Changement de date impossible! La date de fin doit être antérieure à la date de début.",
    ERROR_PROJECT_OPERATION_DENIED: "Action annulée: Privilèges insuffisants",
    ERROR_COMPONENT_OPERATION_DENIED: "Action annulée: Privilèges insuffisants",
    ERROR_ACTIVITY_OPERATION_DENIED: "Action annulée: Privilèges insuffisants",
    ERROR_INVALID_ALLOCATION_UNIT: "Action annulée: Type de calendrier inconnu",
    ERROR_INVALID_COLOR: "Action annulée: Couleur invalide",
    ERROR_MISSING_REQUIRED_PARAM:
        "Action annulée: Les paramètres requis sont manquants",
    ERROR_CHANGING_PROGRESS_SIGNED_CHECKLIST_REQUIRED:
        "Veuillez rendre compte de l'avancement de cette activité dans l'application mobile, car une liste de contrôle y est jointe.",
    ERROR_CONFIRMING_SIGNED_CHECKLIST_REQUIRED:
        "Veuillez rendre compte de l'avancement de cette activité dans l'application mobile, parce que une liste de contrôle s'y rattachent.",
    ERROR_CHANGING_PROGRESS_ALREADY_CONFIRMED:
        "Cette activité a déjà été confirmée.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED:
        "Tous les gestionnaires responsables doivent faire partie de la même équipe.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED_TITLE:
        "Malheureusement pas possible...",
    ERROR_CHAIN_LEAVE_AREA_MANAGER_UNSUPPORTED:
        "Ce gestionnaire responsable remplace actuellement un autre, pour cela, il ne peut pas être remplacé.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ASSIGNED:
        "L'utilisateur que vous avez sélectionné remplace déjà ce gestionnaire responsable.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ACTIVE:
        "Le remplacement est déjà activé.",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_TITLE: "Non responsable",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_MESSAGE:
        "Il semble que vous ayez sélectionné des Livrables pour lesquels un autre utilisateur est le gestionnaire responsable. Par conséquent, il n'a pas été possible d'effectuer la actualisation globale d'avancement. Veuillez ne sélectionner que les produits livrables dont vous êtes responsable et réessayer.",
    ERROR_CHANGING_PROGRESS_REJECTED_TITLE: "Changement de progrès invalide",
    ERROR_CHANGING_PROGRESS_REJECTED_MESSAGE:
        "Au moins une des activités sélectionnées est signalée comme Refusé. Une activité qui est refusée ne peut être activée que si elle est activée.",
    ERROR_CHECKLIST_EXPORT_THRESHOLD:
        "Processus interrompu! Actuellement ce service prend en charge un maximum de 2000 listes de contrôle d'assurance qualité (AQ) pour une seule demande. Veuillez répéter le processus avec moins de produits livrables ou d'activités.",
    ERROR_CHECKLIST_EXPORT_SIZE_THRESHOLD:
        "Les rapports que vous avez demandés dépassent notre limite d'exportation de {{sizeLimitInMb}} Mo. Votre demande comprend {{numberOfDocumentsIncluded}} listes de contrôle d'assurance qualité avec {{numberOfImagesIncluded}} photos ({{sizeEstimateInMbOfImages}} Mo) et {{numberOfAttachmentsIncluded}} pièces jointes PDF ({{sizeEstimateInMbOfAttachments}} Mo). Veuillez réduire la taille de votre demande d'exportation à l'aide des filtres. Si vous continuez à voir ce message, veuillez contacter notre service d'assistance pour discuter de l'option d'exportation.",
    ERROR_UNIQUE_CODE_VIOLATION:
        "La liste Excel que vous essayez d'importer dans ce projet contient des codes de livrables qui existent déjà. Si vous essayez de modifier des produits livrables existants, veuillez utiliser notre fonction de mise à jour. Si vous souhaitez importer de nouveaux produits livrables, veuillez définir les codes dans Excel qui sont uniques pour ce projet.",
    ERROR_UNIQUE_CODE_VIOLATION_TITLE: "Les codes doivent être uniques",

    ERROR_REQUEST_TITLE:
        "Erreur de requête serveur. Veuillez réessayer et si cela continue à se produire, contactez notre équipe d'assistance et donnez-nous des détails.",
    ERROR_REQUEST_MESSAGE:
        "Une erreur s'est produite lors de la communication avec le serveur. Veuillez réessayer et si cela continue à se produire, contactez notre équipe d'assistance et donnez-nous des détails.",
    ERROR_REQUEST_TIMEOUT_TITLE:
        "Demande de Pause. Veuillez réessayer et si cela continue à se produire, contactez notre équipe d'assistance et donnez-nous des détails.",
    ERROR_REQUEST_TIMEOUT_MESSAGE:
        "La demande a pris trop de temps. Veuillez réessayer et si cela continue à se produire, contactez notre équipe d'assistance et donnez-nous des détails.",
    ERROR_REQUIRED_EMAIL_USERNAME: "Email est requis",
    ERROR_REQUIRED_PASSWORD: "Mot de passe requis",
    ERROR_AUTHENTICATION_UNKNOWN_TITLE: "Connexion échouée",
    ERROR_AUTHENTICATION_UNKNOWN_MESSAGE:
        "La connexion a échoué de manière inattendue. Veuillez réessayer et si cela continue à se produire, contactez notre équipe d'assistance à l'adresse support@sablono.com.",
    ERROR_AUTHENTICATION_NO_SESSION_TITLE: "session écoulée",
    ERROR_AUTHENTICATION_NO_SESSION_MESSAGE: "Votre session a expiré.",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_TITLE:
        "Email ou mot de passe invalide",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_MESSAGE:
        "Votre email ou mot de passe n'est pas valide.",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_TITLE: "utilisateurs déactivés",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_MESSAGE:
        "Votre utilisateur est désactivé. Veuillez contacter le support technique.",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_TITLE:
        "utilisateurs expirés",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_MESSAGE:
        "Votre compte a expiré. Veuillez contacter le support technique.",
    ERROR_AUTHENTICATION_USER_LOCKED_TITLE: "utilisateurs verrouillés",
    ERROR_AUTHENTICATION_USER_LOCKED_MESSAGE:
        "Votre compte a été automatiquement verrouillé en raison d'un trop grand nombre de tentatives de connexion. Veuillez attendre 15 minutes et réessayer. Si le problème persiste, veuillez contacter notre équipe d'assistance à l'adresse support@sablono.com.",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_TITLE:
        "Changement de mot de passe requis",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_MESSAGE:
        "Veuillez changer votre mot de passe avant de continuer.",
    ERROR_PASSWORD_NOT_EQUAL_TITLE: "Le mot de passe n'est pas identique",
    ERROR_INPUT_REQUIRED_TITLE: "Champ obligatoire",
    ERROR_INPUT_EMAIL_REQUIRED_TITLE:
        "Veuillez entrer un email pour vous contacter.",
    ERROR_MINLENGTH_TITLE: "min. {{minlength}} caractères",
    ERROR_MAXLENGTH_TITLE: "max. {{maxlength}} caractères",
    ERROR_MIN_ONE_LOWERCASE_LETTER_TITLE: "au moins une lettre minuscule",
    ERROR_MIN_ONE_UPPERCASE_LETTER_TITLE: "au moins une majuscule",
    ERROR_MIN_ONE_SPECIAL_CHAR_TITLE: "au moins un caractère spécial",
    ERROR_MIN_ONE_NUMBER_TITLE: "au moins un chiffre (0-9)",
    ERROR_NUMBER_ONLY: "Entrez un numéro valide",
    ERROR_NO_DECIMAL: "Veuillez utiliser que des nombres entiers",
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_REMOVE_USER_FROM_GROUP_MESSAGE:
        "Vous n'êtes pas autorisé de supprimer un utilisateur",
    ERROR_GROUP_DOES_NOT_EXIST_MESSAGE:
        "Le groupe n'existe pas sur la plateforme",
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_ADD_USER_TO_GROUP_MESSAGE:
        "Vous n'êtes pas autorisé d' ajouter un utilisateur",
    ERROR_USER_ALREADY_IN_PROJECT:
        "L'utilisateur que vous ajoutez est déjà dans le projet",
    ERROR_USER_ALREADY_IN_PROJECT_MESSAGE: "{{Users}} sont déjà dans le projet",
    ERROR_LAST_ADMIN_IN_PROJECT:
        "Un projet doit avoir au moins un administrateur",
    ERROR_FORBIDDEN_USER_IN_MULTIPLE_SYSTEM_GROUPS_MESSAGE:
        "Il n'est pas autorisé d'ajouter un utilisateur dans plusieurs groupes par défaut ou groupes système.",
    ERROR_UNEXPECTED_TITLE:
        "Ups... ça ne devrait pas arriver. Retournez au tableau de bord.",
    ERROR_UNSUPPORTED_FILE_EXTENSION:
        "Type de fichier non valide. Type de fichiers pris en charge:{{fileTypes}}.",
    ERROR_FAILED_FILE_UPLOAD: "Le téléchargement a échoué. Veuillez réessayer.",
    ERROR_MULTIPLE_INVITATIONS_PER_PROJECT: "L'utilisateur est déjà invité",
    DIALOG_ALERT_OK: "OK",
    DIALOG_CONFIRM_OK: "OK",
    DIALOG_CONFIRM_CANCEL: "Annuler",
    DIALOG_CONFIRM_CREATE: "Sauvegarder",
    DIALOG_CONFIRM_CREATE_AND_NEW: "Sauvegarder et créer un autre",
    DIALOG_SELECT_OK: "Sélectionnez",
    DIALOG_SELECT_CANCEL: "Annuler",
    DIALOG_SELECT_NEXT: "Suivant",
    DIALOG_SELECT_BACK: "Retour",
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE:
        "Avertissement du service d'assistance technique!",
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT:
        "Certaines extensions bloquent le service d'assistance, veuillez désactiver les bloqueurs d'annonces pour assurer une expérience fluide.",
    DIALOG_FETCH_PROGRESS_ERROR_TITLE: "Récupérer l'échec de progression",
    DIALOG_FETCH_PROGRESS_ERROR_CONTENT:
        "Une erreur s'est produite lors de la récupération de la progression, veuillez réessayer.",
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE: "Oups...",
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT:
        "Malheureusement, nous ne pouvons pas appliquer le Filtre de Structure de Projet actuellement sélectionné en raison des limitations communes de votre navigateur Web. Veuillez sélectionner une partie de structure plus profonde (par ex. niveau au lieu de bâtiment) ou un autre critère de filtrage.",
    DIALOG_WRONG_EXTENSION_TITLE: "Le fichier n'a pas le bon format",
    DIALOG_WRONG_EXTENSION_CONTENT:
        "Veuillez sélectionner un fichier avec le bon format.",
    DIALOG_READING_FILE_ERROR_TITLE: "Erreur de lecture de fichier",
    DIALOG_READING_FILE_ERROR_CONTENT:
        "Une erreur s'est produite lors de la lecture du fichier, veuillez réessayer.",
    DIALOG_ANALYSING_FILE_ERROR_TITLE: "Erreur de lecture de fichier",
    DIALOG_ANALYSING_FILE_ERROR_CONTENT:
        "Une erreur s'est produite lors de la lecture du fichier, certains champs sont manquants ou corrompus. Veuillez vérifier votre dossier.",
    DIALOG_IMPORT_FILE_ERROR_TITLE: "Erreur d'importation de fichier",
    DIALOG_IMPORT_FILE_ERROR_CONTENT:
        "Une erreur s'est produite lors de l'importation du fichier, veuillez réessayer.",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF_TITLE:
        "@:SECTION_MERGE_ERROR_TITLE",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF:
        "Le PDF n'a pas pu être exporté en raison du grand nombre de pages. Veuillez sélectionner une plage de temps plus petite ou un nombre de livrables plus faible.",

    DIALOG_USER_CANCELED: "Action annulée",
    DIALOG_IMPORT_MERGE_CONFLICT_TITLE: "Avertissement",
    DIALOG_IMPORT_MERGE_CONFLICT_CONTENT:
        "Il semble que vous êtes sur le point d'importer un planning qui est très similaire à celle qui a déjà été importée dans votre projet. Si vous choisissez de continuer, chaque tâche sera ajoutée à votre projet Sablono à nouveau. Vous pouvez également mettre à jour votre projet pour éviter la duplication d'éléments.",
    DIALOG_IMPORT_MERGE_ACTION: "Actualiser le projet",
    DIALOG_IMPORT_CONTINUE_ACTION: "Continuer l'importation",
    DIALOG_ASSIGN_TEMPLATE_TITLE:
        "Affectation d'un Modèle de Flux de Travail à un Livrable",
    DIALOG_ASSIGN_TEMPLATE_TEXT:
        "Sélectionnez un Modèle de Flux de Travail que vous voulez affecter au Livrable actuellement sélectionné.",
    DIALOG_ASSIGN_TEMPLATE_NO_DATA_TEXT:
        "Il semble que vous n'ayez pas encore créé de modèle de Flux de Travail. Utilisez le bouton ci-dessous pour ouvrir l'éditeur.",
    DIALOG_ASSIGN_TEMPLATE_NO_TEMPLATES_NAV_TO:
        "Aller à la création de modèles",
    ACTION_ASSIGN_TEMPLATE: "Affecter modèle",
    DIALOG_ASSIGN_TEMPLATE_SEARCH_PLACEHOLDER:
        "Recherche de modèles par Nom, Code...",
    DIALOG_ASSIGN_TEMPLATE_EMPTY_SEARCH_TEXT:
        "Il n'y a pas de modèle correspondant à votre terme de recherche.",
    DIALOG_CLONE_TEMPLATE_TITLE: "@:ACTION_CLONE_TEMPLATE",
    DIALOG_CLONE_TEMPLATE_TEXT:
        "Veuillez sélectionner le Modèle de Flux de Travail que vous souhaitez copier dans ce projet dans la liste ci-dessous.",
    DIALOG_CLONE_TEMPLATE_BUTTON_TEXT: "Copier le modèle",
    DIALOG_CLONE_TEMPLATE_SUCCESS_TOAST_TEXT:
        "Modèle de Flux de Travail copié avec succès",
    DIALOG_CLONE_TEMPLATE_SEARCH_PLACEHOLDER:
        "Recherche de modèles par nom, code et projet...",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_AND_QA_TITLE:
        "Maintenant, spécifiez ce qui doit se passer avec les listes de contrôle et les équipes d'AQ liées au modèle de Flux de Travail sélectionné.",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_SKIP:
        "Ne copiez et ne joignez aucune liste de contrôle ou équipe d'assurance qualité",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_REUSE:
        "Utilisez les listes de contrôle et les équipes existantes et créez-en de nouvelles pour les attacher au modèle de Flux de Travail copié",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_CLONE:
        "Copier toutes les listes de contrôle et équipes d'AQ et les attacher au modèle de Flux de Travail copié (peut entraîner des doublons)",

    DIALOG_CREATING_PROJECT: "Créer un projet",
    DIALOG_BUSY_TAKE_TIME: "Cela peut prendre un certain temps.",
    DIALOG_CREATE_ACTIVITY_TOOLBAR_TITLE: "Créer une nouvelle activité",
    DIALOG_ACTION_CREATE_ACTIVITY: "Créer une activité",
    _RECENT_ACTIVITIES: "Activités récemment créées",
    _ADD_ACTIVITY_TOOLTIP: "Ajouter une activité à ce livrable.",
    ROWS_PER_PAGE: "Nombre de lignes par page",
    UPLOAD_ERROR: "Téléchargement échoué",
    ACTION_CREATE_DELIVERABLES: "Ajouter des livrables",
    DIALOG_CREATE_NEW_DELIVERABLES_TITLE: "Créer de nouveaux livrables",
    INPUT_NAME_NEW_DELIVERABLE_PLACEHOLDER:
        "Par exemple: Appartement 3.2.1, pièce 202, panneau de façade 3777-08",
    INPUT_CODE_NEW_DELIVERABLE_PLACEHOLDER:
        "Par exemple: App. 3.2.1, R-202, FP-3777-08 - 64 caractères max.",
    DIALOG_CREATE_NEW_DELIVERABLE_SAVED: "Livrable enregistrer",
    DIALOG_CREATE_NEW_DELIVERABLES_ERROR_EXISTING_CODE:
        "Erreur: Il y a déjà un livrable avec ce code dans le projet. Veuillez en choisir un autre.",
    UPLOAD_SUCCESSFUL: "Téléchargement réussi",
    UPLOAD_SUCCESSFUL_CREATED_INFO:
        "{{elements}} livrables{{(elements > 1) ? 's' : ''}} créé{{(elements > 1) ? 's' : ''}} et ajouté{{(elements > 1) ? 's' : ''}} à votre projet.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO:
        "{{elements}} livrable{{(elements > 1) ? 's' : ''}} actualisé{{(elements > 1) ? 's' : ''}} avec les nouvelles valeurs du fichier.",
    UPLOAD_SUCCESSFUL_DELETED_INFO:
        "{{elements}} livrable{{(elements > 1) ? 's' : ''}} retiré{{(elements > 1) ? 's' : ''}} du projet.",
    UPLOAD_SUCCESSFUL_CREATED_STRUCTURE_INFO:
        "{{elements}} éléments structurels créé{{(elements > 1) ? 's' : ''}} et ajouté{{(elements > 1) ? 's' : ''}} à votre projet.",
    UPLOAD_SUCCESSFUL_UPDATED_STRUCTURE_INFO:
        "{{elements}} élément{{(elements > 1) ? 's' : ''}} structurel{{(elements > 1) ? 's' : ''}} actualisé{{(elements > 1) ? 's' : ''}} avec les nouvelles valeurs du fichier.",
    UPLOAD_SUCCESSFUL_DELETED_STRUCTURE_INFO:
        "{{elements}} élément{{(elements > 1) ? 's' : ''}} structure{{(elements > 1) ? 's' : ''}} retiré du projet.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO_NO_FILE:
        "{{elements}} livrable{{(elements > 1) ? 's' : ''}} actualisé{{(elements > 1) ? 's' : ''}} avec de nouvelles valeurs.",
    UPLOAD_SUCCESS_WARN_LIMITS_TITLE:
        "Le projet que vous venez d'importer contient du texte dépassant les limites de notre système. Pour pouvoir importer, nous avons dû le raccourcir en conséquence.",
    UPLOAD_SUCCESS_WARN_LIMITS_MESSAGE:
        "Nos limites sont: 64 caractères pour les codes livrables, 120 caractères pour les noms livrables et 1000 caractères pour les descriptions livrables.",
    TO_PROJECT_DATA: "Voir les livrables",
    GO_TO_DELIVERABLES: "Aller aux livrables",
    DIALOG_UPLOADING_FILE_ERROR_TITLE: "Erreur de téléchargement de données",
    DIALOG_UPLOADING_FILE_ERROR_CONTENT:
        "Une erreur s'est produite pendant télécharger des données, veuillez réessayer.",
    DIALOG_UPLOADING_REMAINING_TIME_LEFT:
        "Temps restant estimé: {{time}} secondes.",
    DIALOG_PREVENT_CLOSING:
        "Il y a un Processus en cours qui ne devrait pas être interrompu!",
    INFO_IMPORT_PROJECT_DATA_INTRODUCTION_MESSAGE:
        "Lors de la création d'un nouveau projet, veuillez d'abord importer les livrables à partir de Microsoft© Excel ou d'Asta Powerproject.\n Par la suite, vous pouvez également importer les dates d'activité ou l'état d'avancement via Microsoft© Excel.",
    IMPORT_UPLOAD_COMPONENTS_IN_PROGRESS:
        "Nous envoyons des éléments {{ elements }} à Sablono",
    SECTION_IMPORT_SUCCESSFULL_PROJECT_IMPORT_HEADER:
        "Projet importé avec succès",
    LEVEL: "Niveau ",
    ASSIGN_PROJECT_ELEMENTS: "Livrables",
    CREATE_STRUCTURE: "Structure du projet",
    ADD_STURCUTRE_LEVEL: "Ajouter Niveau",
    NOT_ASSIGNED: "Non Attribué",
    INFO_IMPORT_NO_DEFINED_STRUCTURE: "Aucune structure n'a été définie",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_TITLE: "Structures incohérentes",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_MESSAGE:
        "Les structures dans les lignes {{ invalidRowNumberRanges.join(', ') }} sont incohérentes. Veuillez réviser vos données dans le fichier d'importation et réessayer.",
    INFO_HOW_TO_IMPORT_A_EXCEL_PROJECT:
        "Comment importer un projet Microsoft Excel",
    NO_MATCH_FOUND: "Aucune correspondance trouvée...",
    STRUCTURE: "Structure du projet",
    STRUCTURE_BRACES: "Structure du projet (tâches sommaires)",
    PROJECT_ELEMENTS: "Livrables",
    PROJECT_ELEMENTS_BRACES: "Livrables (tâches)",
    REFRESH_PREVIEW: "Rafraîchir l'aperçu du projet",
    ACTION_REFRESH_SELECTED_FILE_TOOLTIP: "Rafraîchir le fichier sélectionné",
    PLEASE_SELECT_FILE: "Veuillez sélectionner un fichier",
    EDGE_MODE_ACTIVE:
        "Mode Edge Active - Cliquez sur un Nœud pour créer un bord entre eux.",
    EDGE_CREATE_SUCCESSFUL: "Dépendance créée avec succès",
    EDGE_DELETE_SUCCESSFUL: "Dépendance supprimée avec succès",
    TOGGLE_EDIT_MODE_TOOLTIP: "Modifier",
    ACTION_ACTIVATE_EDIT_MODE: "Modifier le livrable",
    ACTION_DEACTIVATE_EDIT_MODE: "Terminer la modification",
    XML_IMPORT_NO_STRUCTURE:
        "Nous n'avons pas pu détecter une structure de projet dans le fichier que vous essayez de télécharger. Après la importation est terminée, vous pouvez le configuer à l'aide de l'éditeur d'organigramme des tâches de projet.",
    PDF_GEN_PROGRESS: "Fichier généré.",
    ACTION_QR_CODE_GENERATE: "Imprimer les codes QR",
    ACTION_QR_CODE_GENERATE_TOOLTIP:
        "Créez des codes QR imprimables pour votre structure au format PDF.",
    QR_DIALOG_TITLE: "Sélectionnez un format",
    QR_2x6_TITLE: "2 x 6 QR-Codes",
    QR_1x2_TITLE: "1 x 2 QR-Codes",
    QR_STICKER_POWERED_BY: "Powered by ",
    QR_STICKER_WARN_TEXT:
        "L'autocollant ne peut être enlevé que sur instruction du propriétaire.",
    QR_DIALOG_LAST_LEVEL_TEXT:
        "Impression pour les éléments de structure du dernier niveau",
    QR_DIALOG_SELECT_STRUCTURE_TITLE:
        "Impression uniquement pour les éléments de structure sélectionnés",
    QR_DIALOG_STRUCTURE_SELECTION_TEXT:
        "Créer des QR-Codes pour une sélection d'éléments de structure",
    QR_DIALOG_STRUCTURE_ALL_TEXT:
        "Impression pour tous les éléments de structure",
    ERROR_REPORT_SIZE_LIMIT_TITLE: "Rapport trop volumineux",
    QR_DIALOG_ERROR_REPORT_SIZE_MESSAGE:
        "Veuillez créer vos codes QR pour moins de {{max}} produits à livrer.",
    QR_DIALOG_WBS_ERROR_REPORT_SIZE_MESSAGE:
        "Veuillez créer vos codes QR pour moins de {{max}} structure sélectionnés.",
    DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE:
        "Veuillez créer ce rapport pour moins de {{max}} livrables.",
    ACTIVITIES_REPORT_ERROR_SIZE_MESSAGE:
        "Veuillez créer ce rapport pour moins de {{max}} activités.",

    ACTION_GENERATE_CODES: "Générer des codes",
    ACTION_GENERATE_CODES_TOOLTIP:
        "Générez des codes pour tous vos nœuds structurels.",
    CODE_GENERATION_CONFIRM_DIALOG_TITLE: "Génération de code de structure",
    CODE_GENERATION_CONFIRM_DIALOG_CONTENT:
        "De nouveaux codes seront générés pour tous les éléments du Organigramme des tâches du projet. Les codes précédents seront écrasés. Voulez-vous continuer?",
    CODE_GENERATION_SUCCESS_MESSAGE: "Codes générés.",
    ACTION_MERGE_STRUCTURE: "Combiner les structures",
    ACTION_MERGE_STRUCTURE_TOOLTIP:
        "Combiner automatiquement la structure dupliquée.",
    DIALOG_MERGE_STRUCTURE_TITLE: "Combinez la structure de votre projet",
    DIALOG_MERGE_STRUCTURE_TEXT:
        "Veuillez sélectionner la structure du projet (par source) dans laquelle vous souhaitez intégrer les parties de structure dupliquées.<br /><br /><b>Hint:</b> Afin de combiner les parties de structure, leur nom et niveau de structure doivent être exactement les mêmes.",
    DIALOG_MERGE_STRUCTURE_LOADING_TITLE: "Juste quelques secondes...",
    DIALOG_MERGE_STRUCTURE_LOADING_TEXT:
        "Nous sommes en train de combiner votre structure de projet...",
    DIALOG_MERGE_STRUCTURE_SUCCESS_TITLE: "Structure du projet combinée",
    WBS_CREATE_CHILD_TOOLTIP: "Ajouter une sous-catégorie",
    WBS_CREATE_SIBLING_TOOLTIP: "Ajouter une catégorie associée",
    WBS_DELETE_NODE_TOOLTIP: "Supprimer catégorie",
    CONFIRM_DELETE_STRUCTURE_NODE_TITLE: "Etes-vous confiant?",
    CONFIRM_DELETE_STRUCTURE_NODE_TEXT:
        "Voulez-vous vraiment supprimer cette partie de la structure de votre projet?",
    SUCCESSFUL_USER_SIGNUP:
        "Nous avons obtenu vos informations et nous vous contacterons dès que possible.",
    SUCCESSFUL_USER_SIGNUP_GET_MORE_INFO:
        "Entre les deux, apprenez à mieux connaître Sablono:",
    PROJECT_SELECTION_PENDING_INVITES_TITLE: "Invitations en attente",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_ACCEPT: "Accepter",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_DECLINE: "Refuser",
    PROJECT_SELECTION_ACCEPT_INVITATION: "Invitation acceptée",
    PROJECT_SELECTION_DECLINE_INVITATION: "Invitation refusée",
    PROJECT_SELECTION_WELCOME_CARD_TITLE: "Bienvenue au Sablono!",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_TEXT:
        "Vous n'êtes pas encore membre d'un projet? Veuillez communiquer avec votre gestionnaire de projet pour demander l'accès.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_BUTTON_TEXT:
        "Envoyer un email maintenant",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_TEXT:
        "Si vous avez des questions, n'hésitez pas à nous contacter en semaine de 9h à 18h.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_BUTTON_TEXT:
        "Contacter l'assistance",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT:
        "Veuillez m'ajouter au projet sur Sablono",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY:
        "Salut,%0D%0A%0D%0A J'ai activé mon compte sur Sablono. Veuillez m'ajouter au projet maintenant pour que je puisse commencer à utiliser la plateforme.%0D%0A%0D%0A Beaucoup de remerciements!%0D%0A%0D%0A Allez à Sablono: https://app.sablono.com/",
    PROJECT_SELECTION_FILTERS_STATUS: "Status",
    PROJECT_LANGUAGE_HELP_TOOLTIP_MESSAGE:
        "La langue du projet régle les suggestions que vous obtenez sur la plateforme.",
    PROJECT_TIMEZONE_HELP_TOOLTIP_MESSAGE:
        "Le fuseau horaire du projet sera utilisé pour planifier les activités que vous mettrez en place sur la plateforme.",
    SECTION_INSPECTIONS_PROGRESS:
        "Tendance des progrès (10 dernières semaines)",
    DASHBOARD_DELIVERABLES_UNSCHEDULED:
        "Livrables sans date de début et de fin",
    DASHBOARD_DELIVERABLES_BEHIND:
        "Livrables en retard par rapport à l'échéancier",
    DASHBOARD_DELIVERABLES_WITH_CLAIMS:
        "Livrable{{(items ===0 || items > 1) ? 's' : ''}} avec des problèmes de qualité en suspens",
    DASHBOARD_DELIVERABLES_WITH_OBSTRU:
        "Livrables avec des obstructions non résolu",
    DASHBOARD_DELIVERABLES_WITH_INFO: "Livrables avec des notes d'information",
    DASHBOARD_ALL_DELIVERABLES: "Tous les livrables",
    DASHBOARD_STATS_CHART_SELECTION_TITLE:
        "Diagramme à barres: comparaison entre le planifié l'achèvement réel des travaux",
    DASHBOARD_STATS_CHART_SELECT_TYPE:
        "Sélectionner un modèle de Flux de Travail",
    DASHBOARD_S_CURVE_CHART_SELECTION_TITLE:
        "Courbe en S: Comparaison entre les prévisions et l'achèvement réel des travaux",
    DASHBOARD_S_CURVE_CHART_CHART_SELECT_TYPE: "Sélectionnez les activités",
    DASHBOARD_S_CURVE_CHART_CHART_DESELECT_TYPE:
        "Supprimer la sélection des activités",
    DASHBOARD_S_CURVE_CHART_SELECTION_LABEL: "Sélection:",
    DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING:
        "Toutes les activités qui répondent aux filtres sélectionnés",
    DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH:
        "Aucun résultat ne correspond à vos critères de filtrage",
    DASHBOARD_PRODUCTIVITY_CHART_SELECTION_TITLE:
        "Graphique de productivité: Planifié vs. réel par semaine",
    DASHBOARD_CHARTS_LEGEND_PLANNED: "Prévue",
    DASHBOARD_CHARTS_LEGEND_FINISHED: "Travaux terminés",
    DASHBOARD_CHARTS_LEGEND_CONFIRMED: "Confirmé",
    DASHBOARD_CHARTS_LEGEND_UNPLANNED: "Non planifié",
    DASHBOARD_CHARTS_LEGEND_COMPLETED:
        "Finalisé : Confirmé + Terminé après l'équipe responsable",
    FILTER_BY_STRUCTURE: "Filtrer selon la structure",
    FILTER_BY_ALL_STRUCTURES: "Projet complet",
    DASHBOARD_S_CURVE_CHART_EMPTY:
        "Aucun résultat ne correspond à vos critères de filtrage. Si vous avez sélectionné des activités spécifiques, vous pouvez supprimer cette sélection ci-dessous.",
    DASHBOARD_BAR_CHART_EMPTY:
        "Aucun résultat ne correspond à vos critères de filtrage.",
    DASHBOARD_FILTER_BY_TEAM: "Filtrer par équipe",
    DASHBOARD_FILTER_BY_ALL_TEAMS: "Toutes les équipes",
    DASHBOARD_FILTER_BY_PROCESS_TEMPLATE:
        "Filtrer par modèle de Flux de Travail",
    DASHBOARD_FILTER_BY_ALL_PROCESS_TEMPLATES: "Tous les Flux de Travail",

    DASHBOARD_SELECT_ACTIVITIES_DIALOG_TITLE: "Filtrer par activités",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SEARCHBAR_PLACEHOLDER:
        "Recherche d'activités par modèle de Flux de Travail, nom et équipe...",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_DESCRIPTION:
        "Veuillez sélectionner la combinaison d'activités que vous souhaitez voir dans la courbe en S:",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SELECT_BUTTON: "Afficher la combinaison",

    SECTION_DASHBOARD_NO_DATA_TITLE:
        "Veuillez terminer la configuration de votre projet",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TITLE: "Créez vos premiers livrables",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TEXT:
        "Vous pouvez importer des livrables depuis Microsoft© Excel et Asta Powerproject. Ou utilisez le bouton ci-dessus pour les créer manuellement.",
    SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION: "Aller à l'importation",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TITLE:
        "Terminez votre installation sur Sablono",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TEXT:
        "Pour pouvoir commencer à utiliser ce projet, vous devez créer des livrables et au moins un modèle de Flux de Travail. Ensuite, vous pouvez l'affecter à vos livrables pour créer des activités.",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION: "Aller aux livrables",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION_2:
        "Aller aux modèle de Flux de Travail",

    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TITLE:
        "Créer un modèle de Flux de Travail",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TEXT:
        "Les modèles de Flux de Travail doivent être affectés aux livrables pour pouvoir cocher les activités.",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_ACTION: "Aller à l'éditeur de Modèles",
    INFO_STRUCTURE_PROGRESS:
        "{{allElements}} livrables, {{finishedElements}}% fini",
    ERROR_EMPTY_TIMELINE_TITLE: "Il n'y a toujours pas de mise a jour",
    INFO_SHOW_ALL_INSPECTIONS:
        "Afficher toutes les {{totalAmountOfInspections}} actualisations",
    INSPECTION_DETAIL_TITLE: "Rapporté par - {{author}}",
    INSPECTION_DETAIL_SUBTITLE: "Téléchargé {{time}}",
    INSPECTION_DETAIL_HEADER_PROGRESS: "{{number}} livrables actualisés",
    INSPECTION_DETAIL_HEADER_NEW_NOTES: "{{number}} notes créées",
    INSPECTION_DETAIL_HEADER_RESOLVED_NOTES: "{{number}} problèmes résolus",
    INSPECTION_DETAIL_PAGE_NO_FILTERED_DATA:
        "Aucune mise à jour ne correspond à vos critères de filtrage.",
    INSPECTION_DETAIL_FILTER_AUTHOR: "Reporter",
    INSPECTION_DETAIL_FILTER_TIMESTAMP: "Date",
    INSPECTION_DETAIL_NO_DATA_TITLE: "Pas encore de mises à jour disponibles",
    INSPECTION_DETAIL_NO_DATA_TEXT:
        "Aucune mise à jour pour votre projet n'a encore été téléchargée. Une fois la configuration du projet terminée, vous pouvez utiliser nos applications mobiles ou, par exemple, la page Livrables pour télécharger les progrès.",
    INSPECTION_DETAIL_NO_DATA_ACTION: "Aller aux livrables",
    SECTION_USER_PROFILE_TITLE: "Profil de l'utilisateur",
    ERROR_PASSWORD_CHANGE_LIFETIME_MESSAGE:
        "Le changement de mot de passe n'est actuellement pas autorisé: la durée de vie minimale du mot de passe est d'un jour. Attendez {{time}}",
    SECTION_YOUR_INFORMATION_TITLE: "Renseignements personnels",
    INFO_GENDER_FEMALE_TITLE: "Mme",
    INFO_GENDER_MALE_TITLE: "M.",
    ERROR_EMAIL_FORMAT_MESSAGE: "Doit être dans le bon format.",
    INPUT_SALUTATION_TITLE: "Salutation",
    INPUT_TITLE_TITLE: "Titre",
    INPUT_FIRSTNAME_TITLE: "Prénom",
    INPUT_LASTNAME_TITLE: "Nom",
    INPUT_COMPANY_TITLE: "Société",
    ACTION_SAVE: "Sauvegarder",
    ACTION_PUBLISH: "Publier",
    SECTION_CHANGE_PASSWORD_TITLE: "Modifier le mot de passe",
    CHANGE_PASSWORD_ENTER_OLD_TITLE: "Confirmer",
    CHANGE_PASSWORD_ENTER_OLD_MESSAGE:
        "Veuillez entrer l'ancien mot de passe pour le confirmer.",
    ACTION_CHANGE: "Changer",
    SECTION_EMAIL_ADRESSES_TITLE: "Adresses de email",
    INFO_PRIMARY_TITLE: "Primaire",
    INFO_PRIMARY_NOT_VERIFIED: "Non vérifié",
    INFO_PRIMARY_VERIFIED: "Vérifié",
    INPUT_NEW_EMAIL_TITLE: "Ajouter un nouvel email",
    INFO_SAVE_SUCCESS_TITLE: "Sauvegardé avec succès",
    INFO_PASSWORD_CHANGE_SUCCESS_TITLE: "Mot de passe modifié avec succès",
    ACTION_CHANGE_PICTURE: "Sélectionnez une image",
    ACTION_FILTER: "Filtre",
    ACTION_CLEAR_FILTERS: "Enlever les filtres",
    _ROLE_DESCRIPTION_ADMIN:
        "Un administrateur de projet peut accéder à toutes les fonctionnalités de ce projet.",
    _ROLE_DESCRIPTION_MANAGER:
        "Un chef de projet peut accéder à toutes les fonctionnalités de ce projet, à l'exception des paramètres du projet et de la modification des notes.",
    _ROLE_DESCRIPTION_INSPECTOR:
        "Un inspecteur de site peut accéder à l'application mobile Sablono, à la page Planification allégée et aux journaux quotidiens. Les utilisateurs ayant ce rôle sont autorisés à télécharger des progrès.",
    _ROLE_DESCRIPTION_CONTROLLER:
        "Un contrôleur de projet peut accéder à l'application mobile Sablono et à la plupart des pages, à l'exception de la page Configuration. Les utilisateurs de ce rôle sont autorisés à télécharger la progression.",
    _ROLE_DESCRIPTION_REVIEWER:
        "Un réviseur de projet a des droits en lecture seule pour ce projet.",
    ACTION_ADD: "Ajouter",
    SECTION_TEAMS_ADD_USER_DIALOG_TITLE:
        "Invitez un nouveau membre à <b>{{name}}</b>.",
    SECTION_TEAMS_ADD_USER_DIALOG_TEXT:
        "Les membres ajoutés à cette équipe ne pourront rapporter que des activités qui lui ont été assignées ou à 'chacun dans le projet'.",
    SECTION_TEAMS_ADD_USER_DIALOG_OK: "Invitez un membre",
    SECTION_TEAMS_ADD_USER_INPUT_INVALID:
        "Veuillez entrer une adresse e-mail valide",
    SECTION_TEAMS_EDIT_USER_DIALOG_TITLE: "Modifier <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_USER_DIALOG_OK: "Modifier un membre",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_ROLE:
        "Ou modifier le rôle de l'utilisateur dans ce projet.",
    SECTION_TEAMS_ADD_USERS_DIALOG_CHANGE_ROLE:
        "Veuillez sélectionner un rôle définissant les droits d'accès dans ce projet:",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_TEAM:
        "Ici, vous pouvez déplacer cet utilisateur vers une autre équipe.",
    SECTION_TEAMS_ADD_NEW_USER_BADGE: "Ajouter un nouveau membre",
    SECTION_TEAMS_ADD_NEW_COMMERCIAL_USER_BADGE:
        "Ajouter un utilisateur commercial",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_TITLE:
        "Adapter l'invitation à <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_TEAM:
        "Ici, vous pouvez déplacer cet utilisateur vers une autre équipe.",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_ROLE:
        "Veuillez sélectionner un rôle définissant les droits d'accès dans ce projet:",
    ACTION_INVITATION_RESEND: "Renvoyer l'invitation",
    ACTION_INVITATION_UPDATE_AND_RESEND: "Adapter l'invitation et la renvoyer",
    INPUT_SEARCH_USER_MESSAGE: "Rechercher un utilisateur...",
    INPUT_SELECT_ROLE_MESSAGE: "Sélectionnez un rôle...",
    SECTION_INSPECT_APP_SETTINGS_TITLE: "Paramètres de l'application mobile",
    SECTION_INSPECT_APP_SETTINGS_GEOLOCATION:
        "Capturer la géolocalisation des photos",
    SECTION_INSPECT_APP_GEOLOCATION_PERMISSIONS:
        "Les photos prises ou téléchargées sur Sablono incluront la géolocalisation de l'endroit où elles ont été prises, si l'information est disponible.",
    SECTION_INSPECT_APP_SETTINGS_COMBINED_STATUS_CHANGES_ENABLED:
        "Les utilisateurs mobiles sont en mesure de modifier le statut de l'ensemble des produits livrables et de toutes ses activités en même temps. Indice: ne devrait être activé que si vraiment nécessaire.",
    SECTION_INSPECT_APP_SETTINGS_STATUS_CHANGES_TITLE:
        "Changements d'état combinés",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_TITLE:
        "[Seule l'application Inspect] Limiter les activités visibles",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_ENABLED:
        "L'application Inspecter affichera uniquement les activités qui peuvent être modifiées par l'inspecteur de site actuel.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_TITLE:
        "[Seule l'application Inspect] Disposition de l'information",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_NAME:
        "Les noms de vos livrables sont présentés de façon plus visible dans l'application Inspect.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_CODE:
        "Les codes de vos  livrables sont présentés de façon plus visible dans l'application Inspecter.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_TITLE:
        "[Équipe responsable] Signer la liste de contrôle par mot de passe",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_DESCRIPTION:
        "En tant que mesure de sécurité supplémentaire, les utilisateurs de l'équipe d'exécution devront saisir leur mot de passe lorsqu'ils téléchargeront des listes de contrôle d'assurance qualité à l'aide de l'application mobile. L'application saisira toujours le nom de l'utilisateur et l'heure de téléchargement même si ce paramètre est désactivé.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_TITLE:
        "[Équipe d'inspection et de confirmation] Signer la liste de contrôle par mot de passe",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_DESCRIPTION:
        "En tant que mesure de sécurité supplémentaire, les utilisateurs de l'équipe de confirmation devront saisir leur mot de passe lorsqu'ils téléchargeront des listes de contrôle d'assurance qualité à l'aide de l'application mobile. L'application saisira toujours le nom de l'utilisateur et l'heure de téléchargement même si ce paramètre est désactivé.",

    SECTION_QUALITY_PRIVACY_TITLE: "Visibilité de la qualité",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_TITLE:
        "Visibilité limitée des notes",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_DESCRIPTION:
        "Les utilisateurs ne verront que les notes que leur équipe a rapportées, dont elle est responsable ou qui sont liées à des activités dont elle est responsable.",

    SECTION_EMAIL_NOTIFICATIONS_TITLE: "Notifications par courriel",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_TITLE:
        "Mise à jour quotidienne des activités",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_DESCRIPTION:
        "Les utilisateurs recevront un courriel quotidien chaque après-midi indiquant : Les activités disponibles, confirmées, refusées et les activités où les successeurs ont déjà commencé pour leur équipe.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_TITLE:
        "Mise à jour en temps réel des activités",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_DESCRIPTION:
        "Les utilisateurs recevront un e-mail en temps réel indiquant : Les activités disponibles, confirmées et rejetées pour leur équipe.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_TITLE:
        "Rappel de la mise à jour hebdomadaire",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_DESCRIPTION:
        "Les utilisateurs recevront un email hebdomadaire le lundi matin indiquant : Les activités pour lesquelles les successeurs ont déjà commencé pour leur équipe.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_TITLE:
        "Calendrier hebdomadaire prévisionnel",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_DESCRIPTION:
        "Les utilisateurs recevront un e-mail hebdomadaire le lundi matin montrant : Les activités prévues dans les 14 prochains jours et les activités en retard pour leur équipe.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_TITLE:
        "Mise à jour en temps réel des problèmes d'obstruction",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_DESCRIPTION:
        "Les utilisateurs recevront un courriel en temps réel indiquant les problèmes d'obstruction récemment créés dont leur équipe est responsable ou censée confirmer.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_TITLE:
        "Mise à jour en temps réel des problèmes de qualité",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_DESCRIPTION:
        "Les utilisateurs recevront un courriel en temps réel indiquant les problèmes de qualité récemment créés dont leur équipe est responsable ou qu'elle doit confirmer.",

    SECTION_NOTE_CONFIRMATION: "Paramètres de confirmation des problèmes",
    OBSTRUCTION_CONFIRMATION_TITLE: "Problèmes d'obstruction",
    OBSTRUCTION_CONFIRMATION_DESCRIPTION:
        "Si cette option est sélectionnée, les utilisateurs qui signalent un problème d'obstruction doivent lui attribuer une équipe de révision. Sinon, cette option est facultative",
    OBSTRUCTION_DEFAULT_TEAM:
        "Équipe de révision par défaut pour les problèmes d'obstruction",
    QUALITY_ISSUE_CONFIRMATION_TITLE: "Questions de qualité",
    QUALITY_ISSUE_CONFIRMATION_DESCRIPTION:
        "Si cette option est sélectionnée, les utilisateurs signalant un problème de qualité doivent lui attribuer une équipe de révision. Sinon, cela est facultatif",
    QUALITY_ISSUE_DEFAULT_TEAM:
        "Équipe de révision par défaut pour les problèmes de qualité",

    INFO_USER_ADD_SUCCESS_TITLE: "{{users}} ajouté avec succès",
    INFO_USER_MOVED_SUCCESS_TITLE:
        "Vous avez déménagé l'utilisateur avec succès",
    INFO_USER_REMOVE_SUCCESS_TITLE: "Utilisateur enlevé du projet",
    INFO_NO_MEMBER_1_TITLE: "Il n'y a pas de",
    INFO_NO_MEMBER_2_TITLE: "dans votre projet pour le moment.",
    CONFIRM_DELETE_YOURSELF_MESSAGE:
        "Êtes-vous sûr de vouloir effacer votre profil? Ceci ne peut pas être annulé.",
    ACTION_UNDO: "Annuler",
    INFO_USER_NOT_FOUND: "Utilisateur {{user}} introuvable",
    INFO_LOADING_ADD_MESSAGE: "Ajouter un utilisateur",
    INFO_LOADING_ADD_MESSAGE_PLURAL: "Ajout d'utilisateurs",
    INFO_LOADING_REMOVE_MESSAGE: "Suppression d'un utilisateur",
    INFO_LOADING_REMOVE_MESSAGE_PLURAL: "Suppression d'utilisateurs",
    INFO_LOADING_MOVE_MESSAGE: "Déménager l'utilisateur",
    INFO_LOADING_MOVE_MESSAGE_PLURAL: "Déplacement des utilisateurs",
    INFO_LOADING_RESTORE_MESSAGE: "Restauration de l'utilisateur",
    INFO_LOADING_RESTORE_MESSAGE_PLURAL: "Restauration des utilisateurs",
    _SUN: "Dim",
    _MON: "Lun",
    _TUE: "Mar",
    _WED: "Mer",
    _THU: "Jeu",
    _FRI: "Ven",
    _SAT: "Sam",
    SECTION_PROJECT_SETTINGS_PAGE_TITLE: "Paramètres du projet",
    SECTION_PROJECT_CALENDAR_TITLE: "Calendrier du projet",
    SECTION_SCHEDULING_MODE_TITLE: "Mode de planning",
    SECTION_PROJECT_CURRENCY_TITLE: "Monnaie du projet",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_HEADING:
        "planification individuelle",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_OPTION:
        "Ce mode calcule votre planning de référence tout en ignorant les dépendances entre les différents livrables.",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_HEADING: "Planification connecté",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_OPTION:
        "Ce mode calcule votre planning de référence, y compris les dépendances entre les différents livrables. Veuillez noter que les résultats pour vos livrables peuvent varier des dates de début et de fin définies par l'utilisateur.",
    SECTION_SCHEDULING_MODE_SELECTION_INFO_TOOLTIP:
        "Ne vous inquiétez pas! Échanger le planning individuel pour le planning connecté n'effacera jamais les dates. Si vous n'êtes pas satisfait des résultats de l'autre mode, vous pouvez l'échanger à tout moment.",
    ERROR_NO_WORKDAYS_SELECTED_MESSAGE:
        "Vous devez sélectionner au moins un jour ouvrable",
    ERROR_NO_WORKHOURS_SELECTED_MESSAGE:
        "Veuillez sélectionner vos heures de travail",
    ERROR_NO_TIMEZONE_SELECTED_MESSAGE: "Veuillez choisir un fuseau horaire",
    ERROR_INVALID_TIMEZONE_SELECTED_MESSAGE:
        "Veuillez sélectionner un fuseau horaire valide",
    DELETE_PROJECT: "Supprimer le projet",
    SECTION_PROJECT_INFOS_TITLE: "Informations sur le projet",
    SECTION_SELECT_WORKING_DAYS_TITLE: "Jours ouvrés",
    SECTION_SELECT_WORKING_HOURS_TITLE: "Sélectionnez vos horaires de travail",
    INFO_CALENDAR_EDIT_SUCCESS_TITLE: "Calendrier mis à jour avec succès",
    INFO_CALENDAR_EDIT_LOADING_TITLE: "Calendrier en cours de mise à jour...",
    CONFIRM_DELETE_PROJECT_MESSAGE: "Voulez-vous vraiment supprimer ce projet?",
    INFO_PROJECT_EDIT_SUCCESS_TITLE: "Changement réussi",
    INFO_PROJECT_EDIT_LOADING_TITLE: "Le projet est en cours de mise à jour...",
    INPUT_START_DESCRIPTION: "Début",
    INPUT_END_DESCRIPTION: "Fin",
    SECTION_SELECT_FIRST_PERIOD_TITLE: "Première période",
    SECTION_SELECT_SECOND_PERIOD_TITLE: "Deuxième période",
    SECTION_EXCEPTION_DAYS_TITLE: "Jours non ouvrables",
    SECTION_EXCEPTION_DAYS_INFO:
        "Veuillez indiquer tous les jours où votre équipe de projet ne travaillera pas, par exemple les jours fériés.",
    INPUT_DAY_LABEL_DESCRIPTION: "Occasion",
    ERROR_DUPLICATED_DATES: "Vous avez sélectionné deux fois le même jour.",
    ACTION_ADD_MORE_EXCEPTION_DAY: "Ajouter un jour",
    INFO_PROJECT_REMOVE_SUCCESS_TITLE: "Projet supprimé avec succès",
    ACTION_DELETE_PROJECT_TITLE: "Supprimer un projet",
    ACTION_DELETE_PROJECT_CONFIRM_TITLE: "Effacer enfin?",
    SECTION_EDIT_TITLE: "Modifier",
    INFO_COMPONENT_DATE_CHANGE_SUCCESS_TITLE: "{{name}} modifié avec succès",
    ACTION_CLONE_TEMPLATE: "Copier le modèle existant",
    ACTION_CREATE_TEMPLATE: "Créer un nouveau modèle",
    ACTION_UNGROUPED: "Non groupé",
    ACTION_CREATOR: "Créateur",
    ACTION_CATEGORY: "Catégorie",
    ACTION_CODE: "Code",
    ACTION_REVISION: "Version du modèle",
    ACTION_ACTIVITIES: "Nombre d'activités",
    TEMPLATE_GROUP_INPUT_HOURS_OF_WORK_TITLE: "Heures de travail",
    TEMPLATE_GROUP_TOOLTIP_DURATION_TITLE: "Durée: ",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_BEFORE:
        "La durée représente le chemin critique de votre Flux de Travail défini. Ici,",
    TEMPLATE_GROUP_DURATION_TOOLTIP_LINK_TEXT: "heures et jours ourvré",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_AFTER:
        "ainsi que les heures et les jours sont inclus.",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TITLE: "Heures de travail: ",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TEXT:
        "Cette valeur est calculée en ajoutant toutes les durées d'activité contenues dans ce groupe.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_TEXT:
        "Les jours et heures de travail sont planifiés en fonction de votre échéancié de projet de référence. Les jours et les heures utilisent un calendrier de 24 heures.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_DISABLED_TEXT:
        "Définissez vos durées planifiées dans le cadre de vos séquences de travail standard sur Sablono et tirez le meilleur parti de ses fonctionnalités d'ordonnancement Baseline et Look Ahead ! Contactez Sablono dès aujourd'hui pour accéder à cette fonctionnalité.",
    CONFIRM_TEAM_DISABLED_TOOLTIP_TEXT:
        "Affectez des équipes de confirmation à vos activités pour pouvoir approuver ou même rejeter le travail et les listes de contrôle d'assurance qualité directement depuis le site, avec une documentation complète dans le cadre de la piste d'audit de votre projet ! Contactez Sablono dès aujourd'hui pour accéder à cette fonctionnalité.",
    TEMPLATE_ACTIVITY_EMPTY_CHECKLIST_TOOLTIP_TEXT:
        "Les listes de contrôle AQ qui ne comportent encore aucun élément apparaîtront désactivées dans la liste. Veuillez ajouter des éléments à leur modèle de liste de contrôle pour pouvoir les joindre ici.",
    TEMPLATE_ACTIVITY_CHECKLIST_DISABLED_TOOLTIP_TEXT:
        "Assignez des listes de contrôle à vos activités et assurez-vous que l'information sur la qualité est capturée automatiquement tout en rapportant l'avancement des travaux depuis le site ! Contactez Sablono dès aujourd'hui pour accéder à cette fonctionnalité.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_LINK_TEXT:
        "Jours ouvré: {{days}}<br>Heures de travail: {{times}}",
    SECTION_CREATE_TEMPLATE_TITLE: "Créer un nouveau modèle",
    INPUT_TEMPLATE_NAME_TITLE: "Nom",
    INPUT_TEMPLATE_CODE_TITLE: "Code",
    INPUT_UNIT_TITLE: "Unité",
    INPUT_ASSIGNED_TEAM_TITLE: "Équipe responsable",
    INPUT_REVIEW_TEAMS_TITLE: "Équipe de inspection",
    INPUT_CONFIRM_TEAM_TITLE: "Équipe de confirmation",
    _NOTES_CONFIRMATION_TEAM: "Équipe de révision",
    INPUT_OPTION_NO_CONFIRMATION_REQUIRED:
        "Aucune confirmation séparée n'est nécessaire.",
    INPUT_ASSIGNED_CHECKLIST_TITLE: "Liste de contrôle annexé",
    INPUT_ASSIGNED_CHECKLIST_NONE: "Aucune liste de contrôle annexé",
    INPUT_SELECT_ASSIGNED_TEAM_PLACEHOLDER: "Choisissez une équipe",
    INPUT_SELECT_MIXED_TEAM_ASSIGNMENT_OPTION: "Plusieurs équipes attribuées",
    INPUT_SELECT_ADD_NEW_TEAM_OPTION: "Créer une nouvelle équipe",
    INPUT_TEMPLATE_DESCRIPTION_TITLE: "Description",
    INPUT_TEMPLATE_CATEGORY_TITLE: "Catégorie",
    INPUT_TEMPLATE_ACTIVITY_NAME_TITLE: "Nom de l'activité",
    INPUT_TEMPLATE_STATE_NAME_TITLE: "Nom de l'État",
    INFO_TEMPLATE_ADD_SUCCESS_TITLE: "Le modèle a été créé avec succès.",
    INPUT_SELECT_PREDECESSOR_TITLE:
        "Sélectionner un prédécesseur supplémentaire",
    INPUT_SELECT_SUCCESSOR_TITLE: "Sélectionner un successeur supplémentaire",
    INFO_TEMPLATE_EDIT_SUCCESS_TITLE: "Le modèle a été édité avec succès",
    INFO_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} modèles effacé. {{errors}} erreurs",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATES_MESSAGE: "Recherche de modèles...",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATE_CONTENT_MESSAGE:
        "Rechercher dans le modèle...",
    ACTION_GROUP_BY: "Grouper par",
    ACTION_GROUP_BY_TYPE: "Regrouper par type",
    ACTION_GROUP_BY_CREATOR: "Groupe par créateur",
    ACTION_GROUP_BY_CREATION_DATE: "Groupe par date",
    ACTION_KEY: "Code",
    ACTION_NAME: "Nom",
    ACTION_DESCRIPTION: "Description",
    ACTION_TEXT: "Texte",
    ACTION_TYPE: "Type",
    ACTION_SAVE_TEMPLATE: "Sauvegarder le modèle de Flux de Travail",
    ACTION_DATE: "Date",
    INFO_NO_TEMPLATES_YET_TITLE:
        "Aucun modèle de Flux de Travail n'a encore été créé pour ce projet.",
    INFO_NO_TEMPLATES_YET_TEXT:
        "Les modèles de Flux de Travail peuvent être utilisés pour saisir des informations très détaillées sur les séquences de construction. Ils contiennent des activités qui peuvent être structurées en groupes et liées par des dépendances. <br /><img src='images/attach-template_en_slow.gif'/><br /> Après avoir créé un modèle, il suffit de l'affecter aux livrables de votre projet et de démarrer les activités.",
    INFO_NO_TEMPLATES_YET_BUTTON_LEFT: "Créer un nouveau",
    INFO_NO_TEMPLATES_SEARCH_1_MESSAGE: "Votre recherche pour",
    INFO_NO_TEMPLATES_SEARCH_2_MESSAGE:
        "ne correspondaient à aucun modèle existant.",
    INFO_NO_ACTIVITIES_IN_GROUP:
        "Ce groupe ne contient aucune activité de suivi des progrès.",
    INFO_NO_WORKFLOW_ASSIGNED_YET_TITLE:
        "Veuillez attribuer un modèle de Flux de Travail",
    INFO_NO_WORKFLOW_ASSIGNED_YET_MESSAGE:
        "Pour être en mesure de suivre le statut des activités, vous devez affecter un modèle de Flux de Travail à ce livrable suivant.",
    INFO_EMPTY_WORKFLOW_ASSIGNED_TITLE:
        "Schéma de Flux de Travail sans activités traçables",
    INFO_EMPTY_WORKFLOW_ASSIGNED_MESSAGE:
        "Le schéma de Flux de Travail qui vous avez affecté ne contient pas encore d'activités traçables. Ajoutez des activités en modifiant votre modèle.",
    SECTION_NO_GROUP_ELEMENT_TITLE: "Non ",
    ACTION_ADD_TEMPLATE_DESCRIPTION: "Ajouter un modèle",
    SECTION_ALL_THE_TEMPLATES_1_TITLE: "Tous",
    SECTION_ALL_THE_TEMPLATES_2_TITLE: "modèles",
    CONFIRM_DELETE_TEMPLATES_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer {{value}} modèles?",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_TITLE: "Non autorisé",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_MESSAGE:
        "Les modèles de Flux de Travail affectés aux livrables ne peuvent pas être supprimés. Le(s) modèle(s) de Flux de Travail que vous essayez de supprimer sont toujours utilisés par {{value}} livrable{{(value == 1)? '': 's'}}. \n Veuillez affecter un nouveau modèle de Flux de Travail à ces livrables pour continuer.",
    SECTION_GROUPS: "Groupes",
    SECTION_ACTIVITIES: "Activités",
    INFO_ACTIVITY_TITLE: "Activité",
    INFO_TEMPLATE_NO_CHILDREN:
        "Ce modèle n'a pas encore de groupes ou d'activités.",
    ACTION_TEMPLATE_ADD_CONTENT: "Ouvrir dans l'éditeur",
    ACTION_TEMPLATE_DELETE_NOTE: "Supprimer la note",
    CONFIRM_DELETE_NOTE_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer cette note ?",
    ACTION_ADD_GROUP: "Ajouter un nouveau groupe à {{name}}.",
    ACTION_ADD_ACTIVITY: "Ajouter une nouvelle activité à {{name}}.",
    ACTION_DELETE_GROUP: "Supprimer un groupe",
    ACTION_DELETE_ACTIVITY: "Supprimer l'activité",
    ACTION_TRANSFORM_ACTIVITY: "Transformer l'activité en groupe",
    ACTION_DISCARD_CHANGES: "Rejeter les modifications",
    SECTION_TEMPLATE_EDITOR: "Éditeur de modèles",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TITLE: "Remplacer les équipes assignées?",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TEXT:
        "Si vous affectez une équipe responsable à ce groupe, vous écrasez les affectations déjà effectuées pour les activités contenues. Vous êtes sûr de vouloir continuer?",
    CONFIRM_DELETE_GROUP_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer le groupe {{name}}? Tous les contenus du groupe seront également supprimés.",
    CONFIRM_DELETE_ACTIVITY_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer l'activité {{name}}? Veuillez garder à l'esprit qu'après avoir réaffecté le modèle de Flux de Travail, l'avancement, les notes et les images liées à cette activité sur tous vos livrables ne seront plus disponibles. Cette action ne peut pas être annulée.",
    INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE: "{{name}} supprimé avec succès.",
    CONFIRM_DISCARD_CHANGES_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer toutes vos modifications et télécharger à nouveau le modèle à partir du serveur?",
    INFO_TRANSFORM_ACTIVITY:
        "Les groupes et les activités ne peuvent pas être affectés à des activités. Pour ce faire, vous devez transformer l'activité en un groupe.",
    DIALOG_TRANSFORM_ACTIVITY_OPTION:
        "Veuillez choisir l'une des approches suivantes",
    SELECTION_TRANSFORM_ACTIVITY_KEEP:
        "Créez un nouveau groupe et ajoutez cette activité à ce groupe.",
    SELECTION_TRANSFORM_ACTIVITY_DISCARD:
        "Créez un nouveau groupe et supprimez cette activité.",
    INFO_CREATE_NEW_GROUP: "Créer un nouveau groupe...",
    INFO_TEMPLATE_SAVED_SUCCESSFULL: "Modèle enregistré avec succès",
    INFO_CANNOT_DRAG_INTO_ACTIVTY_MESSAGE:
        "Vous ne pouvez pas déplacer une activité ou un groupe dans une autre activité.",
    INFO_CANNOT_DRAG_INTO_CHILD_MESSAGE:
        "Vous ne pouvez pas déplacer ce groupe dans l'un de ses sous-groupes.",
    SECTION_PREDECESSOR_TITLE: "Dépend de",
    SECTION_SUCCESSOR_TITLE: "Suivi de",
    SECTION_CURRENT_TITLE: "Activité actuelle",
    ACTION_EXPAND_ALL: "Développer tout",
    ACTION_COLLAPSE_ALL: "Réduire tout",
    INFO_CIRCLE_PREDECESSOR_MESSAGE:
        "Vous avez déjà défini cette activité comme successeur dans le déroulement des opérations. L'ajouter en tant que prédécesseur conduirait à un cycle de Flux de Travail.",
    INFO_CIRCLE_SUCCESSOR_MESSAGE:
        "Vous avez déjà défini cette activité comme prédécesseur dans le déroulement des opérations. L'ajouter en tant que successeur conduirait à un cycle de Flux de Travail.",
    INFO_SAVING_MESSAGE: "Enregistrer les modifications...",
    INFO_DISCARDING_MESSAGE: "Jeter...",
    LABEL_EDIT_ACTIVITY: "Traiter l'activité",
    INFO_LAST_CHANGES_TIME:
        "Vos modifications ont été sauvegardées sur cet appareil",
    INFO_LAST_CHANGES_TIME_PUBLISH: "Publier maintenant",
    SELECT_EXIT_EDITOR_TITLE: "Quitter l'éditeur de modèle",
    SELECT_EXIT_EDITOR_MESSAGE:
        "Vos modifications n'ont pas encore été publiées. Comment voulez-vous procéder?",
    SELECT_EXIT_EDITOR_DISMISS: "Restez sur cette page",
    SECTION_CHANGE_PARENT_DIALOG_TITLE: "Sélectionner un autre groupe",
    ACTION_MOVE_TO_ANOTHER_PARENT: "Passer à un autre groupe",
    ACTION_PUBLISH_AND_UPDATE_DELIVERABLES: "Sauvegarder et attribuer",
    INFO_UPDATING_DELIVERABLES_MESSAGE: "Mise à jour des livrables...",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL: "Mise à jour réussie des livrables",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL_N:
        "Mise à jour réussie des livrables",
    INFO_PUBLISHING_MESSAGE: "Modifications de publication...",
    INFO_TEMPLATE_PUBLISHED_SUCCESSFUL: "Modèle publié avec succès",
    TEMPLATE_EDITOR_NO_DATA_TITLE:
        "Non {{(type==='GROUP')?'groupe':'activité'}} ajouté encore",
    TEMPLATE_EDITOR_NO_DATA_GROUP_TEXT:
        "Un groupe est constitué de plusieurs activités et sert à structurer votre modèle. Ajoutez un groupe existant ou créez-en un nouveau en utilisant le champ ci-dessus.",
    TEMPLATE_EDITOR_NO_DATA_ACTIVITY_TEXT:
        "Les activités se composent d'une activité et d'un état cible (p. ex., livrer - livré). Ajoutez une activité existante ou créez-en une nouvelle en utilisant le champ ci-dessus.",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TITLE:
        "Aucun prédécesseur n'a encore été sélectionné",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TEXT:
        "Les prédécesseurs doivent être terminés avant que l'activité en cours puisse commencer. Sélectionnez une activité de ce schéma de Flux de Travail à l'aide de la zone ci-dessus.",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TITLE:
        "Aucun successeur n'a encore été choisi",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TEXT:
        "Les successeurs ne peuvent être lancés qu'une fois l'activité en cours terminée. Sélectionnez une activité de ce schéma de Flux de Travail à l'aide de la zone ci-dessus.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_PREDECESSOR:
        "Chaque activité créée dans ce schéma type se traduirait par un cycle de flux de travail si elle était réutilisée. Veuillez créer plus d'activités avant de définir un autre prédécesseur.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_SUCCESSOR:
        "Chaque activité créée dans ce schéma type se traduirait par un cycle de Flux de Travail si elle était réutilisée. Veuillez créer plus d'activités avant de définir un autre successeur.",
    EDIT_TEMPLATE_ACTIVITY_DESCRIPTION_PLACEHOLDER:
        "Saisissez les notes, descriptions ou traductions supplémentaires que vous ou vos partenaires souhaitez voir.",
    INFO_PROGRESS_CHANGE_DISABLED_TOOLTIP:
        "Vous n'êtes pas autorisé à modifier le statut de cette activité car vous n'en êtes pas responsable.",
    ACTION_EXPORT_GRAPHML_FILE:
        "Exporter le Flux de Travail sous forme de .graphml",
    _LABOUR: "Travail",
    _LABOUR_UNIT: "Travailleurs",
    LABOUR_IN_UNIT: "{{amount}} Travailleurs",
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TITLE: "Créez votre premier modèle de note",
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TEXT:
        "Les modèles de notes permettent de gagner du temps sur le site en vous permettant de créer des textes standard qui peuvent ensuite être utilisés pour créer des notes plus efficacement.",
    INFO_NO_DATA_DUE_TO_FILTER_CONDITIONS:
        "Votre filtre et vos critères de recherche actuels ne correspondent à aucun modèle de note.",
    ACTION_NOTE_TEMPLATE_NO_DATA_BUTTON: "Créez votre premier modèle",
    NOTE_TEMPLATE_TITLE: "Type de note",
    CONFIG_CARD_TITLE: "Filtres",
    INFO_PLACEHOLDER_SEARCH_BAR_NOTE_TEMPLATES_MESSAGE:
        "Recherche de modèles de notes...",
    INFO_NOTE_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} Modèles de notes effacé. {{errors}}  erreurs.",
    CONFIRM_DELETE_NOTE_TEMPLATE_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer ces {{value}} modèles?",
    INFO_NOTE_TEMPLATE_LOADING_REMOVE_MESSAGE:
        "Suppression des modèles de notes",
    SECTION_CREATE_NOTE_TEMPLATE_TITLE: "Créer un nouveau modèle de note",
    INPUT_CODE_TITLE: "Code",
    INPUT_NOTE_TEMPLATE_DESCRIPTION_TITLE: "Texte du modèle de note",
    INPUT_SELECT_TYPE: "Sélectionner le type de note",
    INFO_PLACEHOLDER_SEARCH_BAR_PROJECT_SELECTION_MESSAGE:
        "Recherche de projets...",
    INFO_ELEMENTS_SELECTED: "sélectionnés",
    DIALOG_CHANGE_DATES_TITLE: "Modifier la date de livraison",
    DIALOG_DATE_CHANGE_VALUE_NOT_DATE: "La valeur saisie n'est pas une date!",
    DIALOG_CHANGE_DATE_WARNING_MESSAGE:
        "Les produits livrables choisis comprennent différentes dates de début et de fin. Les dates de début au plus tôt et de fin au plus tard sont indiquées ici. Gardez à l'esprit que vous déplacerez tous les livrables sélectionnés si vous choisissez d'enregistrer.",
    DIALOG_CHANGE_DATE_ALL_SELECTED_WARNING_MESSAGE:
        "Attention! Attention! Tous les produits livrables sont actuellement sélectionnés. Si vous décidez de modifier les dates maintenant, cela affectera tous les produits livrables et écrasera les dates de début et de fin existantes.",

    INFO_MAINTENANCE_CARD_TITLE_PLANNED: "Maintenance planifiée",
    INFO_MAINTENANCE_CARD_SUB_TITLE_PLANNED:
        "Le système n'est actuellement pas disponible en raison de mises à jour et de travaux de maintenance programmés.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_PLANNED:
        "Les fenêtres de maintenance planifiée sont du vendredi 22h (CET) au samedi 2h (CET), du samedi 22h (CET) au dimanche 2h (CET) et du dimanche 22h (CET) au lundi 2h (CET).",
    INFO_MAINTENANCE_CARD_TEXT_PLANNED:
        "Veuillez contacter notre service d'assistance à l'adresse <a href='mailto:support@sablono.com'>support@sablono.com</a> si vous avez des questions ou si vous avez besoin d'aide.",

    INFO_MAINTENANCE_CARD_TEXT_UNKNOWN:
        "Si le problème persiste, veuillez nous contacter sur <a href='mailto:support@sablono.com'>support@sablono.com</a>.",
    INFO_MAINTENANCE_CARD_TITLE_UNKNOWN: "Timeout réseau",
    INFO_MAINTENANCE_CARD_SUB_TITLE_UNKNOWN:
        "Il semble qu'il n'y ait pas de connexion ou une connexion lente au serveur Sablono en ce moment...",
    INFO_NOTE_TYPE_EDIT_TEMPORARILY_UNAVAILABLE:
        "La modification du type de notes ne fonctionne plus actuellement. Nous nous efforçons de rétablir cette fonctionnalité dès que possible.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_UNKNOWN:
        "Veuillez vérifier si votre connexion internet est stable et essayer de rafraîchir la page.",
    INFO_MAINTENANCE_CARD_BUTTON_UNKNOWN: "Centre de support",
    SIGNUP_TITLE_FREEMIUM: "Créez votre compte Sablono Track gratuit",
    SIGNUP_TITLE_FULL: "Créez votre compte Sablono",
    SIGNUP_SUCCESS_TITLE:
        "Veuillez vérifier votre e-mail en utilisant votre boîte de réception dans les 24 heures…",
    SIGNUP_SUCCESS_HUBSPOT_TITLE:
        "Voulez-vous tirer le meilleur parti de votre expérience Sablono ? Rencontrez l'un de nos experts produits!",
    SIGNUP_SUCCESS_HUBSPOT_TEXT:
        "Nous sommes plus qu'heureux de vous aider à atteindre vos objectifs avec notre plateforme. Notre équipe dévouée est là pour vous aider.",
    SIGNUP_SUCCESS_TEXT_WAIT_BUTTON: "cliquez ici.",
    SIGNUP_SUCCESS_TEXT_MAIL_SENT:
        "Le courriel a été envoyé une deuxième fois. Si vous ne recevez pas d'e-mail dans les prochaines minutes, veuillez contacter notre support technique par <a href='mailto:support@sablono.com'>support@sablono.com</a> ou <a href='tel:0049030609807440'>+49 030 609807440</a>. Nous nous ferons un plaisir de vous aider!",
    SIGNUP_GENERIC_ERROR_TITLE: "Il semble y avoir un problème",
    SIGNUP_GENERIC_ERROR_TEXT:
        "Nous avons actuellement un problème inattendu avec le processus d'inscription. Veuillez contacter notre support par <a href='mailto:support@sablono.com'>support@sablono.com</a> ou <a href='tel:0049030609807440'>+49 030 609807440</a>. Nous nous ferons un plaisir de vous aider!",
    SIGNUP_FIRST_ERROR_TITLE: "Ups...",
    SIGNUP_FIRST_ERROR_TEXT:
        "Nous sommes désolés, mais quelque chose s'est mal passé lors du transfert de vos données vers notre système. Veuillez réessayer.",
    SIGNUP_FIRST_ERROR_BUTTON: "Retour à l'inscription",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_TITLE: "Déjà pris",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_TEXT:
        "Votre adresse e-mail a déjà été utilisée pour créer un utilisateur.",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_BUTTON: "Utiliser un autre email",
    SIGNUP_ERROR_USER_ALREADY_USED_BUT_NOT_VERIFIED_TITLE: "Déjà pris",
    SIGNUP_ERROR_USER_ALREADY_USED_BUT_NOT_VERIFIED_TEXT:
        "Votre adresse email a déjà été utilisée pour créer un utilisateur qui n'a pas encore été vérifié.",
    SIGNUP_ERROR_USER_ALREADY_USED_BUT_NOT_VERIFIED_BUTTON:
        "Utiliser un autre email",
    SIGNUP_INFO_VERIFY_TITLE: "Choisissez un mot de passe",
    SIGNUP_INFO_VERIFY_TEXT:
        "Veuillez choisir un mot de passe sécurisé avant de commencer à travailler avec Sablono.",
    SIGNUP_SUCCESS_VERIFY_TITLE: "Inscription complétée avec succès",
    SIGNUP_SUCCESS_VERIFY_TEXT:
        "Félicitations ! N'oubliez pas de consulter notre guide Sablono étape par étape qui se trouve sur <a target='_blank' href='http://support.sablono.com/english-tutorials'>support.sablono.com</a> si vous avez des questions concernant la plateforme.",
    SIGNUP_SUCCESS_VERIFY_BUTTON: "Aller au centre de support",
    ACTION_SIGNUP_VERIFY_TEXT: "Activer",
    SIGNUP_ERROR_VERIFY_FAILED_TITLE: "Oups...",
    SIGNUP_ERROR_VERIFY_FAILED_TEXT:
        "D'une façon ou d'une autre, l'activation a échoué. Veuillez essayer d'utiliser à nouveau le lien fourni.",
    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TITLE: "Oups...",
    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TEXT:
        "Le lien que vous utilisez semble être invalide. Cela peut avoir plusieurs raisons.",
    SIGNUP_EULA:
        "J'ai lu et j'accepte par la présente le <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>Sablono End-User-License-Agreement</a>.*",
    SIGNUP_DATA_PRIVACY:
        "J'accepte que Sablono transmette également les données que j'ai saisies à des pays dits tiers (Art. 49 para. 1 lit. a) RGPD). Je suis conscient que dans certains pays tiers (par exemple les États-Unis), l'accès des autorités de contrôle et/ou de surveillance ne peut être exclu. Il est possible que je ne puisse pas faire valoir les droits de la personne concernée ni saisir les tribunaux. La transmission est effectuée à des fins d'exécution du contrat ainsi que pour des contacts promotionnels de la part de Sablono. Mon consentement est librement révocable.*<br><br>Nous vous renvoyons à la <a href='https://www.sablono.com/privacy' target='_blank'>Data and Privacy Policy</a>. Veuillez y consulter en particulier notre remarque sur la transmission dans des pays tiers.",
    SIGNUP_MARKETING_CONSENT:
        "Je souhaite recevoir à l'avenir des informations sur les produits Sablono par e-mail et par téléphone. Mon consentement est librement révocable.*",
    SECTION_VISUALIZATION_PAGE_TITLE: "Visualisation des progrès",
    DIALOG_NO_MATCH_SVG_TITLE: "Aucun progrès correspondant n'a été détecté",
    DIALOG_NO_MATCH_SVG_CONTENT:
        "Nous n'avons pas pu détecter de progrès correspondant entre votre dossier et le projet Sablono. Veuillez vérifier si les codes de livrables de votre dossier correspondent aux codes de la plateforme et si des modèles de Flux de Travail leur ont été attribués.",
    INFO_PLACEHOLDER_SEARCH_BAR_VISUALIZATION: "Recherche de visualisation...",
    INFO_UPLOAD_VISUALIZATION_TEXT:
        "Téléchargez une image de votre projet contenant des informations sur vos livrables (fichier SVG)",
    INFO_UPLOAD_NEW_VISUALIZATION_MESSAGE:
        "Téléchargez un dessin contenant les codes utilisés sur Sablono pour tous les livrables affichés (fichier SVG uniquement).",
    INFO_GENERATE_NEW_VISUALIZATION_MESSAGE:
        "Créez une nouvelle visualisation schématique de votre projet.",
    ACTION_UPLOAD_NEW_VISUALIZATION: "Télécharger le dessin SVG",
    ACTION_GENERATE_NEW_VISUALIZATION: "Créer une visualisation",
    SECTION_VISUALIZATION_SHOW_PROGRESS:
        "Afficher les résultats prévus par rapport aux résultats réels",
    SECTION_VISUALIZATION_SHOW_STAGE: "Afficher l'état",
    SECTION_VISUALIZATION_SHOW_CURRENT_ACTIVITY: "Afficher les équipes",
    SECTION_VISUALIZATION_SHOW_DONE_ACTIVITY: "Parcourir l'état",
    SECTION_VISUALIZATION_PRINT_TOOLTIP:
        "Impression de la visualisation ou sauvegarde au format PDF à l'aide d'une imprimante PDF",
    SECTION_VISUALIZATION_PROGRESS: "Comparaison de base",
    SECTION_VISUALIZATION_STAGE: "Statut",
    SECTION_VISUALIZATION_CURRENT_ACTIVITY: "Équipes",
    SECTION_VISUALIZATION_PRINT_TITLE: " visualisation de ",
    "0_INFO_LEGEND_NO_STAGE": "Aucune activité terminée",
    "0_ACTIVITIES_PLANNED_LEGEND": "Aucune activité prévue",
    ERROR_SVG_DOES_NOT_EXIST:
        "La visualisation demandée n'a pas été trouvée sur notre serveur. N'hésitez pas à en choisir un dans la liste.",
    ERROR_FILE_IS_NOT_SVG:
        "Le fichier que vous avez téléchargé ne semble pas être au format SVG. Veuillez choisir un autre fichier.",
    CONFIRM_SVG_DELETION_TITLE: "Confirmer l'effacement de la visualisation",
    CONFIRM_SVG_DELETION_MESSAGE:
        "Êtes-vous sûr de vouloir supprimer cette visualisation?",
    INFO_SVG_DELETION_SUCCESSFUL: "Visualisation supprimée avec succès",
    INFO_VISUALIZATION_CONFIG_NO_TEMPLATE_EMPTY_STATE_MESSAGE:
        "Il semble que vous n'ayez pas créé de modèle de Flux de Travail dans ce projet, mais.<br> Les visualisations ne peuvent être créées qu'une fois le modèle de Flux de Travail configuré.",
    SELECT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_MESSAGE:
        "Quels types de livrables doivent être montrés dans la visualisation?",
    SELECT_VISUALIZATION_CONFIG_STRUCTURE_NODES_MESSAGE:
        "Quelles parties de la structure de votre projet doivent être affichées dans la visualisation?",
    SELECT_VISUALIZATION_CONFIG_SVG_TITLE_MESSAGE:
        "Veuillez définir les titres affichés dans votre visualisation.",
    SELECT_VISUALIZATION_CONFIG_LABEL_ROW_PROPERTY_MESSAGE:
        "Quelles informations doivent être utilisées pour étiqueter les niveaux affichés dans la visualisation?",
    SELECT_VISUALIZATION_CONFIG_LABEL_CELL_PROPERTY_MESSAGE:
        "Quelles informations doivent être utilisées pour étiqueter les livrables affichés dans la visualisation?",
    SELECT_VISUALIZATION_CONFIG_LABEL_PROPERTY_SUB_MESSAGE:
        "Quels caractères de ces informations doivent être affichés?",
    SELECT_VISUALIZATION_CONFIG_SVG_NAME_MESSAGE:
        "Veuillez nommer la visualisation pour pouvoir la différencier des autres.",
    INPUT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_DESCRIPTION:
        "Sélectionnez un modèle de Flux de Travail...",
    INPUT_VISUALIZATION_CONFIG_STRUCTURE_NODES_DESCRIPTION:
        "Sélectionnez tous les niveaux de structure de projet pertinents...",
    INPUT_VISUALIZATION_CONFIG_HEADER_RIGHT_DESCRIPTION: "Titre:",
    INPUT_VISUALIZATION_CONFIG_HEADER_LEFT_DESCRIPTION: "Structure du projet:",
    INPUT_VISUALIZATION_CONFIG_LABEL_PROPERTY_DESCRIPTION:
        "Informations affichées:",
    INPUT_VISUALIZATION_CONFIG_FIRST_CHAR_DESCRIPTION:
        "Position du premier caractère",
    INPUT_VISUALIZATION_CONFIG_SECOND_CHAR_DESCRIPTION:
        "Dernière position de caractère",
    INFO_VISUALIZATION_CONFIG_LABEL_PROPERTY_LIST: "Exemples:",
    INPUT_VISUALIZATION_CONFIG_SVG_NAME_DESCRIPTION: "Définir un nom...",
    SECTION_VISU_CONFIG_DELIVERABLE_TYPE_STEPPER:
        "Sélectionner un modèle de Flux de Travail",
    SECTION_VISU_CONFIG_STRUCTURE_NODES_STEPPER:
        "Sélectionner la structure du projet",
    SECTION_VISU_CONFIG_SVG_TITLE_STEPPER: "Définir les titres",
    SECTION_VISU_CONFIG_LABEL_PROPERTY_STEPPER: "Définir les étiquettes",
    SECTION_VISU_CONFIG_SVG_NAME_STEPPER: "Visualisation des noms",
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_LEFT_TITLE: "Niveaux",
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_RIGHT_TITLE: "Titre",
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE: "Activité sans objet",
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED: "Pas commencé",
    VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE:
        "Disponible - Tous les prédécesseurs sont terminés",
    VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED: "Commencé",
    VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED: "Terminé et confirmé",
    START_BULK_UPDATE: "Lancer la mise à jour de l'avancement",
    UPDATE_PROGRESS: "Mettre à jour la progression de la sélection",
    BULK_UPDATE_MODE_TITLE:
        "Sélectionnez les produits livrables pour mettre à jour une activité.",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Sélection de plusieurs modèles de Flux de Travail...",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "La mise à jour par lots ne fonctionne que pour une sélection de produits livrables du modèle de Flux de Travail. Veuillez ne sélectionner que les produits livrables utilisant le même modèle.",
    SECTION_PROCESS_VIEWER_TITLE: "Activités",
    SECTION_PROCESS_VIEWER_HIDE_ACTIVITIES_SWITCH_TEXT:
        "Masquer les activités terminées",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_TITLE:
        "Aucun produit livrable existant",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_MESSAGE:
        "Ce projet ne contient pas encore de livrables.",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_1:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_2:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION",
    SECTION_PROCESS_VIEWER_PAGE_NO_FILTERED_DATA:
        "@:SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA",
    SECTION_PROCESS_VIEWER_SELECT_ACTIVITIES_MESSAGE:
        "Sélectionnez des activités pour créer des dépendances entre elles.",
    SECTION_PROCESS_VIEWER_SELECTED_ACTIVITIES_MESSAGE:
        "{{activitiesNum}} activités sélectionnées.",
    SECTION_PROCESS_VIEWER_PREDECESSOR_MESSAGE:
        "{{activitiesNum}} activités sélectionnées. Veuillez définir leur prédécesseur maintenant...",
    SECTION_PROCESS_VIEWER_SUCCESSOR_MESSAGE:
        "{{activitiesNum}} activités sélectionnées. Veuillez définir leur successeur maintenant...",
    SECTION_PROCESS_PREVIOUS_PAGE: "Afficher les activités précédentes",
    SECTION_PROCESS_NEXT_PAGE: "Afficher les prochaines activités",
    DIALOG_CREATE_DEPENDENCY_LOADING_TITLE: "Juste une seconde...",
    DIALOG_CREATE_DEPENDENCY_LOADING_TEXT:
        "Nous sommes en train de créer vos dépendances. Cela peut prendre quelques secondes.",

    INFO_PROCESS_VIEWER_NOTE_CREATED: "Note créée avec succès",
    SECTION_DELIVERABLES_PAGE_TITLE: "Produits livrables",
    SECTION_DELIVERABLES_PAGE_SUBTITLE: "Retour au tableau de bord",
    SECTION_DELIVERABLES_PAGE_FILTERS: "Filtres actifs: ",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_CODE_NAME: "Nom et code",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STRUCT_DESC:
        "Description et structure",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_DATES: "Date de début et de fin",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STATUS: "Flux de Travail et état",
    SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA:
        "Il n'y a aucun produit livrable correspondant à vos critères de filtrage.",
    INFO_DELIVERABLES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> livrables dans la liste",
    INFO_LIST_UPDATED_MESSAGE: "Liste mise à jour",
    INFO_LIST_SELECTION_REMOVED_MESSAGE: "Sélection enlevée",
    SEARCHABLE_SELECT_SELECTION_REMOVED_MESSAGE: "Sélection enlevée",
    SELECTED_ACTIVITIES__SECTION_PRINT_HEADER: "Activités sélectionnées",

    ACTION_ASSIGN_TEMPLATE_ERROR_MAX_REQUEST:
        "Le nombre de produits livrables sélectionnés dépassait la demande maximale permise.",
    ACTION_ASSIGN_TEMPLATE_LOADING: "Affectation d'un modèle...",
    ACTION_ASSIGN_TEMPLATE_SUCCESS_N:
        "Modèle de Flux de Travail affecté à {{changed}} sur {{total}} livrables.",
    ACTION_OPEN_BULK_PROGRESS_CHANGE_DIALOG:
        "Mise à jour de l'état d'avancement",
    DIALOG_BULK_PROGRESS_UPDATE_TITLE: "Mise à jour de l'état d'avancement",
    DIALOG_BULK_PROGRESS_UPDATE_HEADER:
        "Veuillez sélectionner toutes les activités que vous souhaitez mettre à jour:",
    INFO_BULK_PROGRESS_CHANGE_MESSAGE:
        "L'enregistrement écrasera le statut existant de toutes les activités sélectionnées pour tous les produits livrables précédemment sélectionnés.",
    INFO_BULK_PROGRESS_CHANGE_SUCCESS:
        "{{items}} étaient réglées sur'{{state}}'.",
    DIALOG_BULK_PROGRESS_UPDATE_ACTION_HEADER:
        "Sélectionnez maintenant le statut que vous voulez déclarer pour ces activités:",
    DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Activer le filtre Modèle de Flux de Travail",
    DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "Pour pouvoir configurer les activités et les mises à jour des rapports, veuillez d'abord utiliser l'outil de filtrage pour sélectionner le modèle de Flux de Travail que vous souhaitez modifier. Vous le trouverez dans la superposition de filtres que vous pouvez ouvrir sur le côté droit.",
    ACTION_OPEN_BULK_UPDATE_OPTIONS_DIALOG: "Mises à jour des rapports",
    DIALOG_BULK_UPDATE_OPTIONS_TITLE:
        "Mettre à jour les livrables sélectionnés",
    DIALOG_BULK_UPDATE_OPTION_UPDATE_PROGRESS_TITLE:
        "Mettre à jour l'état d'avancement des activités",
    DIALOG_BULK_UPDATE_OPTION_CREATE_NOTE_TITLE:
        "Joindre une note aux activités",
    DIALOG_BULK_CREATE_NOTE_TITLE: "Sélectionnez une activité",
    DIALOG_BULK_CREATE_NOTE_HEADER:
        "Veuillez sélectionner l'activité pour laquelle vous souhaitez créer des notes:",
    DIALOG_BULK_PDF_EXPORT_TITLE:
        "Exportation des listes de contrôle d'assurance qualité PDF",
    DIALOG_BULK_PDF_EXPORT_TEXT:
        "Choisissez les activités pour lesquelles vous souhaitez télécharger les fichiers PDF. Nous allons générer des fichiers PDF pour ceux-ci et vous envoyer un e-mail.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_TEXT:
        "Pour exporter toutes les listes de contrôle d'assurance qualité signées à une date précise, veuillez sélectionner la date ci-dessous. Ensuite, nous générerons des PDF pour vous et vous enverrons un e-mail.",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TITLE: "Aucune liste de contrôle à exporter",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TEXT:
        "Il n'y a pas de liste de contrôle remplie enregistrée sur Sablono pour votre sélection actuelle. Pour pouvoir exporter des fichiers PDF, veuillez sélectionner les produits à livrer et les activités qui comprennent des listes de contrôle d'AQ remplies.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_EMPTY_TEXT:
        "Il n'y a pas de listes de contrôle d'assurance qualité qui ont été téléchargées et signées à la date choisie. Pour pouvoir exporter des PDF, veuillez sélectionner un autre jour.",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TITLE:
        "Vos PDFs sont en cours de génération...",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TEXT:
        "Une fois que nous aurons créé vos {{numberOfReports}} PDFs, nous les enverrons par email à {{email}}. Veuillez garder à l'esprit que cela peut prendre un certain temps selon le nombre de PDF que vous avez demandés (par exemple, 1000 PDFs peuvent prendre jusqu'à 60 minutes).",

    DIALOG_DELIVERABLE_CHECKLIST_PDF_EXPORT_TITLE:
        "Exporter les documents de qualité pour le produit livrable",
    DIALOG_DELIVERABLE_CHECKLIST_PDF_SUCCESS_MESSAGE:
        "Une fois votre PDF généré, nous l'enverrons par courriel à {{email}}. Veuillez garder à l'esprit que cela peut prendre un certain temps en fonction de la quantité de données incluses.",

    ACTION_CONFIGURE_DELIVERABLES: "Configurer les produits livrables",
    DIALOG_DELIVERABLE_SET_CONFIG_TITLE: "@:ACTION_CONFIGURE_DELIVERABLES",
    DIALOG__DELIVERABLE_SET__ASSIGN_TEMPLATE__OPTION_TITLE: "Affecter modèle",
    DIALOG__DELIVERABLE_SET__ASSIGN_STRUCTURE__OPTION_TITLE:
        "Passer à la structure du projet",
    DIALOG__DELIVERABLE_SET__CHANGE_DATES__OPTION_TITLE:
        "Modifier la date de livraison",
    DIALOG__DELIVERABLE_SET__ASSIGN_DELIVERABLE_TYPE__OPTION_TITLE:
        "Attribuer un type",
    DIALOG__DELIVERABLE_SET__ASSIGN_RESPONSIBLE_MANAGER__OPTION_TITLE:
        "Affecter un gestionnaire responsable",
    DIALOG__DELIVERABLE_SET__DELETE__OPTION_TITLE: "Supprimer les livrables",

    ACTION_CONFIGURE_ACTIVITIES: "Configurer les activités",
    DIALOG_ACTIVITY_SET_CONG_TITLE: "@:ACTION_CONFIGURE_ACTIVITIES",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_END_TITLE: "Spécifier la date de fin",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_START_TITLE: "Précisez la date de début",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_TITLE:
        "Supprimer les dates d'activité définies par l'utilisateur",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_SUBMIT:
        "Supprimer les dates définies par l'utilisateur",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_TITLE:
        "Masquer les activités du Flux de Travail",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_TITLE:
        "Afficher les activités cachées",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES:
        "Veuillez sélectionner les activités qui doivent être à nouveau visibles pour les produits sélectionnés précédemment:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY:
        "Veuillez sélectionner les activités pour lesquelles vous souhaitez définir une date de début personnalisée:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_END_DATE:
        "Veuillez sélectionner les activités pour lesquelles vous souhaitez définir une date de fin personnalisée:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_UNSET:
        "Veuillez sélectionner les activités pour lesquelles vous souhaitez supprimer toutes les dates de début et de fin personnalisées:",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_SELECT_ACTIVITIES:
        "Veuillez sélectionner les activités qui devraient être cachées du Flux de Travail des produits livrables que vous avez précédemment sélectionnés:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_DATE:
        "Sélectionnez une date pour ",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_HINT:
        "Veuillez noter que les dates de début ou d'achèvement les plus rapprochées ne seront utilisées comme contrainte lors du calcul de votre planning de projet que si elles ne sont pas en contradiction avec vos Flux de Travail et dépendances définis.",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_SUBMIT: "Masquer les activités",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUBMIT:
        "Afficher les activités cachées",
    DIALOG_ACTIVITY_UNSET_WHAT_TEAM:
        "Pour quelle équipe souhaitez-vous utiliser à nouveau le standard?",

    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_TITLE:
        "Modifier la durée des activités",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SELECT:
        "Veuillez sélectionner les activités des livrables sélectionnés précédemment pour lesquelles vous souhaitez modifier la durée:",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SUBMIT: "Durée de la modification",
    ACTION_SET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} durées ont changé avec succès.",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_TITLE:
        "Supprimer la durée des activités définie par l'utilisateur",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SELECT:
        "Veuillez sélectionner les activités des produits livrables précédemment sélectionnés pour lesquelles vous souhaitez supprimer les activités définies par l'utilisateur et réactiver la durée standard définie dans le modèle de Flux de Travail:",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SUBMIT:
        "Utiliser la durée standard",
    ACTION_UNSET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} durées ont changé avec succès.",
    DIALOG_ASSIGN_AREA_MANAGER_TITLE: "Affecter un gestionnaire responsable",
    DIALOG_ASSIGN_AREA_MANAGER_TEXT:
        "Veuillez saisir l'email de votre partenaire que vous souhaitez définir comme responsable. Veuillez noter que vous devez d'abord les ajouter à ce projet.",
    DIALOG_ASSIGN_AREA_MANAGER_ACTION: "Attribuer",
    ACTION_ASSIGN_AREA_MANAGER_SUCCESS_N:
        "{{displayName}} a été défini comme gestionnaire responsable pour {{deliverables}} livrables.",
    ACTION_SEND_REQUEST_FOR_PROGRESS: "Envoyer la demande",
    ACTION_OPEN_REQUEST_FOR_PROGRESS_DIALOG: "Demande de mise à jour",
    DIALOG_REQUEST_FOR_UPDATE_TITLE: "Demande de mise à jour",
    DIALOG_REQUEST_FOR_UPDATE_CONTENT_HEADER:
        "Veuillez saisir l'adresse e-mail de votre partenaire dont vous souhaitez recevoir des informations. Veuillez noter que vous devez d'abord les ajouter à ce projet.",
    DIALOG_PICK_PROJECT_MEMBER_USERS_PLACEHOLDER:
        "Nom, adresse e-mail ou nom de l'entreprise du partenaire...",
    DIALOG_PICK_PROJECT_MEMBER_USER_NOT_FOUND:
        "On dirait que vous essayez de sélectionner un utilisateur qui n'a pas été ajouté à ce projet. Veuillez les ajouter en premier.",
    DIALOG_REQUEST_FOR_UPDATE_WARNING:
        "Il semble que vous ayez choisi un très grand nombre de produits livrables. Nous vous recommandons d'envoyer de plus petites demandes d'information, car votre partenaire pourrait être submergé par la quantité d'information requise.",
    TOAST_REQUEST_FOR_PROGRESS_SUCCESS:
        "Demande de mise à jour pour {{deliverables}} livrables envoyé à {{username}}.",

    ACTION_OPEN_ASSIGN_TYPE_DIALOG: "Attribuer un type de livrable",
    DIALOG_ASSIGN_TYPE_TITLE: "Attribuer un type de livrable",
    DIALOG_ASSIGN_TYPE_TEXT:
        "Veuillez créer ou sélectionner un type que vous souhaitez attribuer aux produits livrables sélectionnés précédemment:",
    DIALOG_ASSIGN_TYPE_ACTION: "Attribuer",
    DIALOG_PICK_TYPE_TO_ASSIGN_PLACEHOLDER: "Créer ou sélectionner un type...",
    DIALOG_PICK_TYPE_TO_ASSIGN_NOT_FOUND:
        "Ce type n'existe pas encore dans ce projet...",

    _TIMEZONE: "Fuseau horaire",
    _DELAY: "Livraison prévue",
    _DELAYED_BY_X_DAYS: "{{days}} jours en retard",
    _SOURCE: "Source",
    _CREATED_WITH_EXCEL: "Importé d'Excel",
    _CREATED_WITH_PRIMAVERA: "Importé de Primavera",
    _CREATED_WITH_MSP: "Importé de Microsoft Project",
    _CREATED_WITH_ASTA: "Importé d'Asta Powerproject",
    _CREATED_WITH_CLONE: "Cloné à partir d'un autre projet",
    _CREATED_WITH_USER: "Créé avec Sablono",
    _SHOW_ALL: "Afficher toutes les activités",
    _SHOW_ALL_CHECKLISTS: "Afficher tous les listes de contôle d’AQ",

    INFO_BEHIND_SCHEDULE_EXPLANATION_MESSAGE:
        "Au moins une activité en retard sur le calendrier",
    OPEN_ACTIVITY_UPDATE_TIMELINE:
        "Ouvrir la Historique des action pour cette activité",
    SECTION_DEPENDENCIES_TITLE: "Dépendances",
    INFO_NO_CHECKLIST_ITEMS:
        "Il semble que cette liste de contrôle ait été soumise sans qu'aucun élément n'ait été coché.",
    INFO_DELIVERABLES_DEPENDENCIES_SCHEDULE_MODE_TEXT:
        "En mode planification individuelle, les dépendances n'influencent pas la planification de votre projet. Vous pouvez passer à la planification connectée sur la page Paramètres du projet.",
    INFO_DELIVERABLE_PREDECESSOR_ADD_TEXT:
        "Les prédécesseurs doivent être terminés avant que ce livrable puisse être lancé.",
    INFO_DELIVERABLE_SUCCESSOR_ADD_TEXT:
        "Les successeurs ne peuvent commencer qu'après la fin de ce livrable.",
    INFO_DETAILS_OVERLAY_FILTER_TEXT: "Filtrer par équipe",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_TITLE:
        "Aucune activité assignée à l'équipe",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_MESSAGE:
        "Il semble que l'équipe n'ait été assignée responsable d'aucune activité de cette séquence.",
    TOAST_DELETE_CUSTOM_DATES: "Date définie par l'utilisateur supprimée",
    SELECT_PREDECESSOR_DEPENDENCY_TITLE:
        "Sélectionner les prédécesseurs dans la liste",
    SELECT_SUCCESSOR_DEPENDENCY_TITLE:
        "Sélectionner les successeurs dans la liste",
    ACTION_ADD_SUCCESSOR: "Ajouter un successeur",
    ACTION_ADD_PREDECESSOR: "Ajouter un prédécesseur",
    ACTION_CLOSE_DEPENDENCY_MANAGER: "Terminer l'édition",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_PRED:
        "Recherchez les livrables par nom, code ou description et définissez les prédécesseurs...",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_SUCC:
        "Recherchez les livrables par nom, code ou description et définissez les successeurs...",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_DIALOG_TITLE: "Supprimer dépendance",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_MULTIPLE_DIALOG_TEXT:
        "Voulez-vous vraiment supprimer cette dépendance? Comme il a été automatiquement transformé à partir d'une dépendance de deux livrables, toutes les dépendances qui lui sont associées ({{value}}) seront également supprimées.",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_SINGLE_DIALOG_TEXT:
        "Voulez-vous vraiment supprimer cette dépendance?",
    INFO_ACTIVITY_DEPENDENCY_NOT_FULFILLED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} doit être terminé avant de pouvoir commencer <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}}.",
    INFO_ACTIVITY_DEPENDENCY_COMPLETED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} est terminé donc <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}} peut être démarré.",
    ERROR_EDGE_WOULD_INTRODUCE_CYCLE:
        "La dépendance demandée donnerait lieu à un cycle.",
    DIALOG_ASSIGN_STRUCTURE_TITLE:
        "Déplacer les livrables dans la partie structure du projet",
    DIALOG_ASSIGN_STRUCTURE_SELECTED_STRUCTURE_TEXT:
        "Les produits livrables sélectionnés seront déplacés dans la partie structurelle suivante:",
    ACTION_ASSIGN_STRUCTURE:
        "Déplacer les livrables dans la partie structure du projet",
    ACTION_CHANGE_DATES_SUCCESS_N:
        "Les dates de {{deliverables}} livrables ont été modifiées.",
    DIALOG_CHANGE_DATES_RANGE: "Conserver la durée de {{days}} jours.",
    DIALOG_CHANGE_DATES_LOADING_TEXT: "Modifier les dates...",
    DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT: "Supprimer la date",
    DIALOG_ASSIGN_STRUCTURE_LOADING_TEXT: "Des livrables mobiles...",
    ACTION_ASSIGN_STRUCTURE_SUCCESS_N:
        "{{deliverables}} livrables a été déplacé.",
    DIALOG_CHANGE_DATES_OPTION_BOTH_TITLE:
        "Modifier les dates de début et de fin des travaux",
    DIALOG_CHANGE_DATES_OPTION_START_TITLE: "Modifier les dates de début",
    DIALOG_CHANGE_DATES_OPTION_END_TITLE: "Modifier les dates d'achèvement",
    INFO_DATE_CHANGE_CONFLICT_STRATEGY_MESSAGE:
        "Des conflits de dates (achèvement avant la date de début) peuvent survenir. Ces conflits seront résolus en annulant la date si vous décidez de continuer.",
    ACTION_SET_ACTIVITY_DUE_DATES_SUCCESS_N:
        "Les dates d'achèvement de {{activities}}/{{totalActivitiesForBulkOperation}} activités ont été modifiées.",
    ACTION_SET_ACTIVITY_START_DATES_SUCCESS_N:
        "Les dates de début des {{activities}}/{{totalActivitiesForBulkOperation}} activités ont été modifiées.",
    ACTION_DELETE_BULK_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} activités cachées avec succès.",
    ACTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} activités à nouveau visibles",
    ACTION_UNSET_ACTIVITY_DATES_SUCCESS_N:
        "Les dates définies par l'utilisateur des activités {{activities}}/{{totalActivitiesForBulkOperation}} ont été supprimées.",

    DIALOG_DELETE_DELIVERABLES_TITLE: "Supprimer les livrables",
    DIALOG_DELETE_DELIVERABLES_TEXT_N:
        "Voulez-vous vraiment supprimer {{items}} livrables?",
    DIALOG_DELETE_DELIVERABLES_OK: "Oui, effacez-les",
    TOAST_DELETE_DELIVERABLES_SUCCESS:
        "{{items}} livrables supprimé avec succès.",
    DIALOG_DELETE_DELIVERABLES_LOADING_TEXT: "Suppression des livrables...",
    INFO_ALL_ISSUES_RESOLVED: "Tous les problèmes ont été résolus.",
    INFO_NO_NOTES_REPORTED_TITLE: "Aucune note rapportée.",
    INFO_ISSUES_RESOLVED: "{{closed || 0}}/{{total}} Problèmes résolu",
    ACTION_PRINT_DELIVERABLES_REPORT:
        "Rapport sur les produits livrables imprimés",
    ACTION_PRINT_ACTIVITIES_REPORT: "Imprimer le rapport d'activités ",
    ACTION_PRINT_STRUCTURE_REPORT:
        "Imprimer le rapport sur la structure du projet",
    ACTION_PRINT_REPORT: "Imprimer le rapport",
    ACTION_PRINT_REPORTS: "Imprimer les rapports",
    ACTION_EXPORT_DATA: "Exporter les données",
    ACTION_SHOW_PROCESS_VIEWER: "Afficher les activités",
    ACTION_SHOW_DELIVERABLES_LIST: "Afficher les livrables",
    ACTION_ASSIGN_AS_PREDECESSOR: "Définir prédécesseur de sélection",
    ACTION_ASSIGN_AS_SUCCESSOR: "Définir le successeur de la sélection",
    INFO_DELIVERABLES_REPORT_TITLE: "Produits livrables du rapport d'étape",
    INFO_DELIVERABLES_REPORT_TEXT:
        "Cet état contient des informations d'avancement pour les produits livrables qui sont actuellement affichés dans la liste.",
    INFO_ACTIVITIES_REPORT_TITLE: "Activités du rapport d'étape",
    INFO_ACTIVITIES_REPORT_TEXT:
        "Cet état détaillé contient des informations d'avancement pour les livrables actuellement affichés ainsi que les activités qui leur sont affectées.",
    INFO_STRUCTURE_REPORT_TEXT:
        "Cet état contient des informations d'avancement pour l'ensemble du projet structuré par la structure de projet définie.",
    INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE:
        "Exporter les données du projet (CSV)",
    INFO_QR_CODES_TITLE: "Codes QR",

    OVERVIEW_TITLE: "Rapport d'avancement Structure du projet",
    OVERVIEW_TEXT:
        "Ce rapport contient des informations sur l'état d'avancement de votre projet en fonction de l'organigramme technique.",

    INFO_QR_CODES_TEXT:
        "Les codes QR peuvent être utilisés pour identifier les livrables sur le chantier de construction et trouver plus rapidement les activités associées dans l'application mobile.",
    INFO_QA_CHECKLIST_PDFS_TITLE: "Liste de contrôle PDFs",
    INFO_QA_CHECKLIST_PDFS_TEXT:
        "Dans l'étape suivante, vous pourrez sélectionner les activités pour lesquelles vous souhaitez exporter vos listes de contrôle d'assurance qualité au format PDF.",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TITLE: "Liste de contrôle PDF",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TEXT:
        "Cette exportation contient tous les PDF des listes de contrôle d'assurance qualité qui ont été signés un jour donné. Dans l'étape suivante, vous pourrez sélectionner la date à laquelle vous souhaitez exécuter l'exportation.",
    SECTION_CHOOSE_REPORT_TYPE: "Sélectionnez un rapport",
    INFO_NO_FILTERED_DATA:
        "Il n'y a pas de données correspondant à vos critères de filtrage.",
    SECTION_LEANBOARD_TITLE: "Planification Lean",
    SECTION_LEANBOARD_EMPTY_MESSAGE:
        "Il n'y a pas d'activités programmées pendant les jours actuellement montrés.",
    ACTION_LEANBOARD_GO_TO_PREV_WEEK: "Ouvert les semaines précédentes",
    ACTION_LEANBOARD_GO_TO_NEXT_WEEK: "Ouvert les prochaines semaines",
    ACTION_LEANBOARD_OPEN_OVERLAY: "Voir les détails",
    ACTION_LEANBOARD_FORECAST_DELIVERABLE_DATES: "Prévoir tous",
    ACTION_LEANBOARD_SHOW_AGGREGATED_DELIVERABLES:
        "Montrer les livrables inclus",
    ACTION_LEANBOARD_ACCEPT_ALL: "Accepter tout",
    ACTION_LEANBOARD_OPEN_LATE_ACTIVITIES_PANEL:
        "{{lateActivities}} activités tardives",
    DIALOG_LATE_ACTIVITIES_TITLE:
        "Activités tardives pour {{deliverableName}}.",
    DIALOG_LATE_ACTIVITIES_MESSAGE:
        "Cliquez sur les activités pour définir de nouvelles dates.",
    ACTION_LEANBOARD_MOVE_TO_THIS_WEEK: "Passer à la semaine en cours",
    INFO_LEANBOARD_UPDATING_SCHEDULE: "Chargement...",
    ONE_WEEK: "1 sem",
    TWO_WEEKS: "2 sem",
    THREE_WEEKS: "3 sem",
    FOUR_WEEKS: "4 sem",
    EIGHT_WEEKS: "8 sem",
    TWELVE_WEEKS: "12 sem",
    COLUMN_TIMEFRAME_DAYS: "Jours",
    COLUMN_TIMEFRAME_WEEKS: "Semaines",

    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_HEADER:
        "Séance de planification en cours d'exécution...",
    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_MESSAGE:
        "Vous êtes en train d'organiser une séance de planification Regard sur l'avenir. Voulez-vous quitter la page sans sauvegarder ou supprimer vos modifications? Veuillez noter que les autres utilisateurs ne pourront pas modifier l'horaire du projet tant que votre session est toujours en cours.",
    ACTION_STAY_IN_LEANBOARD_SESSION: "Séjour",
    ACTION_DISCARD_LEANBOARD_SESSION_AND_LEAVE: "Laisser et jeter",
    ACTION_RELEASE_LEANBOARD_SESSION_AND_LEAVE: "Quitter et publier",
    ACTION_KEEP_LEANBOARD_SESSION_OPEN_AND_LEAVE:
        "Quitter et maintenir la session en cours d'exécution",
    INFO_LEANBOARD_DELIVERABLE_SELECTED:
        "Une activité de ce livrable a été sélectionnée.",
    SECTION_REQUEST_TEXT:
        "Mettez à jour les livrables ou recherchez d'autres livrables si vous ne trouvez pas ceux sur lesquels vous avez travaillé.",
    SECTION_REQUEST_SUCCESS_TITLE: "Mise à jour réussie",
    SECTION_REQUEST_SUCCESS_TEXT:
        "Votre mise à jour a été sauvegardée. Merci de votre collaboration!",
    SECTION_REQUEST_UPLOAD_BUTTON: "Enregistrer la progression",
    SECTION_REQUEST_INFORMATION_OPT_OUT_BUTTON: "Rechercher d'autres livrables",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TITLE: "Accès refusé",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TEXT:
        "Il semble que le lien sur lequel vous avez cliqué était censé être utilisé par un autre partenaire du projet. Veuillez vous assurer que le courriel vous est destiné et vérifier auprès de son expéditeur.",
    SECTION_REQUEST_ERROR_CLOSED_TITLE: "Lien expiré",
    SECTION_REQUEST_ERROR_CLOSED_TEXT:
        "Le lien sur lequel vous avez cliqué a déjà été utilisé pour envoyer un rapport d'avancement à la plate-forme. Veuillez vous assurer que vous avez utilisé le bon courriel.",
    SECTION_REQUEST_ERROR_NOT_EXIST_TITLE: "Pas de demande ouverte",
    SECTION_REQUEST_ERROR_NOT_EXIST_TEXT:
        "Oups... Il semble qu'il n'y ait pas de demande de mise à jour en cours concernant le lien que vous avez utilisé. Veuillez vous assurer que vous avez cliqué sur un lien valide.",
    ACTION_SELECT_PROJECT: "Sélectionnez un projet",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_PLACEHOLDER: "Rechercher ...",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_TITLE:
        "Recherche de livrables",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_MESSAGE:
        "Recherchez les produits livrables par nom, code ou description.",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_START_SEARCH_ACTION:
        "Démarrer la recherche",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_TITLE:
        "Aucun produit livrable trouvé",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_MESSAGE:
        "Aucune correspondance trouvée pour <b>{{searchTerm}}</b>.",
    SECTION_DELIVERABLES_PROGRESS_LIST_PREDECESSOR_DONE_BADGE:
        "Prédécesseur terminé",
    SECTION_DELIVERABLES_PROGRESS_LIST_SCHEDULED_BADGE: "Travaux prévus",
    SECTION_RESET_PASSWORD_LINK: "Oublié votre mot de passe?",
    SECTION_RESET_PASSWORD_TEXT:
        "Veuillez saisir votre adresse e-mail. Nous vous enverrons un courriel avec des conseils supplémentaires.",
    SECTION_RESET_PASSWORD_BUTTON: "Demander un nouveau mot de passe",
    SECTION_RESET_PASSWORD_SUCCESS_TITLE: "Plus que quelques pas...",
    SECTION_RESET_PASSWORD_SUCCESS_TEXT:
        'Veuillez vérifier votre boîte de réception pour plus d\'instructions. Si vous ne trouvez pas notre courrier électronique, vérifiez également votre dossier de courrier indésirable. Si vous ne le trouvez pas, veuillez contacter notre service clientèle: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_TITLE: "Oups...",
    SECTION_RESET_PASSWORD_ERROR_TEXT:
        'On dirait que quelque chose a mal tourné. Veuillez réessayer sous peu. Si cela se reproduit, n\'hésitez pas à contacter notre service clientèle: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE:
        "L'utilisateur n'existe pas",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT:
        "Il semble que cet utilisateur ne soit pas enregistré pour Sablono. Veuillez vous assurer que vous n'avez pas mal orthographié.",
    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE:
        "Code de vérification non valide",
    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT:
        "Il semble que le code de vérification que vous avez saisi ne soit pas correct. Veuillez revérifier et réessayer.",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TITLE:
        "Code de vérification expiré",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TEXT:
        "Oups... Il semble que votre code de vérification ait expiré. Veuillez en demander un nouveau et réessayer.",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TITLE:
        "L'utilisateur ne peut pas être confirmé",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TEXT:
        "L'utilisateur est déjà confirmé",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TITLE: "Oups...",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TEXT:
        "Il semble que nous n'ayons pas pu livrer le courriel contenant le mot de passe temporaire à l'adresse que vous avez utilisée. Veuillez contacter notre service clientèle: <a href=\"mailto:support@sablono.com\">support@sablono.com</a> Nous vous contacterons dès que possible.",
    SECTION_ISSUES_PAGE_TITLE: "Notes",
    INFO_ISSUES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> consignes dans la liste",
    INPUT_ISSUE_TEXT_SEARCH_TITLE: "Texte de la note",
    INPUT_ISSUE_TEXT_SEARCH_PLACEHOLDER: "Filtrer sur le texte de la note...",
    INPUT_ISSUE_KEY_SEARCH_LABEL: "Note code",
    INPUT_ISSUE_KEY_PLACEHOLDER: "Filtrer par code de note...",
    INPUT_ISSUE_TYPE_LABEL: "Type de note",
    INPUT_ISSUE_STATUS_LABEL: "Statut",
    INPUT_ISSUE_TEMPLATE_KEY_LABEL: "Note - Code modèle",
    INPUT_ISSUE_TEMPLATE_KEY_PLACEHOLDER: "Filtrer par code note-modèle...",
    INPUT_ISSUE_AUTHOR_SEARCH_LABEL: "Reporter",
    INPUT_ISSUE_AUTHOR_PLACEHOLDER:
        "Filtrer par le nom de l'utilisateur qui rapporte la note...",
    INPUT_ISSUE_DELIVERABLE_SEARCH_LABEL: "Recherche de produits livrables",
    INPUT_ISSUE_DELIVERABLE_PROCESS_SEARCH_LABEL: "Modèle de Flux de Travail",
    INPUT_ISSUE_DELIVERABLE_SEARCH_PLACEHOLDER:
        "Recherche de notes par nom, code ou description de livrable...",
    INPUT_DELIVERABLE_NAME_LABEL: "Nom du produit livrable",
    INPUT_DELIVERABLE_NAME_PLACEHOLDER: "Filtrer par nom de livrable...",
    INPUT_DELIVERABLE_CODE_LABEL: "Code de livrable",
    INPUT_DELIVERABLE_CODE_PLACEHOLDER: "Filtrer par code livrable...",
    INPUT_DELIVERABLE_DESC_LABEL: "Description des produits livrables",
    INPUT_DELIVERABLE_DESC_PLACEHOLDER:
        "Filtrer par description des produits livrables...",
    INPUT_ACTIVITY_SEARCH_LABEL: "Recherche par activité",
    INPUT_ACTIVITY_SEARCH_PLACEHOLDER:
        "Recherche de notes par nom ou description d'activité...",
    INPUT_ACTIVITY_NAME_LABEL: "Nom de l'activité",
    INPUT_ACTIVITY_NAME_PLACEHOLDER: "Filtrer par nom d'activité...",
    INPUT_ACTIVITY_DESC_LABEL: "Description de l'activité",
    INPUT_ACTIVITY_DESC_PLACEHOLDER: "Filtrer par description d'activité...",

    INPUT_ISSUE_ISSUE_RESPONSIBLE_TEAM_PLACEHOLDER:
        "Choisissez une équipe responsable...",

    INPUT_ISSUE_ISSUE_CONFIRMATION_TEAM_PLACEHOLDER:
        "Choisissez une équipe de révision...",

    INPUT_ISSUE_GEOLOCATION_INFO:
        "L'administrateur de votre projet vous a demandé de télécharger des photos incluant la géolocalisation. Veuillez vous assurer que vous le faites.",

    DIALOG_ISSUE_EDITOR_TITLE: "Créer une nouvelle note",
    DIALOG_EDIT_ISSUE_TITLE: "Note d'édition",
    DIALOG_EDIT_ISSUE_TOOLTIP: "@:ACTION_TEMPLATE_ADD_CONTENT",
    DIALOG_EDIT_ISSUE_SUCCESS: "@:INFO_PROJECT_EDIT_SUCCESS_TITLE",
    DIALOG_ISSUE_EDITOR_TEAM_SELECTOR_LABEL: "Équipe responsable",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_ERROR_REQUIRED:
        "Veuillez sélectionner une équipe de révision avant de continuer",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_LABEL: "Équipe de révision",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_NONE:
        "Aucun examen séparé n'est demandé",
    INPUT_ISSUE_DESCRIPTION_TITLE: "Entrez un texte décrivant la nouvelle note",
    ACTION_ADD_ATTACHMENT_TO_NOTE: "Ajouter une pièce jointe",
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TITLE: "État de l'émission du rapport",
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TEXT:
        "Voulez-vous vraiment fermer ce dossier? Cela ne peut pas être défait.",

    ACTION_CLOSE_ISSUE: "Résolu",
    ACTION_OPEN_ISSUE: "Ouvert",
    ACTION_FINISH_ISSUE: "Attente de Confirmation",
    ACTION_CONFIRM_ISSUE: "Résolu",
    ACTION_REJECT_ISSUE: "Réouvert",

    DIALOG_PRINT_ISSUE_REPORT_TITLE: "Sélectionnez un rapport",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TITLE:
        "Rapport PDF Notes avec photos",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TEXT:
        "Ce rapport contiendra les notes qui sont actuellement affichées dans la liste.",
    SECTION_PRINT_CSV_ISSUE_REPORT_TITLE: "Tableau CSV",
    SECTION_PRINT_CSV_ISSUE_REPORT_TEXT:
        "Ce tableau contiendra toutes les notes existantes et pourra être ouvert dans Microsoft Excel.",

    //user defined dates
    INFO_DELETING_ALL_USER_DEFINED_DATES_MESSAGE:
        "Suppression de dates définies par l'utilisateur",
    INFO_USER_DEFINED_DATE: "Date définie par l'utilisateur",
    USER_DEFINED_DATE_CONFLICT:
        "Conflit de dates défini et calculé par l'utilisateur",
    INFO_USER_DEFINED_DATE_EXPLAINED:
        "Cette date définie par l'utilisateur contredit le Flux de Travail et les dépendances définis. Par conséquent, vous le verrez ici, mais il n'influence pas l'horaire calculé.",
    INFO_EQUAL_DISTRIBUTED_DURATIONS:
        "Durée calculée - La durée de cette activité a été calculée en fonction de la durée livrable. Vous pouvez définir des durées d'activité à l'aide de l'éditeur de modèle.",
    INFO_SCHEDULING_ON_DEMAND_DIRTY_MODE_TEXT:
        "Vous avez appliqué des modifications au calendrier de ce projet (par exemple, des livrables supprimés ou de nouvelles dépendances créées) qui n'ont pas encore été intégrées dans le programme.",
    ACTION_RECALCULATE_SCHEDULE: "Recalculer l'horaire",
    SECTION_COMMERCIAL_PAGE_TITLE: "Tableau de bord commercial",
    SECTION_COMMERCIAL_PRO_PAGE_TITLE: "Évaluation commerciale",
    ACTION_NAV_TO_COMMERCIAL_PRO: "Évaluation commerciale",

    ACTION_NAV_TO_DASHBOARD_PRO: "Centre de contrôle des projets",
    SECTION_DASHBOARD_PRO: "Centre de contrôle des projets",

    ACTION_NAV_TO_COMMERCIAL: "Tableau de bord commercial",
    SECTION_COMMERCIALS_PAGE_LOADING_DATA_MESSAGE:
        "Chargement des données commerciales",
    SECTION_COMMERCIALS_PAGE_NO_DATA_TITLE: "Aucun groupe de coûts disponible",
    SECTION_COMMERCIALS_PAGE_NO_DATA_MESSAGE:
        "Les groupes de coûts vous aideront à évaluer l'avancement de votre projet. Actuellement, aucun groupe de coûts n'est disponible pour que vous puissiez le consulter, ce qui pourrait signifier qu'aucun n'a été créé ou que vous n'avez pas encore suffisamment de permissions pour les consulter.",
    ACTION_SETUP_COST_GROUPS: "Créer groupe de coûts",
    DIALOG_CREATE_COST_GROUP_TITLE: "Créer un nouveau groupe de coûts",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_TYPE:
        "Tout d'abord, sélectionnez le schéma de Flux de Travail qui contient les activités que vous voulez affecter au nouveau groupe de coûts:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_ACTIVITIES:
        "Veuillez sélectionner toutes les activités qui font partie du nouveau groupe de coûts:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_NAME:
        "Quel est le nom du groupe de coûts?",
    DIALOG_CREATE_COST_GROUP_NEW_NAME_PLACEHOLDER: "Nom",
    ACTION_DELETE_COST_GROUP: "Supprimer groupe de coûts",
    COST_GROUP_SAVED: "Groupe de coûts sauvegardé",
    INFO_COST_GROUP_STAGES_TITLE: "Ventilation du montant du contrat",
    INFO_COST_GROUP_STAGE_WEIGHT: "du montant du contrat",
    INFO_COST_GROUP_STAGE_BUDGET_ALLOCATED: "Montant du contrat alloué",
    INFO_COST_GROUP_STAGE_BUDGET_STARTED: "En cours",
    INFO_COST_GROUP_STAGE_BUDGET_WAITING_FOR_CONFIRMATION:
        "En attente d'approbation",
    INFO_COST_GROUP_STAGE_BUDGET_COMPLETED: "Signé",
    INFO_COST_GROUP_STAGE_PERCENTAGE_OF_COST_GROUP_SPENT: "Coût terminé",
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_TITLE:
        "Veuillez allouer 100,00% de votre budget.",
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_MESSAGE: "Actuellement attribué:",
    ERROR_COST_GROUP_CONFIG_REMAINING_WEIGHT_MESSAGE: "Déviation:",
    INPUT_COST_GROUP_ENTER_BUDGET_TITLE: "Saisir le montant du contrat",
    COST_GROUP_BUDGET_ALLOCATED: "Montant du contrat alloué",
    COST_GROUP_BUDGET_STARTED: "Dans l'ensemble, en cours",
    COST_GROUP_BUDGET_WAITING_FOR_CONFIRMATION:
        "En attente de l'approbation entière",
    COST_GROUP_BUDGET_COMPLETED: "Entièrement approuvé",
    COST_GROUP_PERCENT_COST_COMPLETED: "Coût total des travaux achevés",
    ERROR_COST_GROUP_WEIGHT_INPUT_PATTERN_MESSAGE: "Pas un nombre",
    ERROR_COST_GROUP_WEIGHT_BUDGET_MIN_MESSAGE:
        "0 est le budget minimum soutenu",
    COST_GROUP_NUMBER_OF_COMMERCIAL_MANAGERS:
        "Directeurs commerciaux: {{numberOf}}",
    COST_GROUP_NUMBER_OF_COMMERCIAL_REVIEWERS:
        "Réviseurs commerciaux: {{numberOf}}",
    COST_GROUP_ADD_MEMBER_DIALOG_HEADER:
        "Ajouter ou supprimer des utilisateurs",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_MANAGER:
        "<b>Gestionnaires commerciaux </b> peuvent modifier et réviser les valeurs commerciales de cette équipe et en gérer l'accès.",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_REVIEWER:
        "<b>Les examinateurs commerciaux </b> peuvent réviser les valeurs commerciales de cette équipe, mais ne peuvent rien modifier.",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_HEADER: "Est-tu vous confiant?",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_TEXT:
        "Une fois que vous vous serez retiré, vous ne pourrez plus voir ni modifier les valeurs commerciales de cette équipe. Voulez-vous continuer?",
    COMMERCIAL_PAGE_SECTION_COMBINED_STAGES:
        "Activités à partir de plusieurs modèles de Flux de Travail",
    ACTION_COMBINE_COST_GROUP: "Combiner des activités",
    DIALOG_COMBINE_COST_GROUP_TITLE: "Combiner des activités",
    DIALOG_COMBINE_COST_GROUP_STAGE_SELECT_ACTIVITIES_DESCRIPTION:
        "Veuillez sélectionner toutes les activités que vous souhaitez regrouper sur une seule ligne du groupe de coûts:",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_DESCRIPTION:
        "Veuillez préciser le nom de la ligne combinée comprenant les activités précédemment sélectionnées:",
    DIALOG_CONFIRM_COMBINE: "Combiner",

    COST_GROUP_STAGE_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Recherche d'activités par modèle de Flux de Travail et équipe...",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_PLACEHOLDER: "Nom",
    DELIVERABLE_CODE: "Code de livrable",
    DELIVERABLE_NAME: "Produit livrable - Nom",
    DELIVERABLE_DESC: "Livrable-Description",
    PROJECT_STRUCTURE: "Structure du projet",
    PLANNED_DELIVERABLE_START_DATE: "Début défini par l'utilisateur (Livrable)",
    PLANNED_DELIVERABLE_END_DATE: "Fin définie par l'utilisateur (Livrable)",
    EARLIEST_DELIVERABLE_START_DATE: "Début au plus tôt (Livrable)",
    EARLIEST_DELIVERABLE_END_DATE: "Fin au plus tôt (Livrable)",
    LATEST_DELIVERABLE_START_DATE: "Début au plus tard (Livrable)",
    LATEST_DELIVERABLE_END_DATE: "Dernière extrémité (Livrable)",
    DELIVERABLE_SCHEDULE: "Livrable - Echéancier",
    DELIVERABLE_PROGRESS: "Produits livrables - Progrès réalisés",
    ACTIVITY_ID: "ID de l'activité",
    ACTIVITY_NAME: "Nom de l'activité",
    ACTIVITY_DESCRIPTION: "Description de l'activité",
    ACTIVITY_PLANNED_DURATION: "Durée prévue",
    ACTIVITY_ASSIGNED_TEAM_NAME: "Équipe responsable",
    PLANNED_ACTIVITY_START_DATE: "Début défini par l'utilisateur (activité)",
    PLANNED_ACTIVITY_END_DATE: "Fin définie par l'utilisateur (activité)",
    EARLIEST_ACTIVITY_START_DATE: "Début au plus tôt (activité)",
    EARLIEST_ACTIVITY_END_DATE: "Fin au plus tôt (activité)",
    LATEST_ACTIVITY_START_DATE: "Début au plus tard (activité)",
    LATEST_ACTIVITY_END_DATE: "Dernière fin (Activité)",
    ACTIVITY_ACTUAL_START_DATE: "Date de début de l'activité (réelle)",
    ACTIVITY_ACTUAL_END_DATE: "Date de fin d'activité (réelle)",
    ACTUAL_START_REPORTED_BY: "Début signalé par",
    ACTUAL_END_REPORTED_BY: "Fini rapportée par",
    ACTIVITY_OPEN_CLAIM_QUANTITY: "Problèmes de qualité en suspens",
    ACTIVITY_OPEN_OBSTRUCTION_QUANTITY: "Obstructions ouvertes",
    ACTIVITY_CURRENT_PROGRESS: "Situation actuelle",
    ACTIVITY_SCHEDULE: "Calendrier des activités",
    ACTIVITY_REQUIRED_CHECKLIST: "Liste de contrôle ci-jointe",
    ACTIVITY_LAST_PLANNED_START: "Regard vers l'avenir (Activité)",
    ACTIVITY_LAST_PLANNED_END: "Regard vers l'avenir (Activité)",
    ACTIVITY_LAST_PLANNED_DURATION:
        "Durée du regard vers l'avenir (jours ouvrables)",
    ACTIVITY_CONFIRMATION_TIME: "Date de confirmation de l'activité (réelle)",
    ACTIVITY_CONFIRMATION_REPORTER: "Confirmé par",
    ACTIVITY_STATE: "Statut",
    CHECKLIST_TEMPLATE_PAGE_NAME: "Listes de contrôle d'AQ",
    CHECKLIST_TEMPLATE_ADD_NEW: "Créer une nouvelle liste de contrôle",
    CHECKLIST_TEMPLATE_LIST_LOADING_DATA_MESSAGE:
        "Chargement des listes de contrôle",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TITLE:
        "Créez votre première liste de contrôle",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TEXT:
        "Les listes de contrôle AQ permettent de garantir un travail de haute qualité. Après avoir créé une liste de contrôle, elle peut être affectée à des activités dans l'éditeur de modèle de Flux de Travail. Ensuite, elle sera affichée lorsque le statut des activités sera modifié.",
    CHECKLIST_TEMPLATE_LIST_EMPTY_BUTTON:
        "Créez votre première liste de contrôle",
    CHECKLIST_TEMPLATE_LIST_NUMBER_OF_ITEMS: "Nombre d'articles",
    CHECKLIST_TEMPLATE_LIST_CREATION: "Créé",
    CREATED_BY:
        "Créé {{timestamp | amDateFormat:dateFormat}} par {{inspector}}.",
    CREATE_NEW_CHECKLIST_TEMPLATE_TITLE: "Créer une nouvelle liste de contrôle",
    CREATE_NEW_CHECKLIST_TEMPLATE_DESCRIPTION:
        "Tout d'abord, veuillez choisir un nom pour votre nouveau modèle de liste de contrôle:",
    CREATE_NEW_CHECKLIST_TEMPLATE_NAME_PLACEHOLDER:
        "Nom de la liste de contrôle",
    CREATE_NEW_CHECKLIST_TEMPLATE_CONFIRM: "Créer une liste de contrôle",
    CREATE_CHECKLIST_EDITOR_TITLE: "Éditeur de la liste de contrôle",
    CREATE_CHECKLIST_EDITOR_ITEMS: "Objets:",
    CREATE_CHECKLIST_EDITOR_ADD_NEW: "Ajouter un article",
    CREATE_CHECKLIST_EDITOR_ADD_NEW_PLACEHOLDER:
        "Ajouter un nouvel élément à votre liste de contrôle...",
    CREATE_CHECKLIST_EDITOR_SAVED:
        "La liste de contrôle a été sauvegardée avec succès.",

    CREATE_CHECKLIST_EDITOR_SETTINGS_LABEL: "Obligatoire pour",
    CREATE_CHECKLIST_EDITOR_SETTINGS_TOOLTIP:
        "Les éléments obligatoires de la liste de contrôle sont surlignés dans l'application mobile et ne peuvent pas être répondu  non coché  par l'équipe de confirmation.",

    CREATE_CHECKLIST_EDITOR_IS_MANDATORY: "Toutes les équipes",
    CREATE_CHECKLIST_EDITOR_IS_NOT_MANDATORY: "Aucune équipe",
    CREATE_CHECKLIST_EDITOR_IS_CONFIRM_MANDATORY:
        "Seulement l'équipe de confirmation",
    CREATE_CHECKLIST_EDITOR_IS_WORK_MANDATORY: "Seulement l'équipe responsable",

    CREATE_CHECKLIST_EDITOR_REQUIRE_IMAGE_LABEL: "Photo requise",

    SECTION_CHECKLISTS_TITLE: "Listes de contrôle d'AQ",
    CHECKLISTS_TAB_NUMBER_UPLOADED: "Listes de contrôle d'AQ téléchargées",
    CHECKLISTS_TAB_NUMBER_NON_STARTED:
        " Listas de controle de calidad no iniciado",
    CHECKLISTS_TAB_NUMBER_ATTACHED_TO_ACTIVITY: "Attaché à",
    CHECKLISTS_TAB_DONE_BY: "Terminé {{timestamp}} par {{inspector}}.",
    CHECKLISTS_TAB_NO_CHECKLISTS_FOR_FILTER:
        "Aucune activité avec des protocoles AQ liés n'a été attribuée.",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE_FOR_FILTER:
        "Il semble qu'aucune activité avec des protocoles AQ liés n'ait été attribuée à l'équipe pour cette séquence.",
    CHECKLISTS_TAB_NO_CHECKLISTS: "Aucun protocole AQ n'est lié",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE:
        "Les protocoles AQ permettent de vérifier une liste de points pour l'activité. Dans les modèles de Flux de Travail, les protocoles AQ peuvent être liés aux activités.",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED:
        "Aucun modèle de Flux de Travail attribué",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED_MESSAGE:
        "L'attribution de modèles de Flux de Travail vous permet de capturer un niveau de détail plus élevé pour vos livrables. et d'ajouter des listes de contrôle aux activités.",

    CHECKLISTS_DETAILS_RESPONSIBLE_TEAM: "Équipe responsable",
    CHECKLISTS_DETAILS_CONFIRMING_TEAM: "Équipe de confirmation",
    CHECKLISTS_DETAILS_REPORTED_BY: "Rapporté par:",
    CHECKLISTS_DETAILS_RESULT_Y: "Oui",
    CHECKLISTS_DETAILS_RESULT_N: "Non",
    CHECKLISTS_DETAILS_RESULT_B: "Non vérifié",
    CHECKLISTS_DETAILS_RESULT_NA: "N'est pas applicable",
    CHECKLISTS_DETAILS_RESULT_EMPTY: "",
    BACKGROUND_MENU_GLOBAL_SETTINGS: "Réglages",
    BACKGROUND_MENU_RELEVENT_ONLY: "Afficher uniquement mes domaines",
    TEAM_USERS:
        "Utilisateurs responsables des produits livrables non assignés à un gestionnaire responsable",
    TEAM_CONSTRUCTION_MANAGERS:
        "Gestionnaires responsables de produits livrables particuliers",
    TEAM_ADD_REPLACEMENT: "Définir utilisateur de remplacement",
    TEAM_REPLACEMENT_ACTIVE: "Activé",
    TEAM_REPLACEMENT_INACTIVE: "Désactivé",
    TEAM_REMOVE_REPLACEMENT: "Supprimer l'utilisateur de remplacement",
    TEAM_NO_EXISTING_REPLACEMENT: "Aucun utilisateur de remplacement existant",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TITLE:
        "Confirmer le déménagement du gestionnaire responsable",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TEXT:
        "Êtes-vous sûr de vouloir transférer ce Responsable dans une autre équipe? Si vous continuez, tous les produits livrables qui lui sont assignés ne seront pas assignés. Ceci ne peut être annulé qu'à l'aide de la page Produits livrables.",

    WEEK_BOARD_OPTIONS: "Options",
    WEEK_BOARD_CHOOSE_SIZE: "Montrer la conception d'une grande activité",
    WEEK_BOARD_SHOW_ACTUALS:
        "Afficher les dates réelles lorsqu'elles sont disponibles",
    WEEK_BOARD_SIZES_SMALL: "Petit",
    WEEK_BOARD_SIZES_LARGE: "Grand",
    WEEK_BOARD_PREVIOUS: "Page précédente",
    WEEK_BOARD_NEXT: "Page suivante",

    DATE_ORIGIN_ACTUAL: "(Prévue)",
    DATE_ORIGIN_PREDICTED: "@:DATE_ORIGIN_LOOK_AHEAD",
    DATE_ORIGIN_FORECASTED: "(Actual)",
    DATE_ORIGIN_LOOK_AHEAD: "(Court terme)",
    DATE_ORIGIN_BASELINE: "(Base)",

    ACTIVITY_DETAILS_BASELINE_DURATION: "Durée (Base)",
    ACTIVITY_DETAILS_STRUCTURE_DISPLAYED_DURATION: "Structure du projet Durée",
    ACTIVITY_DETAILS_MEAN_DURATION_LOOKAHEAD: "Durée moyenne (Court terme)",
    ACTIVITY_DETAILS_BASELINE_START: "Date de début  (Base)",
    ACTIVITY_DETAILS_BASELINE_DUE: "Date de fin  (Base)",
    ACTIVITY_DETAILS_ACTUAL_START: "Début effectif",
    ACTIVITY_DETAILS_ACTUAL_DONE: "Fin effective",
    ACTIVITY_DETAILS_ACTUAL_CONFIRMED: "Réel confirmé",
    ACTIVITY_DETAILS_LATEST_DURATION: "Durée (Court terme)",
    ACTIVITY_DETAILS_LATEST_START: "Date de début  (Court terme)",
    ACTIVITY_DETAILS_NEW_DURATION: "Nouvelle durée",
    ACTIVITY_DETAILS_DISPLAYED_DURATION: "Durée actuelle",
    ACTIVITY_DETAILS_NEW_START: "Nouvelle date de début",
    ACTIVITY_DETAILS_DISPLAYED_START: "Date de début actuelle",
    ACTIVITY_DETAILS_NEW_DUE: "Nouvelle date de fin",
    ACTIVITY_DETAILS_DISPLAYED_DUE: "Date de fin actuelle",
    ACTIVITY_DETAILS_LATEST_DUE: "Date de fin  (Court terme)",
    ACTIVITY_DETAILS_NUMBER_OF_CHANGES: "Nombre de replanifications",
    ACTIVITY_DETAILS_FORECAST_START: "Date de début prévue",
    ACTIVITY_DETAILS_FORECAST_DUE: "Date de fin prévue",
    ACTIVITY_DETAILS_ACCEPT_FORECAST: "Utiliser comme date d'anticipation",
    ACTIVITY_DETAILS_FORECAST_START_TOOLTIP:
        "Utilisez la date prévue comme nouvelle date de début de la période Perspectives d'avenir. Par la suite, vous devrez toujours enregistrer et publier vos modifications au projet.",
    ACTIVITY_DETAILS_FORECAST_DUE_TOOLTIP:
        "Utilisez la date prévue comme nouvelle date de fin de la période Regard vers l'avenir. Par la suite, vous devrez toujours enregistrer et publier vos modifications au projet.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES:
        "Planifier toutes les activités suivantes en fonction de vos changements et des dépendances définies.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES_TOOLTIP:
        "Lorsque cette case est cochée, Sablono planifie automatiquement toutes les activités qui succèdent à celle-ci en fonction des changements que vous avez définis. Veuillez noter que cela entraînera également des dates prévisionnelles pour toutes les activités suivantes.",
    ACTIVITY_DETAILS_RESPONSIBLE_TEAM: "Équipe responsable",
    ACTIVITY_DETAILS_PLANNED_LABOUR: "Main-d'œuvre prévue",
    ACTIVITY_DETAILS_CONFIRMING_TEAM: "Équipe de confirmation",
    ACTIVITY_DETAILS_DELIVERABLE_CODE: "Code de livrable",
    ACTIVITY_DETAILS_DELIVERABLE_NAME: "Nom du produit livrable",
    ACTIVITY_DETAILS_PROJECT_STRUCTURE: "Structure du projet",
    ACTIVITY_DETAILS_NUMBER_OF_DELIVERABLES: "Les livrables comprennent",
    ACTIVITY_DETAILS_NUMBER_OF_FORECASTED_ACTIVITIES: "prévisionnel",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY: "Productivité planifiée",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY_UNIT: "Livrables par jour",
    ACTIVITY_DETAILS_LAST_CHECKED_BY: "Dernière mise à jour par",
    ACTIVITY_DETAILS_LAST_CHECKED_ON: "Dernière mise à jour le",
    ACTIVITY_DETAILS_CHANGE_DATE:
        "Sélectionnez Regarder vers l'avenir Date de fin",
    ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED:
        "Aucun examen séparé n'est demandé",
    ACTIVITY_DETAILS_START_SESSION: "Démarrer la session",
    ACTIVITY_DETAILS_CHANGE_DATES_OF_ACTIVITIES: "Modifier les dates",
    ACTIVITY_DETAILS_HAS_CHANGED_THIS_SESSION:
        "Cette date de fin de la période Look Ahead a été modifiée au cours de la session en cours d'exécution.",
    ACTIVITY_DETAILS_SAVED_IN_SESSION:
        "Sauvegarde de la session de planification",
    ACTIVITY_DETAILS_BULK_LOOK_AHEAD_SAVED:
        "{{changed}} de {{all}} dates de Look-Ahead ont été modifiées.",
    FILTER_MENU: "Filtres: {{activeFilterCount}} actuellement actif",
    FILTER_MENU_SEARCH_PLACEHOLDER: "Recherche dans {{items}} éléments...",
    ACTION_DOWNLOAD_WEEKPLAN: "Imprimer",
    SESSION_MENU_BULK_CHANGES_CANCEL: "Accompli",
    SESSION_MENU_BULK_CHANGES_START: "Déplacer plusieurs activités",
    SESSION_MENU_BULK_CHANGES_APPLY: "Modifier les dates sélectionnées",
    SESSION_MENU_BULK_CHANGES_ACTIVITIES:
        "{{activitiesNum}} activités sélectionnées.",
    SESSION_MENU_START: "Début de la séance de planning Look-ahead",
    SESSION_MENU_SAVE_AND_RELEASE: "Publier",
    SESSION_MENU_CANCEL: "Rejeter",
    SESSION_MENU_COMMIT_TO_ALL_FORECASTED_DATES:
        "Utilisez toutes les dates prévues comme nouveau calendrier de prévisions.",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_TITLE:
        "Séance de planification sur le plan de la semaine en cours...",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_MESSAGE:
        "Les utilisateurs suivants n'ont pas encore publié leurs modifications Look Ahead: {{users}}. Veuillez vous assurer que les dates Look Ahead sont sauvegardées ou supprimées par l'utilisateur qui les a créées avant de continuer à travailler sur la planification du projet.",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_TITLE:
        "Utilisez toutes les dates prévues comme nouveau calendrier de prévisions.",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_CONTENT:
        "Êtes-vous sûr de vouloir utiliser toutes les dates prévues comme nouvelles dates du calendrier Look-ahead? Veuillez noter que ceci inclura également les produits livrables que vous ne verrez peut-être pas à l'écran actuellement (par exemple, parce qu'ils ne correspondent pas à vos critères de filtrage).",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE:
        "Voulez-vous vraiment prévoir tous les livrables?",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE_CONTENT:
        "Prévoir tout inclura tous les livrables correspondant à vos filtres actuels. Veuillez noter qu'ils peuvent également être affichés sur les pages précédentes/suivantes. Voulez-vous continuer?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_TITLE:
        "Voulez-vous vraiment utiliser toutes les dates prévues comme nouveau planning Look Ahead?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_CONTENT:
        "Accepter tout inclura tous les livrables prévus, même s'ils ne correspondent pas à vos filtres actuels ou s'ils sont affichés sur des pages précédentes/suivantes. Souhaitez-vous poursuivre?",

    WORKFLOW_ACTIVITY_DETAIL_DELETE_CUSTOM_DATE:
        "Supprimer les dates définies par l'utilisateur",
    WORKFLOW_ACTIVITY_DETAIL_QA_CHECKLIST: "Liste de contrôle ci-jointe",
    WORKFLOW_ACTIVITY_DETAIL_LOOK_AHEAD_DURATION: "Durée (Court terme)",
    WORKFLOW_ACTIVITY_BASELINE_DURATION: "Durée (Base)",
    WORKFLOW_GROUP_ACTIVITIES_COMPLETED: "Act. terminées",
    ACTIVITY_DETAILS_REVIEW_TEAMS: "Équipe de inspection",

    WORKFLOW_BULK_LOOK_AHEAD_MULTI:
        "ALT + cliquez pour sélectionner un niveau de structure de projet",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_TITLE: "Calculer votre horaire...",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_PROGRESS:
        "Nous devons encore reprogrammer les {{items}} activités.",
    WORKFLOW_BULK_LOOK_AHEAD_DIALOG_TITLE:
        "Modifier les dates d'anticipation pour la sélection",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_TITLE:
        "Une autre activité déjà sélectionnée...",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_MESSAGE:
        "Il semble que vous ayez déjà choisi une autre activité du même livrable. Si vous voulez sélectionner celui-ci, veuillez désélectionner celui que vous avez sélectionné précédemment.",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_TITLE:
        "Activité déjà terminée...",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_MESSAGE:
        "Pour les activités qui ont déjà été terminées, les dates de Look Ahead ne peuvent plus être modifiées. Vous verrez leurs dates de début et de fin réelles en regardant le plan de la Planification Lean.",
    SITE_DIARY_TEAMS_CARD_TITLE_LEFT: "Équipes",
    SITE_DIARY_TEAMS_CARD_TITLE_MANAGERS: "Gestionnaires",
    SITE_DIARY_TEAMS_CARD_TITLE_FOREMEN: "Contremaîtres",
    SITE_DIARY_TEAMS_CARD_TITLE_WORKERS: "Travailleurs",
    SITE_DIARY_TEAMS_CARD_TITLE_OTHER: "Autre",
    SITE_DIARY_TEAMS_CARD_TITLE_TOTAL: "Total",
    SITE_DIARY_PROGRESS_CARD_TITLE: "Progrès réalisés",
    SITE_DIARY_PROGRESS_CARD_INFORMATION:
        "Dernière mise à jour {{createdAt}}. Les informations présentées peuvent être rafraîchies jusqu'à ce que le journal soit finalisé.",
    SITE_DIARY_PROGRESS_CARD_TOOLTIP:
        "Cette activité a été lancée le {{dateStarted}}.",
    SITE_DIARY_MACHINES_CARD_TITLE: "Machines",
    SITE_DIARY_MACHINES_CARD_PLACEHOLDER:
        "Saisissez quelles machines et combien ont été utilisées le jour sélectionné.",
    SITE_DIARY_EVENTS_CARD_TITLE: "Événements spéciaux",
    SITE_DIARY_EVENTS_CARD_PLACEHOLDER:
        "Saisissez tous les événements spéciaux ou autres notes que vous souhaitez documenter pour le jour sélectionné.",
    ACTION_CHOOSE_A_DATE: "Choisir la date",
    ACTION_FINALISE_ENTRY: "Finaliser l'entrée",
    SITE_DIARY_CALENDAR_TODAY: "Aujourd'hui",
    SITE_DIARY_CALENDAR_FINALISED: "Finalisé",
    SITE_DIARY_CALENDAR_FINALISED_BY: "Finalisé par {{finaliser}}.",
    SITE_DIARY_INFO_CARD_LOW: "Plus bas",
    SITE_DIARY_INFO_CARD_HIGH: "Plus haut",
    SITE_DIARY_INFO_CARD_CONDITIONS: "Conditions météorologiques",
    SITE_DIARY_INFO_CARD_DEPARTMENT: "Propriétaire du bâtiment",
    SITE_DIARY_INFO_CARD_TEMPERATURE_UNIT: "&deg;C",
    SITE_DIARY_CARD_INPUT_ERROR_MIN_MESSAGE:
        "Veuillez sélectionner un nombre ci-dessus {{value}}.",
    SITE_DIARY_CARD_INPUT_ERROR_MAX_MESSAGE:
        "Veuillez sélectionner un nombre ci-dessous {{value}}.",
    SITE_DIARY_PROGRESS_REPORTED_BY: "Mis à jour par {{inspector}}.",
    SITE_DIARY_PROGRESS_EMPTY:
        "Il semble qu'aucun progrès n'ait été signalé et qu'aucune note n'ait été ouverte ou fermée le jour choisi...",
    SITE_DIARY_NOTE_CREATED_BY: "Mis à jour par {{inspector}}.",
    SITE_DIARY_CONFIRM_LEAVE_TITLE: "Modifications non sauvegardées",
    SITE_DIARY_CONFIRM_LEAVE_TEXT:
        "Il semble que vous ayez apporté des modifications à ce journal quotidien qui n'ont pas encore été sauvegardées. Êtes-vous sûr de vouloir quitter cette page sans les sauvegarder d'abord?",
    SITE_DIARY_SAVE_SUCCESS: "Journal de bord quotidien mis à jour avec succès",
    SITE_DIARY_PAGINATION_SELECTION: "Page {{currentPage}} de {{TotalPages}}.",
    SITE_DIARY_SHOW_DETAILS: "Afficher les détails",
    SITE_DIARY_HIDE_DETAILS: "Masquer les détails",
    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TITLE: "Etes-vous confiant?",
    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TEXT:
        "Après avoir finalisé ce journal quotidien, vous ou vos collègues ne pourrez plus le modifier. Voulez-vous continuer?",
    COST_GROUP_ENTER_SEARCH_TERM: "Recherche de groupes de coûts...",
    COST_GROUP_SEARCH_EMPTY:
        "Aucun groupe de coûts ne correspond à vos critères.",
    COST_GROUP_CONFIRM_DELETE_TITLE: "Etes-vous confiant?",
    COST_GROUP_CONFIRM_DELETE_BODY:
        "Etes-vous confiant? Ceci ne peut pas être défait.",
    COST_GROUP_FORECASTED_VALUES_HINT:
        "Vous avez sélectionné une date ultérieure. Les chiffres du tableau de bord commercial sont prévus en fonction de l'emplacement du projet à la date que vous avez choisie.",
    COST_GROUP_CSV_COLUMN_WORK_PACKAGE: "Paquet de travail",
    COST_GROUP_CSV_COLUMN_BUDGET_SHARE: "% du montant du contrat",
    COST_GROUP_CSV_COLUMN_BUDGET_ALLOCATED: "Montant du contrat alloué",
    COST_GROUP_CSV_COLUMN_STARTED: "Montant du contrat en cours {{currency}}.",
    COST_GROUP_CSV_COLUMN_PERCENT_STARTED: "% d'activités en cours",
    COST_GROUP_CSV_COLUMN_TOTAL_STARTED: "Nombre d'activités en cours",
    COST_GROUP_CSV_COLUMN_WAITING_FOR_CONFIRMATION:
        "Montant du contrat en attente de signature {{currency}}.",
    COST_GROUP_CSV_COLUMN_PERCENT_WAITING_FOR_CONFIRMATION:
        "d'activités en attente d'approbation",
    COST_GROUP_CSV_COLUMN_TOTAL_WAITING_FOR_CONFIRMATION:
        "Nombre d'activités en attente d'approbation",
    COST_GROUP_CSV_COLUMN_FINISHED_AND_CONFIRMED:
        "Montant du contrat signé {{currency}}.",
    COST_GROUP_CSV_COLUMN_PERCENT_FINISHED_AND_CONFIRMED:
        "% d'activités approuvées",
    COST_GROUP_CSV_COLUMN_TOTAL_FINISHED_AND_CONFIRMED:
        "Nombre d'activités approuvées",
    COST_GROUP_CSV_COLUMN_PERCENT_COST_GROUP_BUDGET_SPENT: "% du coût terminé",
    COST_GROUP_CSV_COLUMN_DATE_OF_GENERATION: "Rapport exporté le",
    COST_GROUP_CSV_COLUMN_FIGURES_UP_TO_DATE: "Incl. mises à jour jusqu'à",
    ACTIVITIES_LIST_SELECTOR_HEADER_ACTIVITY: "Activité",
    ACTIVITIES_LIST_SELECTOR_HEADER_TEAM: "Équipe responsable",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_TEAM:
        "Équipe responsable standard",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_CONFIRMING_TEAM:
        "Équipe de confirmation standard",
    ACTIVITIES_LIST_SELECTOR_HEADER_CONFIRMATION_TEAM:
        "@:CHECKLISTS_DETAILS_CONFIRMING_TEAM",
    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Recherche d'activités par modèle de Flux de Travail, nom et équipe...",
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS___TITLE:
        "Équipes d'inspection requises",
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS__MESSAGE:
        "Cette activité doit être contrôlée par des équipes d'inspection, ce qui n'est possible qu'avec l'application mobile Sablono. Veuillez l'utiliser pour signaler les progrès réalisés.",

    LEGEND_STAGE_MODE_DEFAULTS: "Autres",
    MAKING_DECISION_LOCAL_DATA_HEADER:
        "Modèle de Flux de Travail sauvegardé localement",
    MAKING_DECISION_LOCAL_DATA_MESSAGE:
        "Vous êtes en train d'ouvrir une version de votre modèle de Flux de Travail qui n'a été enregistrée localement sur cet appareil qu'il y a {{timeAgo}}. Vous souhaitez continuer à travailler dessus ou télécharger le dernier modèle du serveur Sablono?",
    DISCARD_AND_DOWNLOAD_NEW_VERSION:
        "Jeter et télécharger la nouvelle version",

    DELETE_PROJECT_DIALOG_WARN_USER:
        "Voulez-vous vraiment supprimer ce projet? Cela supprimera toutes les informations connexes de tous les systèmes Sablono.",
    DELETE_PROJECT_DIALOG_WARN_USER_INFO:
        "Attention: Cette action NE PEUT PAS être annulée.",
    DELETE_PROJECT_DIALOG_WARN_USER_NEXT: "Procéder",
    DELETE_PROJECT_DIALOG_WARN_USER_ALT: "Demande d'exportation de données",
    DELETE_PROJECT_DIALOG_PASSWORD:
        "Veuillez vérifier votre mot de passe Sablono pour pouvoir supprimer irrémédiablement ce projet.",
    DELETE_PROJECT_DIALOG_WRONG_PASSWORD:
        "Votre mot de passe n'est pas valide.",
    DELETE_PROJECT_DIALOG_COPY_NAME_TO_DELETE:
        "Enfin, veuillez copier le nom de votre projet <b>{{projectName}}</b> pour confirmer que vous voulez vraiment le supprimer définitivement du serveur Sablono. Attention: Cette action NE PEUT PAS être annulée.",
    DELETE_PROJECT_DIALOG_REPEAT_PROJECT_NAME_PLACEHOLDER:
        "Saisissez le nom du projet pour confirmation",
    _ERASE: "Supprimer",

    ARCHIVE_PROJECT: "Archiver le projet",
    ARCHIVE_PROJECT_CONFIRMATION:
        "Vous voulez vraiment archiver ce projet? Ensuite, aucun utilisateur n'y aura plus accès, mais il sera tout de même enregistré sur le serveur Sablono et pourrait être réactivé à l'avenir.",

    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL_SUBJECT:
        "Demande d'exportation de données",
    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL:
        "Salut le soutien de Sablono,%0D%0A%0D%0A" +
        "Je souhaite supprimer le projet (ID: {{projectId}}) mais je reçois au préalable une exportation des données. Veuillez me contacter dès que possible et m'informer des prochaines étapes.%0D%0A" +
        "Veuillez agréer, Monsieur, l'expression de mes sentiments distingués,%0D%0A" +
        "{{username}}",

    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER_WITHOUT_PROCESS_TEMPLATE:
        "Recherche d'activités par modèle de nom et équipe...",

    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_TITLE:
        "Souhaitez-vous poursuivre?",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_MESSAGE:
        "Si vous souhaitez remplacer le modèle de Flux de Travail sur des livrables déjà démarrés, veuillez cocher la case ci-dessous. Cette action ne peut être annulée que par le support Sablono.",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_CHECKBOX_MESSAGE:
        "Oui, je souhaite écraser les données d'avancement existantes",
    ERR_FR_MAX_BODY_LENGTH_EXCEEDED:
        "Il semble que la quantité de listes de contrôle d'assurance qualité que vous essayez d'exporter dépasse la limite de notre serveur. Veuillez essayer à nouveau en sélectionnant un nombre plus petit. Si le problème persiste, veuillez contacter notre service d'assistance.",

    FILTER_BY_PROCESS_TEMPLATES_TITLE: "Filtrer par modèle de Flux de Travail",
    FILTER_BY_PROCESS_TEMPLATES_ALL_TEMPLATES: "Tous les Flux de Travail",

    FILTER_BY_DELIVERABLE_TYPE_TITLE: "Filtrer par type de livrable",
    FILTER_BY_DELIVERABLE_TYPE_ALL_TEMPLATES:
        "Tous les types de produits livrables",

    DASHBOARD_PROJECT_BAR_CHART_TITLE: "Toutes les activités",
    SECTION_PROJECT_OVERVIEW_TITLE: "Aperçu du projet",
    ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE: "Aperçu du projet",

    PROJECT_OVERVIEW_PROCESS_TEMPLATES_TITLE: "Flux de Travail",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_TITLE: "Types de produits livrables",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_TITLE:
        "Créer des types de produits livrables",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_MESSAGE:
        "Une fois que vous avez classé vos produits livrables par types, vous trouverez ici un résumé par type.",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_ACTION: "@:GO_TO_DELIVERABLES",

    DASHBOARD_BAR_CHART_TOTAL_LABEL: "Total:",
    DASHBOARD_BAR_CHART_TOTAL_TEXT: "@:_ACTIVITIES_N",
    _DELIVERABLE_TYPE: "Type de livrable",

    REMOVE_DELIVERABLE_TYPE: "Supprimer le type de livrable",
    DIALOG_UNASSIGNED_TYPE_ACTION: "Supprimer",

    //    TUTORIAL LINKS

    TUTORIAL_BAR_CHART:
        "http://support.sablono.com/en/articles/4828856-bar-chart-planned-vs-actual-completion-of-work",
    TUTORIAL_S_CURVE:
        "http://support.sablono.com/en/articles/4828855-s-curve-planned-vs-actual-completion-of-work",
    TUTORIAL_PRODUCTIVITY_CHART:
        "https://support.sablono.com/en/articles/8704401-productivity-chart-planned-vs-actual-by-week",
    TUTORIAL_DELIVERABLE_TYPES:
        "http://support.sablono.com/en/articles/4838516-how-to-categorize-your-deliverables-by-types",
    TUTORIAL_IMPORT_PROGRESS:
        "http://support.sablono.com/en/articles/5393783-how-to-import-progress-data-from-microsoft-excel",

    DELIVERABLE_LINK_TO_MOBILE_LABEL: "Ouvrir dans l'application",
    DELIVERABLE_LINK_TO_MOBILE: "Cliquez ici",
    ERROR_OPEN_DEEP_LINK_TO_NATIVE_APP_MESSAGE:
        "Veuillez vous assurer que l'application Sablono est installée et disponible pour l'ouvrir",
    DELIVERABLE_VIEW: "Livrables",
    STRUCTURE_VIEW: "Structure",

    PAGINATION_FETCH_FROM_SOURCE_ERROR_MESSAGE:
        "Échec du chargement de données supplémentaires en arrière-plan. Les informations présentées dans la vue actuelle peuvent ne pas être complètes.",
    UPLOAD_SVG_FILE:
        "Votre fichier est en train d'être téléchargé - Cela peut prendre quelques instants ...",

    TIME_RANGE_SELECTOR_TITLE:
        "Choisissez la plage de temps à afficher sur la page de planification de Lean",
    TIME_RANGE_SELECTOR_FROM: "Date de début",
    TIME_RANGE_SELECTOR_TO: "Date de fin",
    TIME_RANGE_SELECTOR_EXTRA: "Intervalles de temps prédéfinis",
    TIME_RANGE_SELECTOR_CURRENTLY_SELECTED: "Actuellement sélectionné",
    TIME_RANGE_SELECTOR_START: "Début",
    TIME_RANGE_SELECTOR_END: "Fin",
    ERROR_MAXIMUM_DATE_RANGE_EXCEEDED:
        "Veuillez sélectionner une plage de temps inférieure à un an",

    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_TITLE: "Changer les équipes",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SELECT:
        "Veuillez sélectionner les activités des livrables sélectionnés précédemment pour lesquelles vous souhaitez changer les équipes prédéfinies de votre modèle de Flux de Travail:",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SUBMIT: "Changer les équipes",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_DROPDOWN_DESCRIPTION:
        "Pour quelle équipe souhaitez-vous changer le standard ?",

    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_TITLE:
        "Supprimer les équipes définies par l'utilisateur",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SELECT:
        "Veuillez sélectionner les activités des livrables sélectionnés précédemment pour lesquelles vous souhaitez supprimer les équipes définies par l'utilisateur et utiliser le standard défini dans votre modèle de Flux de Travail à la place:",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SUBMIT:
        "Utiliser les équipes standard",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_DROPDOWN_DESCRIPTION:
        "Pour quelle équipe souhaitez-vous utiliser à nouveau le standard?",

    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_RESPONSIBLE:
        "@:ACTIVITY_DETAILS_RESPONSIBLE_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_CONFIRMATION:
        "@:ACTIVITY_DETAILS_CONFIRMING_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_BOTH: "Les deux",
    DIALOG_ACTIVITY_SET_SUCCESS_TEXT:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Équipes modifiées avec succès.",

    SECTION_EXCEL_IMPORT_PROGRESS_TEXT:
        "Veuillez vous assurer que votre Excel a le format suivant avant de l'importer :<br>" +
        "Code du livrable - Nom de l'activité - Statut - Horodatage - Format de la date - ID du projet<br>" +
        "Veuillez noter que vous ne pourrez mettre à jour que les activités dont votre équipe est responsable.",

    SECTION_EXCEL_IMPORT_PROGRESS_TITLE: "@:SECTION_EXCEL_IMPORT_TITLE",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TITLE:
        "Les mises à jour d'état ont été importées avec succès",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TEXT:
        "{{numberOfUpdates}} Les mises à jour de statut ont été importées dans votre projet.",
    SECTION_EXCEL_IMPORT_UPLOADED_WITH_FAILURES:
        "Malheureusement, certaines de vos mises à jour de statut n'ont pas pu être importées dans votre projet:",

    CS_REVERT_CONFIRMATION_ACTION: "Demande: Rétablir la confirmation",
    CS_RESET_ACTIVITY_DIALOG_HEADER: "Demande: Réinitialisation de l'activité",
    CS_REVERT_CONFIRMATION_DIALOG_HEADER: "Demande: Rétablir la confirmation",
    CS_RESET_ACTIVITY_DIALOG_CONTENT:
        "Si vous souhaitez réinitialiser cette activité qui inclut la suppression de toutes les données connectées, veuillez copier le texte suivant et le coller dans un message à votre support Sablono:",
    CS_REVERT_CONFIRMATION_DIALOG_CONTENT:
        "Si vous souhaitez annuler l'étape de confirmation de cette activité, y compris toutes les données connectées, veuillez copier le texte suivant et le coller dans un message à votre support Sablono:",

    IMPORT_PROGRESS_SUCCESS: "Activité mise à jour avec succès",
    IMPORT_SUCCESS: "Activité mise à jour avec succès",
    IMPORT_PROGRESS_CHECKLIST_REQUIRED:
        "Une liste de contrôle doit être remplie lors de la mise à jour du statut de l'activité",
    IMPORT_PROGRESS_NOT_ALLOWED_TRANSITION:
        "Les Status Updates fournis entrent en conflit avec la logique métier de Sablono",
    IMPORT_PROGRESS_ALREADY_UPDATED:
        "La mise à jour de statut fournie existe déjà",
    IMPORT_ACTIVITY_DUPLICATE_NAME:
        "Le nom de l'activité fourni existe deux fois dans le livrable concerné",
    IMPORT_PROGRESS_NOT_RESPONSIBLE_TEAM:
        "Votre équipe n'est pas responsable de l'activité",
    IMPORT_PROGRESS_TIMESTAMP_IN_THE_FUTURE:
        "L'horodatage de la mise à jour de statut fournie se situe dans le futur",
    IMPORT_ACTIVITY_NOT_FOUND:
        "Le nom d'activité fourni n'existe pas dans le livrable concerné.",
    IMPORT_USER_DEFINED_DATES_ONLY_ONE_DATE_IS_ALLOWED:
        "Une seule des dates de début ou de fin doit être fournie",
    IMPORT_USER_DEFINED_DATES_NO_DATE_AND_DURATION_GIVEN:
        "La date de début ou de fin ou la durée doit être fournie",
    IMPORT_USER_DEFINED_DATES_DELIVERABLE_IS_LOCKED:
        "Le livrable est actuellement verrouillé en session de planification",
    IMPORT_USER_DEFINED_DATES_INVALID_UNIT_OF_WORK:
        "Unité de travail incorrecte",
    IMPORT_PROGRESS_WRONG_PROJECT:
        "L'ID de projet fourni ne correspond pas au projet actuellement ouvert",
    ERROR_PROGRESS_ENTRIES_LIMITATION:
        "Vous pouvez importer jusqu'à 500 mises à jour de statut en une seule fois. Si vous souhaitez en importer plus, veuillez diviser le fichier en plusieurs et les importer les uns après les autres.",
    ERROR_DATES_ENTRIES_LIMITATION:
        "Vous pouvez importer jusqu'à 500 mises à jour en une seule fois. Si vous souhaitez en importer plus, veuillez diviser le fichier en plusieurs et les importer les uns après les autres.",
    ERROR_PDF_CREATION_MESSAGE:
        "Le fichier PDF demandé est devenu trop volumineux pour être créé. Veuillez réduire l'ensemble des données incluses et réessayer.",
    ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "La taille des données demandées a dépassé les limites. Veuillez réduire l'ensemble des données incluses et réessayer.",
    ERROR_NOTE_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "Le rapport demandé dépasse notre limite d'exportation de {{sizeLimitInMb}} Mo. Votre demande comprend {{numberOfNotes}} notes, y compris {{numberOfImagesIncluded}} photos ({{sizeEstimateInMbOfImages}} Mo). Veuillez réduire la taille de votre demande d'exportation à l'aide des filtres. Si vous continuez à voir ce message, veuillez contacter notre service d'assistance pour discuter des options possibles.",
    INTERNAL_SERVER_ERROR: "@:ERROR_UNKNOWN_MESSAGE",
    FORBIDDEN:
        "Vous n'avez pas les permissions suffisantes pour exécuter cette action. Veuillez contacter votre administrateur de projet pour, par exemple, changer votre rôle.",

    EULA_PROMPT_TITLE:
        "Nous avons mis à jour notre End User Licence Agreement (EULA) et Data and Privacy Policy",
    EULA_PROMPT_CONTENT:
        "Nous souhaitons vous informer que nous avons adapté notre EULA et Data and Privacy Policy.<br><br>Vous trouverez ici la version actuelle du contrat de <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a>.<br><br>.Le texte de la Data and Privacy Policy se trouve à l'adresse <a href='https://www.sablono.com/privacy' target='_blank'>https://www.sablono.com/privacy</a>.<br><br>Vous n'avez rien d'autre à faire. Si vous souhaitez formuler une objection, veuillez le faire dans les 30 jours en envoyant un courriel à <a href='mailto:support@sablono.com'>support@sablono.com</a>.<br><br>Votre équipe Sablono",
    EULA_CONFIRM_READ_TEXT: "J'ai lu et compris le EULA.",
    EULA_PROMPT_ACTION_ACCEPT: "Ok",
    EULA_PROMPT_ACTION_READ: "Ouvrir le EULA en PDF",
    EULA_PROMPT_ACTION_SKIP: "Rappelez-moi plus tard",
    EULA_LINK:
        "https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf",
    EULA_REVIEW_EULA: "Consultez le EULA",
    PRIVACY_POLICY: "Politique de données et de confidentialité",
    PRIVACY_POLICY_URL:
        "https://www.sablono.com/legal/data-and-privacy-policy?hsLang=en",

    SECTION_TOOLS_DIALOG_USAGE_NUMBER: "({{active}} Livrables actif)",

    IMPORT_DELIVERABLE_EXCEL_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967630-how-to-import-a-microsoft-excel-project",
    IMPORT_ASTA_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967630-how-to-import-a-microsoft-excel-project",
    CREATE_VISUAL_TRACKER_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2911811-how-to-create-visual-trackers-on-sablono",
    VISUAL_TRACKER_SVG_UPLOAD_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/5461453-how-to-create-visual-trackers-on-inkscape",
    GO_TO_TUTORIAL_ACTION: "Aller au tutoriel",
    COST_GROUP_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/4828779-how-can-i-set-up-a-cost-group",
    PROCESS_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967824-how-to-create-a-process-template",
    QA_CHECKLIST_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2904883-what-are-qa-checklists-and-how-do-i-use-them-in-my-project",
    NOTE_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967699-how-to-create-a-note-template",
    NOTES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/5433250-how-to-add-notes-from-the-web-platform",
    NOTES_EMPTY_STATE_HEADER: "Déclarez vos premières notes",
    NOTES_EMPTY_STATE_CONTENT:
        "Sur Sablono, vous pouvez créer des notes sur vos livrables, vos activités et vos éléments d'assurance qualité. Lisez le tutoriel ci-dessous pour apprendre à rapporter vos premières notes.",
    LEGEND_LINE_DUE_TODAY: "A remettre aujourd'hui",
    LEGEND_LINE_DUE_THIS_WEEK: "A remettre avant la fin de cette semaine",
    LEGEND_LINE_DUE_NEXT_WEEK: "Pour la fin de la semaine prochaine",

    MAIN_CONTRACTOR: "Entrepreneur principal",
    SUBCONTRACTOR: "Sous-traitant",
    POD_SUPPLIER: "Fournisseur d'une chambre à coucher rénovée",
    HOUSE_BUILDER: "Constructeur de maison",
    ARCHITECT: "Architecte",
    DEVELOPER: "Promoteur",
    HOUSING_ASSOCIATION: "Association de logement",
    MANUFACTURER: "Fabricant",
    CONSULTANT: "Consultant",
    OTHER: "Autre",
    CONSTRUCTION_MANAGER: "Directeur de la construction",
    CONSTRUCTION_DIRECTOR: "Directeur des travaux",
    PROJECT_OR_PACKAGE_MANAGER: "Directeur de projet / de paquet",
    COMMERCIAL_DIRECTOR: "Directeur commercial",
    COMMERCIAL_MANAGER: "Chef de chantier commercial",
    PLANNER: "Planificateur",
    PROJECT_DIRECTOR: "Directeur de projet",
    BOARD_DIRECTOR: "Directeur de conseil",
    CONSTRUCTION_OPERATIVE: "Opérateur de construction",
    SUB_CONTRACTOR_MANAGER: "Directeur de sous-traitance",
    CENTRAL_BUSINESS_OR_OFFSITE_MANAGEMENT: "Entreprise centrale",
    THIRD_PARTY_INSPECTOR: "Inspecteur tiers",
    SECTION_REGISTRATION_JOB_TITLE: "Titre du poste",
    SECTION_REGISTRATION_COMPANY_TYPE: "Type d'entreprise",

    IMAGE_OVERLAY_INFO_TITLE_LOCATION: "Ubicación",
    IMAGE_OVERLAY_INFO_TITLE_ALTITUDE: "Altitude",
    IMAGE_OVERLAY_INFO_TITLE_LONGITUDE: "Longitude",
    IMAGE_OVERLAY_INFO_TITLE_LATITUDE: "Latitude",
    SIGNUP_NEW_TEXTS_WITH_MARKETING_CONSENT:
        "En vous inscrivant à Sablono, vous acceptez notre contrat de <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> et notre <a href='https://www.sablono.com/privacy' target='_blank'>politique de confidentialité et de protection des données,</a> y compris le transfert vers des pays tiers. Sablono vous fournira des informations pertinentes sur ses produits. Vous pouvez vous désabonner de ces communications à tout moment.",
    SIGNUP_NEW_TEXTS_WITHOUT_MARKETING_CONSENT:
        "En vous inscrivant à Sablono, vous acceptez notre contrat de <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> et notre <a href='https://www.sablono.com/privacy' target='_blank'>politique de confidentialité et de protection des données,</a> politique de confidentialité et de protection des données, y compris le transfert vers des pays tiers.",
    ACTION_ADD_COMMENT: "Ajouter des commentaires et des photos",
    ADD_NOTE_COMMENT_DIALOG_TITLE:
        "Ajouter des commentaires et des photos à la note",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TITLE: "État de l'émission du rapport",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TEXT:
        "Vous souhaitez compléter le changement de statut par un commentaire ou une photo?",
    ADD_NOTE_COMMENT_DESCRIPTION_TITLE:
        "Saisir un texte décrivant le commentaire de la note",

    RESET_ACTIVITY_ACTION:
        "Supprimer toutes les données relatives à l'avancement et à la qualité",
    REQUEST_ACTIVITY_UPDATE:
        "Demander à un collègue de mettre à jour l'état d'avancement des travaux",
    REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT:
        "{{projectName}} - Veuillez mettre à jour le statut de l'activité ci-jointe sur Sablono",
    REQUEST_ACTIVITY_UPDATE_EMAIL_BODY:
        "Bonjour! \n\n Veuillez mettre à jour le statut de l'activité suivante sur Sablono pour moi : \n * {{deliverableCode}} - {{activityName}} \n * https://app.sablono.com/{{deliverableUrl}} \n\n Vous pouvez soit cliquer sur le lien pour ouvrir l'activité sur votre application mobile Sablono, soit vous connecter sur le web pour la mettre à jour. \n\n Merci \n {{userName}}",
    RESET_ACTIVITY_TITLE: "Réinitialiser l'activité",
    RESET_ACTIVITY_CONFIRMATION: "Pour continuer, entrez le mot RESET",
    RESET_ACTIVITY_DESCRIPTION:
        "Voulez-vous vraiment réinitialiser cette activité <b>à \"non commencée\"</b>?\n\nSi vous procédez ainsi, les rapports d'avancement, les dates réelles, les notes connectées et les listes de contrôle d'assurance qualité seront supprimés. Cette opération ne peut être annulée qu'avec le support de Sablono.",
    BULK_RESET_ACTIVITIES_TITLE: "Réinitialiser les activités à non commencé",
    BULK_RESET_ACTIVITIES_DESCRIPTION:
        "Voulez-vous vraiment réinitialiser les activités sélectionnées <b>à \"non commencé\"</b>?\n\nSi vous procédez ainsi, les rapports d'avancement, les dates réelles, les notes connectées et les listes de contrôle de qualité seront supprimés. Cette opération ne peut être annulée qu'avec le support de Sablono.",
    BULK_RESET_ACTIVITIES_HEADER:
        "Veuillez sélectionner les activités des livrables précédemment sélectionnés que vous souhaitez réinitialiser. Cela inclut les rapports d'avancement, les dates réelles, les notes connectées et les listes de contrôle de qualité.",
    BULK_RESET_ACTIVITIES_SUBMIT: "Réinitialiser les activités",
    BULK_RESET_ACTIVITIES_TOAST:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Réinitialisation des activités",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TITLE:
        "Exportation de vos notes dans un rapport PDF",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TEXT:
        "Une fois que nous avons créé votre rapport PDF, nous l'envoyons par courrier électronique à {{email}}. Veuillez noter que cela peut prendre un certain temps en fonction du nombre de photos incluses dans le rapport.",
    SEND_REPORT_TO_ADDITIONAL_EMAILS:
        "Partagez les PDF de la liste de contrôle avec vos collègues, y compris les commentaires",

    INITIAL_ASSIGNMENT_OF_MULTIPLE_COMMERCIAL_MANAGERS_ERROR:
        "Vous ne pouvez ajouter à cette équipe que le premier directeur commercial, qui gérera ensuite l'accès.",
    PROJECT_CURRENCY_HELP_TOOLTIP_MESSAGE:
        "La devise du projet sera affichée à côté de toutes les valeurs commerciales, par exemple dans le tableau de bord Commercial Pro ou dans le suivi des activités.",
    PDF_EXCEEDS_LIMIT:
        "Le PDF que vous avez essayé de télécharger dépasse 50MB. Veuillez télécharger une version plus petite.",
};
module.exports = translation;
