import DeliverableSelection from "common/plainModels/deliverable_selection.model";

export default function DeliverableChecklistsCtrl(
    $q,
    $scope,
    $translate,
    downloadCenterService,
    $mdDialog,
    $sbDeliverableChecklists,
    $sbBulkProcessOperationsService,
    $sbCurrentProject,
    $sbErrorPresenter,
    $sbDetailsOverlay,
    $mdToast,
    $sbTracking,
    $sbLocale,
    $sbDetailsOverlayData,
    Analytics,
    $sbTeam,
    $sbFeatureDecisions,
    FeatureFlags,
    $timeout
) {
    "ngInject";

    const vm = this;

    let deliverableWatcher = undefined;
    let selectedTeamWatcher = undefined;

    vm.notes = [];
    vm.allChecklists = [];

    vm.model = {
        checklists: [],
        currentDeliverableId: undefined,
    };
    vm.openChecklist = openChecklist;
    vm.startedChecklists = [];
    vm.notStartedChecklists = [];
    vm.onFilterByTeam = onFilterByTeam;
    vm.removeTeamFilter = removeTeamFilter;

    vm.exportDeliverableChecklistsPdf = _exportDeliverableChecklistsPdf;
    vm.isDeliverableChecklistPrintDisabled = true;
    const statesNotIncludedInDeliverableChecklistPdf = ["ongoing", "rejected"];

    vm.$onChanges = $onChanges;

    vm.isChecklistEnabled = $sbFeatureDecisions.isFeatureEnabled(
        FeatureFlags.TemplateEditorQaChecklist
    );

    $onInit();

    function $onInit() {
        _initializeWatchers();

        $timeout(() => {
            const el = document.getElementById("requestAccessQAChecklistsTab");

            if (el) {
                el.toState = "requestAccessQAChecklistsTabOnDeliverables";
            }
        });
    }

    function openChecklist(checklistHandover) {
        $sbTracking.detailsOverlay.checklists.openReviewer();

        const executionChecklistId = checklistHandover.executionChecklist?.id;
        const checklistTemplateId = checklistHandover.templateChecklist?.id;
        const checklistId = executionChecklistId || checklistTemplateId;

        void $sbDetailsOverlay.toggleView(
            "checklist",
            vm.model.currentDeliverableId +
                "/" +
                checklistHandover.activity.id +
                "/" +
                checklistId
        );
    }

    /**
     * Request a checklist handover protocol as PDF.
     *
     * @param {ActivityChecklist} checklistHandover
     * @param {Activity} checklistHandover.activity
     * @param {string} checklistHandover.activity.id
     * @param {Object} checklistHandover.executionChecklist
     * @param {number} checklistHandover.executionChecklist.id
     * @param {Object} checklistHandover.confirmationChecklist
     * @param {number} [checklistHandover.confirmationChecklist.id]
     */

    function getStartedChecklists() {
        return vm.allChecklists.filter(
            (checklist) => !checklist.templateChecklist
        );
    }

    function getNotStartedChecklists() {
        return vm.allChecklists.filter(
            (checklist) => checklist.templateChecklist
        );
    }

    function removeTeamFilter() {
        onFilterByTeam($sbTeam.findProjectTeam(vm.teams));
    }

    function onFilterByTeam(team) {
        if (team.isProjectTeam) {
            Analytics.trackEvent(
                "Details Overlay",
                "click",
                "changed to show all activities"
            );
        } else {
            Analytics.trackEvent(
                "Details Overlay",
                "click",
                "changed to specific team activities"
            );
        }
        vm.onSelectedTeamChange({ team });

        _filterChecklistsBasedOnFilterSelection(team);
    }

    function $onChanges(changes) {
        if (changes.selectedTeam) {
            _filterChecklistsBasedOnFilterSelection(
                changes.selectedTeam.currentValue
            );
        }
    }

    function _filterChecklistsBasedOnFilterSelection(team) {
        if (!team || team.isProjectTeam) {
            vm.model.checklists = vm.allChecklists;
            return;
        }

        vm.model.checklists = vm.allChecklists.filter((checklist) => {
            const qualityPipelineTeamIds =
                checklist.qualityPipeline?.teams.map((t) => t.id) ?? [];
            return qualityPipelineTeamIds.includes(team.id);
        });
    }

    function _exportDeliverableChecklistsPdf() {
        Analytics.trackEvent("PDF Reports", "click", "ITP export");

        const deliverableSelection = new DeliverableSelection();
        deliverableSelection.select(vm.deliverable);

        const activityTemplateIds = vm.deliverable
            .getActivities()
            .map(({ piTemplateId }) => ({ id: piTemplateId }));

        const req = () => {
            return $sbBulkProcessOperationsService.bulkExportChecklistPDF(
                deliverableSelection,
                activityTemplateIds,
                {},
                {
                    excludedStates: statesNotIncludedInDeliverableChecklistPdf,
                    format: "document",
                }
            );
        };

        $mdDialog.show(
            $mdDialog.deliverableChecklistsPdfExport().sendRequest(req)
        );
    }

    function _initializeWatchers() {
        deliverableWatcher = $scope.$watch(
            function () {
                return vm.deliverable;
            },
            function (deliverable) {
                vm.model.checklists = [];
                vm.model.currentDeliverableId = deliverable.id;

                if (deliverable && deliverable.id) {
                    $sbDetailsOverlayData
                        .loadChecklists(deliverable.id)
                        .then(function (checklists) {
                            $sbTracking.detailsOverlay.tabs.openQAChecklists();
                            vm.notes = $sbDetailsOverlayData.current.notes;

                            vm.allChecklists = checklists;
                            vm.startedChecklists = getStartedChecklists();
                            vm.notStartedChecklists = getNotStartedChecklists();
                            vm.isDeliverableChecklistPrintDisabled =
                                vm.startedChecklists.every((checklist) =>
                                    statesNotIncludedInDeliverableChecklistPdf.includes(
                                        checklist.sequenceStatus
                                    )
                                );

                            _filterChecklistsBasedOnFilterSelection(
                                vm.selectedTeam
                            );

                            $scope.$apply();
                        });
                }
            }
        );

        selectedTeamWatcher = $scope.$watch(
            "$ctrl.selectedTeam.id",
            function () {
                vm.startedChecklists = getStartedChecklists();
                vm.notStartedChecklists = getNotStartedChecklists();
            }
        );
    }

    function _errorHandling(err) {
        if (
            err.code === "REQUEST_TIMEOUT" ||
            err.code === "PAYLOAD_TOO_LARGE"
        ) {
            return $mdToast.show(
                $mdToast
                    .simple()
                    .content("ERROR_PDF_CREATION_MESSAGE")
                    .hideDelay(3000)
                    .position("top right")
            );
        }
        return $sbErrorPresenter.catch(err);
    }

    $scope.$on("$destroy", function () {
        deliverableWatcher();
        selectedTeamWatcher();
    });
}
