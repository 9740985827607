import FeatureFlagsModule from "./feature_flags.module";

//! IMPORTANT keep aligned with the following three Angular enums
// page-flags.enum.ts
// feature-flags.enum.ts
// environment-flags.enum.ts

FeatureFlagsModule.constant("FeatureFlags", {
    TrackerInviteCoworkers: "tracker-invite-coworkers",
    TrackerAsLandingPage: "tracker-as-landing-page",
    TrackerInlineActivityStatusUpdates:
        "tracker-inline-activity-status-updates",
    TemplateEditorDurations: "template-editor-durations",
    TemplateEditorLabour: "template-editor-labour",
    TemplateEditorConfirmationTeams: "template-editor-confirmation-teams",
    TemplateEditorQaChecklist: "template-editor-qa-checklist",
    TrackerInlineLookaheadDates: "tracker-inline-lookahead-dates",
    DashboardsFullAccess: "dashboards-full-access",
});

FeatureFlagsModule.constant("PageFlags", {
    PageNotMappedToFeatureFlagYet: "page-not-mapped-to-feature-flag-yet",
    ProjectOverview: "project-overview",
    ProjectOverviewPro: "project-overview-pro",
    PerformanceDashboard: "performance-dashboard",
    PerformanceDashboardPro: "performance-dashboard-pro",
    VisualTrackers: "visual-trackers",
    Deliverables: "deliverables",
    Notes: "notes",
    ProjectSettings: "project-settings",
    ProjectStructure: "project-structure",
    ImportExport: "import-export",
    QaChecklists: "qa-checklists",
    NoteTemplates: "note-templates",
    LeanPlanning: "lean-planning",
    Commercial: "commercial",
    CommercialValuation: "commercial-valuation",

    RequestAccessProjectOverview: "request-access-project-overview",
    RequestAccessPerformanceDashboard: "request-access-performance-dashboard",
    RequestAccessPerformanceDashboardPro:
        "request-access-performance-dashboard-pro",
    RequestAccessDeliverables: "request-access-deliverables",
    RequestAccessNotes: "request-access-notes",
    RequestAccessLeanPlanning: "request-access-lean-planning",
    RequestAccessCommercialDashboard: "request-access-commercial-dashboard",
    RequestAccessCommercialProDashboard: "request-access-commercial-valuation",
    RequestAccessVisualTrackers: "request-access-visual-trackers",
    RequestAccessQAChecklists: "request-access-qa-checklists",
    SiteDiary: "site-diary",
});

FeatureFlagsModule.constant("EnvironmentFlags", {
    AllowTemplateOverwrite: "allow-template-overwrite",
    ShowCsRequest: "show-cs-request-action",
    ShowDeliverablesDependencies: "show-deliverables-dependencies",
    ShowSchedulingMode: "show-scheduling-mode",
    DeprecatedFeatureToRemoveSoon: "deprecated-feature-to-remove-soon",
    ShowOldImports: "show-old-imports",
    ShowInspectionTeams: "show-inspection-teams",
});
