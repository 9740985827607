export default function ExternalServices($window) {
    "ngInject";

    /**
     *
     * @param {SbProject} project
     * @returns {*}
     */
    function setActiveProject(project) {
        return $window?.sbServices?.activeProjectService.setActiveProject({
            id: project.id,
            name: project.name,
            purpose: project.purpose,
            language: project.language,
            subscription: project.subscription,
            currencyCode: project.currencyCode,
            account: project.account,
        });
    }

    function setIntercomService(intercomService) {
        $window?.sbServices?.intercomService.setIntercomService(
            intercomService
        );
    }

    function activityTrackerState() {
        return $window?.sbServices?.appActivityTrackerState;
    }

    function getChecklistSequences(deliverableId) {
        return $window?.sbServices?.checklistSequenceService.getChecklistSequences(
            deliverableId
        );
    }

    function showDetailsEventEmitter() {
        return $window?.sbServices?.showDetailsEventEmitter;
    }

    function analyticsService() {
        return $window?.sbServices?.analyticsService;
    }

    function router() {
        return $window?.sbServices?.routerBridgeService;
    }

    function noteCreationService() {
        return $window?.sbServices?.angularBridgeNoteCreationService;
    }

    function noteCommentCreationService() {
        return $window?.sbServices?.angularBridgeNoteCommentCreationService;
    }

    function translateServiceTriggerChange(language) {
        return $window?.sbServices?.translateService.triggerLanguageChange(
            language
        );
    }
    function authenticationService() {
        return $window?.sbServices?.authenticationService;
    }

    function noteViewFacadeService() {
        return $window?.sbServices?.noteViewFacade;
    }

    function openImportActivitiesModal(importActivitiesModalData) {
        return $window?.sbServices?.importActivitiesService.onImportClicked(
            importActivitiesModalData
        );
    }

    function openImportPackageModal(importPackageModalData) {
        return $window?.sbServices?.importPackageService.onImportClicked(
            importPackageModalData
        );
    }

    function onTrackerDataChange() {
        return $window?.sbServices?.eventBusService.bridgeEmissions;
    }

    function resetActivitiesJob(projectId, resetActivitiesJobDefinition) {
        return $window?.sbServices?.activityJobsRepository.resetActivitiesJob(
            projectId,
            resetActivitiesJobDefinition
        );
    }

    function emailChange$() {
        return $window?.sbServices?.composeEmailBridgeService.emailChange$.asObservable();
    }
    function projectOverviewSettingsStore() {
        return $window?.sbServices?.projectOverviewSettingsStore;
    }

    function featureFlagsService() {
        return $window?.sbServices?.featureFlagsService;
    }

    return {
        router,
        setActiveProject,
        setIntercomService,
        activityTrackerState,
        getChecklistSequences,
        showDetailsEventEmitter,
        analyticsService,
        noteCreationService,
        noteCommentCreationService,
        translateServiceTriggerChange,
        authenticationService,
        noteViewFacadeService,
        openImportActivitiesModal,
        openImportPackageModal,
        onTrackerDataChange,
        resetActivitiesJob,
        emailChange$,
        projectOverviewSettingsStore,
        featureFlagsService,
    };
}
