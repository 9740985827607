export default function (
    $stateProvider,
    $urlRouterProvider,
    $urlMatcherFactoryProvider
) {
    "ngInject";
    $urlMatcherFactoryProvider.caseInsensitive(true);

    // URL handling for the legacy routes. of sapui5 : analysis + open a specific detail
    //
    $urlRouterProvider.when(
        "/{projectId}/analysis/{componentId}",
        function ($match, $state) {
            "ngInject";
            $state.go(
                "sablono.project.ngdeliverables",
                {
                    projectId: $match.projectId,
                    open: $match.componentId,
                },
                {
                    location: "replace",
                }
            );
        }
    );

    // URL handling for the legacy routes. of sapui5 : analysis in general
    //
    $urlRouterProvider.when(
        "/{projectId}/analysis",
        "/{projectId}/ngdeliverables"
    );

    // URL handling for the legacy routes. of sapui5 : project elements in general
    //
    $urlRouterProvider.when(
        "/{projectId}/deliverables",
        "/{projectId}/ngdeliverables"
    );

    // URL handling for the legacy routes. of sapui5 : inspection timeline in general
    //
    $urlRouterProvider.when(
        "/{projectId}/inspections",
        "/{projectId}/timeline"
    );

    //TODO this is a workaround for the two disabled pages.
    // Cleanup to follow once no customer request the old pages anymore
    $urlRouterProvider.when(
        "/{projectId}/projectoverview",
        "/{projectId}/dashboards-pro"
    );

    $urlRouterProvider.when(
        "/{projectId}/dashboard",
        "/{projectId}/dashboards-pro"
    );
}
