import html from "./sb_timeline.component.html";
import SbTimelineCtrl from "./sb_timeline.controller";

export default {
    templateUrl: html,
    bindings: {
        isLoading: "<",
        inspections: "<",
        totalAmountOfInspections: "<",
        showMoreIsVisible: "<",
    },
    controller: SbTimelineCtrl,
    controllerAs: "$ctrl",
};
