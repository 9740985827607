import has from "has";
import makePageDefinitions from "./menu_page_entries";

export default function MenuContentService(
    APP_CATEGORY,
    VERSION,
    SABLONO_STATES,
    $sbFeatureDecisions,
    EnvironmentFlags,
    PageFlags
) {
    "ngInject";

    const pages = makePageDefinitions(SABLONO_STATES, APP_CATEGORY);

    function pickPageByFeature(flag, pageEnabled, pageDisabled) {
        return $sbFeatureDecisions.isFeatureEnabled(flag)
            ? pageEnabled
            : pageDisabled;
    }

    function processViewer() {
        if (
            $sbFeatureDecisions.isFeatureEnabled(
                EnvironmentFlags.DeprecatedFeatureToRemoveSoon
            )
        ) {
            return [pages.processViewer];
        }
        return [];
    }

    //TODO the commented out parts are a workaround for the two disabled pages.
    // Cleanup to follow once no customer request the old pages anymore
    function performanceDashboard() {
        const pagesArray = [];
        // pagesArray.push(
        //     pickPageByFeature(
        //         PageFlags.PerformanceDashboard,
        //         pages.dashboard,
        //         pages.requestAccessPerformanceDashboard
        //     )
        // );
        if (
            $sbFeatureDecisions.isFeatureEnabled(
                PageFlags.PerformanceDashboardPro
            )
        ) {
            pagesArray.push(pages.dashboardPro);
        }
        return pagesArray;
    }

    function commercialFeatures() {
        const isCommercialEnabled = $sbFeatureDecisions.isFeatureEnabled(
            PageFlags.Commercial
        );
        if (isCommercialEnabled) {
            return [
                pickPageByFeature(
                    PageFlags.Commercial,
                    pages.commercialDashboard,
                    pages.requestAccessCommercialDashboard
                ),
                pickPageByFeature(
                    PageFlags.CommercialValuation,
                    pages.commercialProDashboard,
                    pages.requestAccessCommercialProDashboard
                ),
            ];
        }
        return [
            pickPageByFeature(
                PageFlags.CommercialValuation,
                pages.commercialProDashboard,
                pages.requestAccessCommercialProDashboard
            ),
        ];
    }

    function generateMenu() {
        const sections = [
            {
                name: "SECTION_SIDENAV_DASHBOARDS_AND_TRACKERS_TITLE",
                type: "toggle",
                cyName: "menu-dashboards-and-trackers",
                pages: [
                    // pickPageByFeature(
                    //     PageFlags.ProjectOverviewPro,
                    //     pages.projectOverviewPro,
                    //     pickPageByFeature(
                    //         PageFlags.ProjectOverview,
                    //         pages.projectOverview,
                    //         pages.requestAccessProjectOverview
                    //     )
                    // ),
                    ...performanceDashboard(),
                    pickPageByFeature(
                        PageFlags.VisualTrackers,
                        pages.visualization,
                        pages.requestAccessVisualTrackers
                    ),
                    pages.tracker,
                    ...commercialFeatures(),
                    ...processViewer(),
                ],
            },
            {
                name: "SECTION_SIDENAV_LISTS_TITLE",
                type: "toggle",
                cyName: "menu-list",
                pages: [
                    pickPageByFeature(
                        PageFlags.Deliverables,
                        pages.deliverablesProgress,
                        pages.requestAccessDeliverables
                    ),
                    pickPageByFeature(
                        PageFlags.Notes,
                        pages.issues,
                        pages.requestAccessNotes
                    ),
                ],
            },
            {
                name: "SECTION_SIDENAV_SCHEDULES_AND_TIMELINES_TITLE",
                type: "toggle",
                cyName: "menu-schedule-and-timelines",
                pages: [
                    pickPageByFeature(
                        PageFlags.LeanPlanning,
                        pages.leanboard,
                        pages.requestAccessLeanPlanning
                    ),
                    pages.inspections,
                    pages.siteDiary,
                ],
            },
            {
                name: "SECTION_SIDENAV_SETUP_TITLE",
                type: "toggle",
                cyName: "setup",
                pages: [
                    pages.projectSettings,
                    pages.templates,
                    pickPageByFeature(
                        PageFlags.RequestAccessQAChecklists,
                        pages.requestAccessQAChecklists,
                        pages.checklistTemplatesList
                    ),
                    pages.teams,
                    pages.wbs,
                    pages.noteTemplates,
                    pages.tools,
                ],
            },
            {
                name: "SECTION_SIDENAV_RESOURCES_TITLE",
                type: "toggle",
                cyName: "resources",
                pages: [
                    pages.faq,
                    pages.versionPage,
                    pages.ideasPortal,
                    pages.imprint,
                    pages.eula,
                    pages.privacyPolicy,
                ],
            },
        ];

        if (has("development")) {
            sections[4].pages.push({
                name: "Version " + VERSION,
                state: "sablono.info",
                type: "state",
                icon: "mdi mdi-ticket",
                highlight: "sablono.info",
            });
        }

        return {
            header: [
                {
                    ...pages.projectSelection,
                    cyName: "menu-show-my-projects",
                },
            ],
            headerNoProject: [pages.projectSelection],
            sections: sections,
        };
    }

    return { generateMenu };
}
