module.exports = function (SABLONO_STATES, APP_CATEGORY) {
    return {
        projectOverviewPro: {
            name: "ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE",
            state: SABLONO_STATES.projectOverviewPro,
            type: "state",
            icon: "mdi mdi-monitor-dashboard",
            highlight: SABLONO_STATES.projectOverviewPro,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-project-overview-pro",
        },
        projectOverview: {
            name: "ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE",
            state: SABLONO_STATES.projectOverview,
            type: "state",
            icon: "mdi mdi-monitor-dashboard",
            highlight: SABLONO_STATES.projectOverview,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-project-overview",
        },
        requestAccessProjectOverview: {
            name: "ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE",
            state: SABLONO_STATES.requestAccessProjectOverview,
            stateParams: {
                toState: SABLONO_STATES.requestAccessProjectOverview,
            },
            type: "state",
            icon: "mdi mdi-monitor-dashboard",
            highlight: SABLONO_STATES.requestAccessProjectOverview,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
        },
        tracker: {
            name: "ACTION_NAV_TO_TRACKER",
            state: SABLONO_STATES.tracker,
            type: "state",
            icon: "mdi mdi-grid",
            highlight: SABLONO_STATES.tracker,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "tracker",
        },
        dashboard: {
            name: "ACTION_NAV_TO_DASHBOARD",
            state: SABLONO_STATES.dashboard,
            type: "state",
            icon: "mdi mdi-speedometer",
            highlight: SABLONO_STATES.dashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "dashboard",
        },
        requestAccessPerformanceDashboard: {
            name: "ACTION_NAV_TO_DASHBOARD",
            state: SABLONO_STATES.requestAccessPerformanceDashboard,
            stateParams: {
                toState: SABLONO_STATES.requestAccessPerformanceDashboard,
            },
            type: "state",
            icon: "mdi mdi-speedometer",
            highlight: SABLONO_STATES.requestAccessPerformanceDashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "dashboard",
        },
        dashboardPro: {
            name: "ACTION_NAV_TO_DASHBOARD_PRO",
            state: SABLONO_STATES.dashboardPro,
            type: "state",
            icon: "mdi mdi-finance",
            highlight: SABLONO_STATES.dashboardPro,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "dashboard",
        },
        // requestAccessDashboardPro: {
        //     name: "ACTION_NAV_TO_DASHBOARD_PRO",
        //     state: SABLONO_STATES.requestAccessPerformanceDashboardPro,
        //     type: "state",
        //     icon: "mdi mdi-finance",
        //     highlight: SABLONO_STATES.requestAccessPerformanceDashboardPro,
        //     category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
        //     cyName: "dashboard",
        //     hasNewBadge: true,
        // },
        deliverablesProgress: {
            name: "ACTION_NAV_TO_ANALYSIS",
            state: SABLONO_STATES.deliverablesProgress,
            type: "state",
            icon: "mdi mdi-format-list-bulleted-type",
            highlight: SABLONO_STATES.deliverablesProgress,
            category: APP_CATEGORY.LISTS,
            cyName: "deliverables-side-menu-bar",
        },
        requestAccessDeliverables: {
            name: "ACTION_NAV_TO_ANALYSIS",
            state: SABLONO_STATES.requestAccessDeliverables,
            stateParams: {
                toState: SABLONO_STATES.requestAccessDeliverables,
            },
            type: "state",
            icon: "mdi mdi-format-list-bulleted-type",
            highlight: SABLONO_STATES.requestAccessDeliverables,
            category: APP_CATEGORY.LISTS,
            cyName: "deliverables-side-menu-bar",
        },
        issues: {
            name: "ACTION_NAV_TO_NOTES",
            state: SABLONO_STATES.issues,
            type: "state",
            icon: "sb-icon-attach_mobile",
            highlight: SABLONO_STATES.issues,
            category: APP_CATEGORY.LISTS,
            cyName: "notes-page",
        },
        requestAccessNotes: {
            name: "ACTION_NAV_TO_NOTES",
            state: SABLONO_STATES.requestAccessNotes,
            stateParams: {
                toState: SABLONO_STATES.requestAccessNotes,
            },
            type: "state",
            icon: "sb-icon-attach_mobile",
            highlight: SABLONO_STATES.requestAccessNotes,
            category: APP_CATEGORY.LISTS,
            cyName: "notes-page",
        },
        leanboard: {
            name: "ACTION_NAV_TO_LEANBOARD",
            state: SABLONO_STATES.leanboard,
            type: "state",
            icon: "mdi mdi-calendar-week",
            highlight: SABLONO_STATES.leanboard,
            category: APP_CATEGORY.SCHEDULES_AND_TIMELINES,
            cyName: "leanboard",
        },
        requestAccessLeanPlanning: {
            name: "ACTION_NAV_TO_LEANBOARD",
            state: SABLONO_STATES.requestAccessLeanPlanning,
            stateParams: {
                toState: SABLONO_STATES.requestAccessLeanPlanning,
            },
            type: "state",
            icon: "mdi mdi-calendar-week",
            highlight: SABLONO_STATES.requestAccessLeanPlanning,
            category: APP_CATEGORY.SCHEDULES_AND_TIMELINES,
            cyName: "leanboard",
        },
        inspections: {
            name: "ACTION_NAV_TO_INSPECTIONS",
            state: "sablono.project.timeline",
            type: "state",
            icon: "sb-icon-inspection_history_2",
            highlight: "sablono.project.timeline",
            category: APP_CATEGORY.SCHEDULES_AND_TIMELINES,
            cyName: "update-timeline",
        },
        siteDiary: {
            name: "ACTION_NAV_TO_SITE_DIARY",
            state: SABLONO_STATES.siteDiary,
            type: "state",
            icon: "mdi mdi-calendar-search",
            highlight: SABLONO_STATES.siteDiary,
            category: APP_CATEGORY.SCHEDULES_AND_TIMELINES,
            cyName: "menu-site-diary",
        },
        commercialProDashboard: {
            name: "ACTION_NAV_TO_COMMERCIAL_PRO",
            state: SABLONO_STATES.commercialProDashboard,
            type: "state",
            icon: "mdi mdi mdi-currency-usd",
            highlight: SABLONO_STATES.commercialProDashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-commercial-valuation",
        },
        requestAccessCommercialProDashboard: {
            name: "ACTION_NAV_TO_COMMERCIAL_PRO",
            state: SABLONO_STATES.requestAccessCommercialProDashboard,
            stateParams: {
                toState: SABLONO_STATES.requestAccessCommercialProDashboard,
            },
            type: "state",
            icon: "mdi mdi-currency-usd",
            highlight: SABLONO_STATES.requestAccessCommercialProDashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-commercial-valuation",
        },
        commercialDashboard: {
            name: "ACTION_NAV_TO_COMMERCIAL",
            state: SABLONO_STATES.commercialDashboard,
            type: "state",
            icon: "mdi mdi mdi-cash-multiple",
            highlight: SABLONO_STATES.commercialDashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-commercial-dashboard",
        },
        requestAccessCommercialDashboard: {
            name: "ACTION_NAV_TO_COMMERCIAL",
            state: SABLONO_STATES.requestAccessCommercialDashboard,
            stateParams: {
                toState: SABLONO_STATES.requestAccessCommercialDashboard,
            },
            type: "state",
            icon: "mdi mdi mdi-cash-multiple",
            highlight: SABLONO_STATES.requestAccessCommercialDashboard,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "menu-commercial-dashboard",
        },
        visualization: {
            name: "ACTION_NAV_TO_VISUALIZATION",
            state: SABLONO_STATES.visualization,
            type: "state",
            icon: "sb-icon-room",
            highlight: SABLONO_STATES.visualization,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "visualization",
        },
        requestAccessVisualTrackers: {
            name: "ACTION_NAV_TO_VISUALIZATION",
            state: SABLONO_STATES.requestAccessVisualTrackers,
            stateParams: {
                toState: SABLONO_STATES.requestAccessVisualTrackers,
            },
            type: "state",
            icon: "sb-icon-room",
            highlight: SABLONO_STATES.requestAccessVisualTrackers,
            category: APP_CATEGORY.DASHBOARDS_AND_TRACKERS,
            cyName: "visualization",
        },
        projectSettings: {
            name: "ACTION_NAV_TO_PROJECT_DETAILS",
            state: SABLONO_STATES.projectSettings,
            type: "state",
            icon: "mdi mdi-cog",
            highlight: SABLONO_STATES.projectSettings,
            category: APP_CATEGORY.SETUP,
            cyName: "menu-project-settings",
        },
        templates: {
            name: "ACTION_NAV_TO_TEMPLATE_ELEMENT",
            state: SABLONO_STATES.templates,
            type: "state",
            icon: "sb-icon-template",
            highlight: SABLONO_STATES.templates,
            category: APP_CATEGORY.SETUP,
            cyName: "templates",
        },
        teams: {
            name: "ACTION_NAV_TO_TEAMS",
            state: SABLONO_STATES.teams,
            type: "state",
            icon: "mdi mdi-account-multiple",
            highlight: SABLONO_STATES.teams,
            category: APP_CATEGORY.SETUP,
            cyName: "teams",
        },
        wbs: {
            name: "ACTION_NAV_TO_WBS",
            state: SABLONO_STATES.wbs,
            type: "state",
            icon: "sb-icon-wbs_structure",
            highlight: SABLONO_STATES.wbs,
            category: APP_CATEGORY.SETUP,
            cyName: "wbs",
        },
        noteTemplates: {
            name: "ACTION_NAV_TO_TEMPLATE_NOTES",
            state: SABLONO_STATES.noteTemplates,
            type: "state",
            icon: "mdi mdi-comment",
            highlight: SABLONO_STATES.noteTemplates,
            category: APP_CATEGORY.SETUP,
            cyName: "menu-note-templates",
        },
        tools: {
            name: "ACTION_NAV_TO_TOOLS",
            state: SABLONO_STATES.tools,
            type: "state",
            icon: "mdi mdi-file-import",
            highlight: SABLONO_STATES.tools,
            category: APP_CATEGORY.SETUP,
            cyName: "tools",
        },
        requestAccessQAChecklists: {
            name: "ACTION_NAV_TO_TEMPLATE_CHECKLIST",
            state: SABLONO_STATES.requestAccessQAChecklists,
            stateParams: {
                toState: SABLONO_STATES.requestAccessQAChecklists,
            },
            type: "state",
            icon: "mdi mdi-clipboard-list-outline",
            highlight: SABLONO_STATES.requestAccessQAChecklists,
            category: APP_CATEGORY.SETUP,
            cyName: "menu-checklist-template",
        },
        checklistTemplatesList: {
            name: "ACTION_NAV_TO_TEMPLATE_CHECKLIST",
            state: SABLONO_STATES.checklistTemplatesList,
            type: "state",
            icon: "mdi mdi-clipboard-list-outline",
            highlight: SABLONO_STATES.checklistTemplatesList,
            category: APP_CATEGORY.SETUP,
            cyName: "menu-checklist-template",
        },
        faq: {
            name: "ACTION_NAV_TO_FAQ",
            href: "LINK_SUPPORT_CENTER",
            type: "href",
            icon: "mdi mdi-help-circle",
        },
        versionPage: {
            name: "ACTION_NAV_TO_VERSION",
            href: "LINK_VERSION_PAGE",
            target: "_blank",
            type: "href",
            icon: "mdi mdi-information",
        },
        ideasPortal: {
            name: "ACTION_NAV_TO_IDEAS",
            href: "LINK_IDEAS_PORTAL",
            target: "_blank",
            type: "href",
            icon: "mdi mdi-lightbulb",
        },
        imprint: {
            name: "ACTION_NAV_TO_IMPRINT",
            href: "LINK_IMPRESSUM",
            type: "href",
            icon: "mdi mdi-ticket-account",
        },
        eula: {
            name: "EULA_PROMPT_ACTION_READ",
            href: "EULA_LINK",
            type: "href",
            icon: "mdi mdi-book",
        },
        privacyPolicy: {
            name: "PRIVACY_POLICY",
            href: "PRIVACY_POLICY_URL",
            type: "href",
            icon: "mdi mdi-lock",
        },
        projectSelection: {
            name: "ACTION_NAV_TO_PROJECT_SELECTION",
            state: "sablono.projectSelection",
            type: "state",
            icon: "sb-icon-project_overview",
            highlight: "sablono.projectSelection",
        },
        processViewer: {
            name: "ACTION_NAV_TO_PROCESS_VIEWER",
            state: "sablono.project.processViewer",
            type: "state",
            icon: "sb-icon-group",
            highlight: "sablono.project.processViewer",
            category: APP_CATEGORY.REVIEW,
        },
    };
};
