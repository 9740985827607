export default function SbTimelineCtrl(Analytics) {
    "ngInject";

    var vm = this;
    vm.trackClick = trackClick;

    function trackClick() {
        Analytics.trackConversion("update timeline clicked");
    }
}
