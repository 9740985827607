import angular from "angular";
import moment from "moment";
import _ from "lodash";
import DeliverableSelection from "common/plainModels/deliverable_selection.model";
import ModeBuilder from "common/plainModels/modeBuilder/mode_builder.class";

export default function SketchVisualizationCtrl(
    visualizationService,
    $stateParams,
    $sbErrorPresenter,
    $sbDetailsOverlay,
    $sbColor,
    $state,
    SABLONO_STATES,
    $mdDialog,
    MODES,
    $rootScope,
    $timeout,
    $scope,
    $filter,
    $interval,
    $sbDeliverablePanelTooltip,
    EVENTS,
    Analytics,
    $sbDialog,
    $sbFullscreenService,
    $sbPermission,
    $sbCurrentProject,
    EnvironmentFlags
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;
    var currentOpenComponentId;
    const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.svgCode = "";
    vm.fileName = "";
    vm.projectComponents = [];
    vm.currentSelection = [];
    vm.isLoading = true;
    vm.isMultiSelectActive = false;
    vm.date = moment();
    vm.EnvironmentFlags = EnvironmentFlags;

    // from local constants
    vm.modes = MODES;
    vm.activeMode = vm.modes.STAGE;

    vm.toggleOverlay = toggleOverlay;
    vm.onStartBulkSelection = onStartBulkSelection;
    vm.onToggleLegend = (showLegend) => (vm.isLegendHidden = !showLegend);
    vm.onCancelBulkUpdate = onCancelBulkUpdate;
    vm.updateSelection = updateSelection;

    vm.onStageSelectionChanged = onStageSelectionChanged;
    vm.toggleMode = toggleMode;
    vm.print = printSketch;

    vm.setActiveModeFromStateParams = setActiveModeFromStateParams;
    vm.hasVisualizationKey = hasVisualizationKey;
    vm.getVisualizationKey = getVisualizationKey;
    vm.isResultContainingComponents = isResultContainingComponents;
    vm.showNoMatchedDeliverablesInfo = showNoMatchedDeliverablesInfo;
    vm.goToVisualizationOverviewPage = goToVisualizationOverviewPage;
    vm.trackActiveModeOnPageOpened = trackActiveModeOnPageOpened;
    vm.showSettingsMenu = showSettingsMenu;

    vm.fullscreenMode = {
        isEnabled: false,
        switchFullscreenMode: switchFullscreenMode,
        view: {
            timer: undefined,
            refresh: onRefreshClick,
            lastUpdated: "",
            REFRESH_INTERVAL_IN_MILLISECONDS: FIVE_MINUTES_IN_MILLISECONDS,
        },
    };

    vm.$onInit = onInit;

    vm.onMouseOver = onMouseOver;
    vm.onMouseOut = $sbDeliverablePanelTooltip.close;

    // selection mode
    vm.onBulkStateChangeClick = onBulkStateChangeClick;
    vm.selectionProvider = new DeliverableSelection();
    vm.isCurrentSelectionEmpty = isCurrentSelectionEmpty;

    vm.selectionMode = {
        name: "SELECT_MODE",
        onClick: function (targetComponent) {
            var deliverableId = targetComponent.getAttribute("deliverable");

            if (deliverableId && deliverableId.length > 0) {
                var selectedComponent = _findProjectComponent(deliverableId);
                vm.updateSelection(selectedComponent);
            }
        },
    };

    vm.interactionMode = {
        name: "INTERACT_MODE",
        onClick: function (targetComponent) {
            var deliverableId = targetComponent.getAttribute("deliverable");

            if (
                deliverableId &&
                deliverableId.length > 0 &&
                _findProjectComponent(deliverableId)
            ) {
                const activityId =
                    _getIdForSelectedActivityOfDeliverable(deliverableId);

                vm.toggleOverlay(deliverableId, activityId);
            }
        },
    };

    vm.modeManager = ModeBuilder.create()
        .onModeChange(function (from, to) {
            if (
                from === vm.interactionMode.name &&
                to === vm.selectionMode.name
            ) {
                vm.isMultiSelectActive = true;
                vm.onStartBulkSelection();
            }

            if (
                from === vm.selectionMode.name &&
                to === vm.interactionMode.name
            ) {
                vm.onCancelBulkUpdate();
            }
        })
        .addMode(vm.selectionMode.name, vm.selectionMode)
        .addMode(vm.interactionMode.name, vm.interactionMode)
        .setDefault(vm.interactionMode.name)
        .start();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    const dataChangeListener = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
        _refreshSvgByState
    );
    const noteCreationListener = $rootScope.$on(
        EVENTS.NOTE_CHANGED,
        _reloadAll
    );

    $scope.$on("$destroy", function () {
        dataChangeListener();
        noteCreationListener();
    });

    $rootScope.$on(
        EVENTS.FULLSCREEN_MODE_CHANGE,
        function (evt, isFullscreenMode) {
            vm.fullscreenMode.isEnabled = isFullscreenMode;
            if (!isFullscreenMode) {
                _removeTimer();
            }
        }
    );

    $scope.$on(EVENTS.DETAILS_OVERLAY_CLOSED, function () {
        currentOpenComponentId = undefined;
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function showSettingsMenu(mdMenu, event) {
        mdMenu.open(event);
    }

    function switchFullscreenMode() {
        $sbFullscreenService.toggleAll(_onEnterFullscreen, _removeTimer);
    }

    function onRefreshClick() {
        _removeTimer();
        _refreshConnection();
        _setTimerToRefreshConnection();
    }

    function _getIdForSelectedActivityOfDeliverable(deliverableId) {
        if (vm.selectedStages && vm.selectedStages.length > 0) {
            const deliverable = _.find(vm.projectComponents, [
                "ID",
                deliverableId,
            ]);

            const activity = _.find(deliverable.ACTIVITIES, [
                "TEMPLATE_ID",
                vm.selectedStages[0].id,
            ]);

            return activity.ID ? activity.ID : undefined;
        }
    }

    function _onEnterFullscreen() {
        _setLastUpdatedToNow();
        _setTimerToRefreshConnection();
    }

    function _setTimerToRefreshConnection() {
        vm.fullscreenMode.view.timer = $interval(
            _refreshConnection,
            vm.fullscreenMode.view.REFRESH_INTERVAL_IN_MILLISECONDS
        );
    }

    function _removeTimer() {
        if (!vm.fullscreenMode.view.timer) {
            return false;
        }

        // interval.cancel returns boolean so the same "type" is kept for the wrapping function
        if ($interval.cancel(vm.fullscreenMode.view.timer)) {
            vm.fullscreenMode.view.timer = undefined;
            return true;
        }

        return false;
    }

    function _refreshConnection() {
        _reloadAll();
        _setLastUpdatedToNow();
    }

    function _setLastUpdatedToNow() {
        vm.fullscreenMode.view.lastUpdated = moment().toDate();
    }

    function printSketch() {
        // taken from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
        //

        /* eslint-disable no-undef */
        const isOpera =
            (!!window.opr && !!opr.addons) ||
            !!window.opera ||
            navigator.userAgent.indexOf(" OPR/") >= 0;
        const isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(
                !window.safari ||
                    (typeof safari !== "undefined" && safari.pushNotification)
            );
        const isChrome = !!window.chrome;

        /* eslint-enable no-undef */

        if (isOpera || isSafari || isChrome) {
            vm.date = moment();
            window.print();
            Analytics.trackEvent(
                "PDF Reports",
                "click",
                vm.activeMode.printGAKey
            );
        } else {
            const warnDialog = $mdDialog
                .alert()
                .title("DIALOG_BAD_PRINT_WARNING_TITLE")
                .content("DIALOG_BAD_PRINT_WARNING_CONTENT")
                .ok("DIALOG_ALERT_OK");

            $mdDialog.show(warnDialog);
            Analytics.trackEvent(
                "Known Errors",
                "Error",
                "2D print in unsupported browser"
            );
        }
    }

    function onInit() {
        vm.setActiveModeFromStateParams($stateParams);
        vm.canReportProgress = $sbPermission.hasWorkPermissions(
            $sbCurrentProject.pick("privileges")
        );

        if (vm.hasVisualizationKey()) {
            _loadSvg(vm.getVisualizationKey())
                .then(function onLoadSvg(result) {
                    if (!vm.isResultContainingComponents(result)) {
                        return vm.showNoMatchedDeliverablesInfo();
                    }
                })
                .finally(function () {
                    vm.isLoading = false;
                });
        } else {
            vm.goToVisualizationOverviewPage();
        }

        vm.trackActiveModeOnPageOpened();
    }

    function trackActiveModeOnPageOpened() {
        Analytics.trackEvent(
            "2D visualization",
            "open",
            vm.activeMode.modeGAKey
        );
    }

    function isResultContainingComponents(result) {
        return (
            result &&
            angular.isArray(result.components) &&
            result.components.length > 0
        );
    }

    function hasVisualizationKey() {
        return _.isString($stateParams.id);
    }

    function getVisualizationKey() {
        return $stateParams.id;
    }

    function showNoMatchedDeliverablesInfo() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_NO_MATCH_SVG_TITLE")
                .content("DIALOG_NO_MATCH_SVG_CONTENT")
        );
    }

    function goToVisualizationOverviewPage() {
        $state.go(SABLONO_STATES.visualization);
    }

    function setActiveModeFromStateParams(stateParams) {
        if (stateParams.mode && MODES[stateParams.mode]) {
            vm.activeMode = MODES[stateParams.mode];
        }
    }

    function toggleMode(mode) {
        if (mode !== vm.activeMode) {
            vm.isLoading = true;

            vm.activeMode = mode;

            // change URL accordingly
            //
            var options = angular.copy($stateParams);
            options.mode = mode.name;

            $state.go($state.current, options, {
                notify: false,
            });

            // change the svg
            //
            _changeSvgToMode();

            // Track with GA
            //
            Analytics.trackEvent(
                "2D visualization",
                "click",
                vm.activeMode.modeGAKey
            );
        }
    }

    /**
     * Opens the overlay, or closes it if the component is already open.
     * @param deliverableId
     */
    function toggleOverlay(deliverableId, activityId) {
        if (activityId && vm.activeMode.name !== MODES.STAGE.name) {
            Analytics.trackConversion("Activity Overlay opened");

            currentOpenComponentId = deliverableId;
            $sbDetailsOverlay.toggleView(
                "activity",
                activityId,
                undefined,
                "narrow"
            );
        } else if (currentOpenComponentId !== deliverableId) {
            Analytics.trackConversion("Details Overlay opened");

            currentOpenComponentId = deliverableId;
            $sbDetailsOverlay.toggleView("deliverable", deliverableId);
        } else {
            currentOpenComponentId = undefined;
            $sbDetailsOverlay.close();
        }
    }

    function clearSelection() {
        // trigger async digest through timeout to update the drawing selection afterwards
        //
        $timeout(function () {
            vm.currentSelection = [].concat(
                vm.selectionProvider.clearSelection()
            );
        });
    }

    function updateSelection(selectedComponent) {
        // trigger async digest through timeout to update the drawing selection afterwards
        //
        $timeout(function () {
            vm.selectionProvider.selectionChange(selectedComponent);
            vm.currentSelection = _.map(
                vm.selectionProvider.selections(),
                "ID"
            );
        });
    }

    function _onFinishBulkUpdate() {
        Analytics.trackEvent(
            "2D visualization",
            "Progress change",
            "Batch update"
        );
        onCancelBulkUpdate();
        _reloadAll();
    }

    function onStartBulkSelection() {
        vm.isMultiSelectActive = true;
    }

    function onCancelBulkUpdate() {
        vm.isMultiSelectActive = false;
        vm.activeSelectionMode = vm.modeManager.switchTo(
            vm.interactionMode.name
        );
        clearSelection();
    }

    function _changeSvgToMode() {
        // change the svg colors
        //
        visualizationService
            .setContentByMode(vm.projectComponents, vm.activeMode)
            .then((content) => {
                updateViewModelWithVisualizationContent(
                    content,
                    vm.selectedStages
                );

                // pretend there is some loading.
                $timeout(function () {
                    vm.isLoading = false;
                }, 200);
            });
    }

    function updateViewModelWithVisualizationContent(content, selection) {
        var projectComponents = content.components;

        if (vm.activeMode.name === MODES.BROWSE_ACTIVITY.name) {
            projectComponents = _calculateColoredBrowseActivityComponents(
                content,
                selection
            );
        }

        vm.projectComponents = projectComponents;
        vm.legend = content.legend;
    }

    function _calculateColoredBrowseActivityComponents(
        { components },
        selection
    ) {
        vm.usedRootTemplates = calculateAssignedTemplatesFrom(components);

        if (_.isEmpty(selection)) {
            return visualizationService.updateColorPropertyForComponentsBy(
                _.get(selection, "id"),
                components
            );
        }

        let projectComponents = [];

        for (let selectionEntry of selection) {
            projectComponents = projectComponents.concat(
                visualizationService.updateColorPropertyForComponentsBy(
                    // get the activity id
                    _.get(selectionEntry, "id"),
                    // get the components that belong to the same template
                    _.filter(components, [
                        "TEMPLATE_ID",
                        _.get(selectionEntry, "rootId"),
                    ])
                )
            );
        }

        // IMPORTANT
        // The components are being passed back and forth
        // with the styles changing. If a component is
        // not included in an "iteration" then it will not be
        // styled any more even when its template is selected.

        if (
            // a template is not yet selected-its activities have no styling yet
            components.length !== projectComponents.length
        ) {
            // get the unselected components
            const unselectedComponents = _.difference(
                components,
                projectComponents
            );
            // give them the default "unstyled" style and add the components to the array
            projectComponents = projectComponents.concat(
                visualizationService.updateColorPropertyForComponentsBy(
                    _.get(selection, "id"),
                    unselectedComponents
                )
            );
        }

        return projectComponents;
    }

    function calculateAssignedTemplatesFrom(components) {
        return _.chain(components)
            .map(function (component) {
                return component.TEMPLATE_ID;
            })
            .uniq()
            .value();
    }

    function _reloadAll() {
        return _loadSvg($stateParams.id);
    }

    function _loadSvg(svgId) {
        return visualizationService
            .loadVisualization(svgId)
            .then(function (result) {
                vm.svgCode = result.svg;
                vm.fileName = result.name;

                return visualizationService
                    .fetchContentByMode(svgId, vm.activeMode)
                    .then(function (result) {
                        updateViewModelWithVisualizationContent(
                            result,
                            vm.selectedStages
                        );
                        return result;
                    });
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $state.go(SABLONO_STATES.visualization);
            });
    }

    function onStageSelectionChanged(selection) {
        vm.selectedStages = selection;

        if (_.isEmpty(vm.selectedStages)) {
            Analytics.trackEvent(
                "2D visualization",
                "clear all selected",
                "Activities"
            );
        } else {
            Analytics.trackEvent("2D visualization", "select", "Activities");
        }

        updateViewModelWithVisualizationContent(
            {
                components: vm.projectComponents,
                legend: vm.legend,
            },
            vm.selectedStages
        );

        if (currentOpenComponentId !== undefined) {
            const activityId = _getIdForSelectedActivityOfDeliverable(
                currentOpenComponentId
            );

            if (activityId) {
                vm.toggleOverlay(currentOpenComponentId, activityId);
            }
        }
    }

    function _getTemplateIdsFromSelection(selection) {
        return _.uniqBy(selection, "TEMPLATE_ID");
    }

    function _getFirstTemplateIdFromSelection(selection) {
        return selection[0].TEMPLATE_ID;
    }

    function _isUniqueTemplateSelection() {
        var selectedDeliverableTemplateIds = _getTemplateIdsFromSelection(
            vm.selectionProvider.selections()
        );
        return selectedDeliverableTemplateIds.length === 1;
    }

    function onMouseOver($event) {
        const componentID = _getComponentID($event);
        const component = _findProjectComponent(componentID);

        $sbDeliverablePanelTooltip.close().then(() => {
            if (!componentID || !component) {
                return;
            }

            const context = {
                legend: vm.legend,
                activeMode: vm.activeMode,
                component: component,
            };

            $sbDeliverablePanelTooltip.open($event, context);
        });
    }

    function _getComponentID($event) {
        return $event.target.getAttribute("deliverable");
    }

    function _findProjectComponent(withID) {
        return _.find(vm.projectComponents, {
            ID: withID,
        });
    }

    function onBulkStateChangeClick($event) {
        if (_isUniqueTemplateSelection()) {
            var templateId = _getFirstTemplateIdFromSelection(
                vm.selectionProvider.selections()
            );

            return _openBulkStateChangeDialog($event, templateId)
                .then(_onFinishBulkUpdate)
                .catch(function (error) {
                    if (error) {
                        $sbErrorPresenter.catch(error);
                    }
                });
        } else {
            return _openNonUniqueTemplateSelectionWarning();
        }
    }

    function _openBulkStateChangeDialog($event, templateId) {
        return $sbDialog.openBulkStateChangeDialog(
            $event,
            vm.selectionProvider,
            templateId
        );
    }

    function _openNonUniqueTemplateSelectionWarning() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_TITLE")
                .content("DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_MESSAGE")
                .ok("DIALOG_ALERT_OK")
        );
    }

    function isCurrentSelectionEmpty() {
        return vm.currentSelection && vm.currentSelection.length === 0;
    }

    function _refreshSvgByState(event, activityId, newState, deliverableId) {
        _updateSingleActivityState(activityId, newState, deliverableId);
        _loadSvg(vm.getVisualizationKey());
    }

    function _updateSingleActivityState(activityId, newState, deliverableId) {
        const deliverable = _.find(vm.projectComponents, ["ID", deliverableId]);
        const activity = _.find(deliverable.ACTIVITIES, ["ID", activityId]);
        activity.STATE = newState;
    }
}
